import {ConfigurationComponent} from '../configurationComponent'
import {ConfiguratorMode, InsideOutsideArray, InsideOutsideObject, SideType} from '../../types'
import {
  ConfiguratedBlendrahmen,
  ConfiguratedDeckschicht,
  ConfiguratedDienstleistung,
  ConfiguratedMehrpreis,
  ConfiguratedOption,
  FluegelrahmenColor
} from '../../class'
import {ConstructionComponent} from '../model/component/construction/constructionComponent'
import {ConfiguratedGlasaufbau} from '../configuratedGlasaufbau'
import {ConfiguratedDoor} from '../model/configuratedDoor'
import {Blendrahmen} from '../model/component/frame/blendrahmen'
import {ZUBEHOER_CATEGORY_HAUSNUMMER} from '../model/component/extras/zubehoer/zubehoer.type'
import {Parameter} from '../service/parameter/parameter'
import {ConstructionComponentType} from '../model/component/construction/construction-component-type.enum'
import {LetterMapping} from '../model/component/extras/massblatt'
import {ReducedHausfront} from '../model/component/hausfront/reduced-hausfront'

export class ConfiguratedDoorDto {
  Aktion: string
  Bauform: string
  Bemerkungen: string
  Blendrahmen: InsideOutsideArray<ConfiguratedBlendrahmen>
  Breite: number
  Clerk: string
  Components: ConfigurationComponent[]
  CustomSize: boolean
  Din: number
  Hausfronten: InsideOutsideObject<ReducedHausfront>
  Hoehe: number
  Images: InsideOutsideObject<string>
  Klebesystem: number
  Kommission: string
  MasseOffen: boolean
  Material: string
  Mode: ConfiguratorMode
  Pos: string
  Profil: string
  ProfilId: number
  Seeklimatauglich: boolean
  SessionToken: string
  ShowRahmen: boolean
  Software: string
  Staerke: number
  TransKey: string
  Werksmontage: boolean

  constructor(data?: Partial<ConfiguratedDoorDto>) {
    this.Aktion = data?.Aktion ?? ''
    this.Bauform = data?.Bauform ?? ''
    this.Bemerkungen = data?.Bemerkungen ?? ''
    this.Blendrahmen = data?.Blendrahmen ?? null
    this.Breite = data?.Breite ?? -1
    this.Clerk = data?.Clerk ?? ''
    this.Components = []
    data?.Components?.forEach((c): void => {
      this.Components.push(new ConfigurationComponent(c))
    })
    this.CustomSize = data?.CustomSize === true
    this.Din = data?.Din ?? -1
    this.Hausfronten = {
      Inside: data?.Hausfronten?.Inside
        ? new ReducedHausfront({Id: data.Hausfronten.Inside.Id, Custom: data.Hausfronten.Inside.Custom})
        : null,
      Outside: data?.Hausfronten?.Outside
        ? new ReducedHausfront({Id: data.Hausfronten.Outside.Id, Custom: data.Hausfronten.Outside.Custom})
        : null,
    }
    this.Hoehe = data?.Hoehe ?? -1
    this.Images = data?.Images ?? null
    this.Klebesystem = data?.Klebesystem ?? -1
    this.Kommission = data?.Kommission ?? ''
    this.MasseOffen = data?.MasseOffen === true
    this.Material = data?.Material ?? ''
    this.Mode = data?.Mode ?? ConfiguratorMode.TTK
    this.Pos = data?.Pos ?? ''
    this.Profil = data?.Profil ?? ''
    this.ProfilId = data?.ProfilId ?? -1
    this.Seeklimatauglich = data?.Seeklimatauglich === true
    this.SessionToken = data?.SessionToken ?? ''
    this.ShowRahmen = data?.ShowRahmen === true
    this.Software = data?.Software ?? ''
    this.Staerke = data?.Staerke ?? -1
    this.TransKey = data?.TransKey ?? ''
    this.Werksmontage = data?.Werksmontage === true
  }

  static collectMehrpreise(
    component: ConstructionComponent
  ): ConfiguratedMehrpreis[] {
    const mehrpreise: ConfiguratedMehrpreis[] = []
    component.Mehrpreise.forEach((mehrpreisEntry): void => {
      const zubehoerItem = (mehrpreisEntry.hasOwnProperty('Item') ? mehrpreisEntry.Item : null)
      const fbsMehrpreis = component.model.Mehrpreise.find((m): boolean => m.Typ === mehrpreisEntry.Typ)
      const configuratedMehrpreisEntry = {
        Id: fbsMehrpreis.Id,
        Typ: mehrpreisEntry.Typ,
      } as ConfiguratedMehrpreis
      if (zubehoerItem && zubehoerItem.Id !== 0) {
        configuratedMehrpreisEntry.Zubehoer = [{
          Id: zubehoerItem.Id,
          Typ: zubehoerItem.Typ,
          ...(
            zubehoerItem.Typ === ZUBEHOER_CATEGORY_HAUSNUMMER
              ? {
                Value: zubehoerItem.Value
              }
              : {}
          )
        }]
      }
      if (mehrpreisEntry?.addons.length > 0) {
        configuratedMehrpreisEntry.Dienstleistungen = mehrpreisEntry.addons.map((a): ConfiguratedDienstleistung => ({
          Id: a.Id,
          Typ: a.Typ,
          ...(a.Massblatt
            ? {
              Massblaetter: [{
                Id: a.Massblatt.Id,
                ScriptId: a.Massblatt?.ScriptId,
                Values: new LetterMapping(a.Massblatt.Values)
              }]
            }
            : {}
          )
        }))
      }
      mehrpreise.push(configuratedMehrpreisEntry)
    })
    return mehrpreise
  }

  static collectOptions(
    component: ConstructionComponent
  ): InsideOutsideArray<Partial<ConfiguratedOption>> {
    const optionen: InsideOutsideArray<Partial<ConfiguratedOption>> = {
      Inside: [],
      Outside: []
    }
    component.optionen.Inside.forEach((o): void => {
      const option = {
        Id: o.Id,
        Typ: o.Typ
      } as Partial<ConfiguratedOption>
      if (o.Beschichtung) {
        option.Beschichtung = {
          Id: o.Beschichtung.Id,
          Typ: o.Beschichtung.Typ
        }
      }
      optionen.Inside.push(option)
    })
    component.optionen.Outside.forEach((o): void => {
      const option = {
        Id: o.Id,
        Typ: o.Typ
      } as Partial<ConfiguratedOption>
      if (o.Beschichtung) {
        option.Beschichtung = {
          Id: o.Beschichtung.Id,
          Typ: o.Beschichtung.Typ
        }
      }
      optionen.Outside.push(option)
    })
    return optionen
  }

  static fromConfiguratedDoor(
    configuratedDoor: ConfiguratedDoor,
    parameter: Parameter | undefined,
    modelPos: string
  ): ConfiguratedDoorDto {
    const toColorObject =
      (b: Blendrahmen): ConfiguratedBlendrahmen & { Beschichtung: { Bezeichnung?: string }; Bezeichnung?: string } => ({
        Id: b.Id,
        Bezeichnung: configuratedDoor.profile.Beschreibung,
        Typ: 'blendrahmen',
        HasBeschichtung: typeof b?.Beschichtung?.Id === 'number',
        Beschichtung: {
          Id: b?.Beschichtung?.Id,
          Bezeichnung: b?.Beschichtung?.Bezeichnung,
          Typ: b?.Beschichtung?.Typ
        }
      })
    const element: ConfiguratedDoorDto = new ConfiguratedDoorDto({
      Aktion: configuratedDoor.Aktion,
      Bauform: configuratedDoor.bauform,
      Bemerkungen: configuratedDoor.notes,
      Blendrahmen: {
        Inside: [toColorObject(configuratedDoor.Blendrahmen[SideType.Inside] ?? new Blendrahmen())],
        Outside: [toColorObject(configuratedDoor.Blendrahmen[SideType.Outside] ?? new Blendrahmen())]
      },
      Breite: configuratedDoor.width,
      Clerk: configuratedDoor.Clerk,
      CustomSize: configuratedDoor.customSize,
      Din: configuratedDoor.configuratorMode === ConfiguratorMode.FBS
        ? configuratedDoor.selectedComponent.din
        : configuratedDoor.Components.find(
          (c: ConstructionComponent): boolean => c._objectType === ConstructionComponentType.Door
        ).din,
      Material: configuratedDoor.profile?.Material,
      Hoehe: configuratedDoor.height,
      Images: configuratedDoor.Images,
      Kommission: configuratedDoor.Kommission,
      MasseOffen: configuratedDoor.MasseOffen,
      Pos: modelPos,
      Profil: parameter.profil,
      ProfilId: configuratedDoor.profile.Id,
      ShowRahmen: parameter.showrahmen,
      Seeklimatauglich: configuratedDoor.Seeklimatauglich === true,
      SessionToken: parameter?.session_token,
      Software: parameter?.software,
      TransKey: parameter?.transkey,
      Werksmontage: configuratedDoor.Werksmontage === true,
      Mode: configuratedDoor.configuratorMode,
      Hausfronten: configuratedDoor?.Hausfronten
    })
    configuratedDoor.updateKonstruktionsMasse()
    element.Components = []
    configuratedDoor.Components.forEach((c: ConstructionComponent): void => {
      element.Components.push(this.generateConfigFromComponent(configuratedDoor, c))
    })
    return element
  }

  static generateConfigFromComponent(
    configuratedDoor: ConfiguratedDoor,
    component: ConstructionComponent
  ): ConfigurationComponent {
    return new ConfigurationComponent({
      Breite: component.breite,
      Deckschichten: this.getConfiguratedDeckschichten(component),
      Din: component.dinfuellung,
      Fluegelrahmen: this.getFluegelrahmenColor(configuratedDoor, component),
      Glasaufbau: ConfiguratedGlasaufbau.fromGlasaufbau(component?.glasaufbau),
      Hoehe: component.hoehe,
      Hoehenverteilung: component.Hoehenverteilung,
      KatalogId: component.model?.KatalogId,
      Klebesystem: component.Klebesystem?.Id,
      Klebeset: component.Klebeset === false ? false : (component.Klebesystem?.Id === 2 ? true : false),
      Konstruktion: component.konstruktion,
      KonstruktionsMasse: component.KonstruktionsMasse,
      KonstruktionsVariante: component.fbsKonstruktion?.Id || 0,
      Material: component.material,
      Mehrpreise: this.collectMehrpreise(component),
      ModelId: component.model?.Id,
      Oeffnungsart: component.oeffnungsart,
      Optionen: this.collectOptions(component),
      Querfries: component.Querfries,
      Schallschutz: configuratedDoor.schallschutzPaket?.Id,
      Sicherheit: configuratedDoor.sicherheitsPaket?.Id,
      Staerke: Math.round((component.staerke + Number.EPSILON) * 100) / 100,
      Typ: component.objectType,
      Waermeschutz: configuratedDoor.waermeschutzPaket?.Id,
      Zmass: component.Zmass,
    })
  }

  static getConfiguratedDeckschichten(component: ConstructionComponent): InsideOutsideArray<Partial<ConfiguratedDeckschicht>> {
    const deckschichten: InsideOutsideArray<Partial<ConfiguratedDeckschicht>> = {
      Inside: [],
      Outside: []
    }
    const sides = [SideType.Inside, SideType.Outside]
    sides.forEach((side): void => {
      if (component.Deckschichten && component.Deckschichten[side] && component.Deckschichten[side].length > 0) {
        deckschichten[side] = []
        component.Deckschichten[side].forEach((ds): void => {
          deckschichten[side].push(
            {
              Id: ds.Id,
              Typ: 'fuellung',
              HasBeschichtung: ds.Beschichtung?.Id > 0,
              Beschichtung: {
                Id: ds.Beschichtung?.Id,
                Typ: ds.Beschichtung?.Typ
              }
            }
          )
        })
      }
    })
    return deckschichten
  }

  static getFluegelrahmenColor(
    configuratedDoor: ConfiguratedDoor,
    component: ConstructionComponent
  ): InsideOutsideArray<FluegelrahmenColor> {
    return {
      Inside: ((f): FluegelrahmenColor[] => f === null ? [] : [{
        Id: f.Id,
        Bezeichnung: configuratedDoor.profile.Beschreibung,
        Typ: 'fluegelrahmen',
        Beschichtung: {
          Id: f.Beschichtung.Id,
          Typ: f.Beschichtung.Typ
        }
      }])(component?.Fluegelrahmen.Inside),
      Outside: ((f): FluegelrahmenColor[] => f === null ? [] : [{
        Id: f.Id,
        Bezeichnung: configuratedDoor.profile.Beschreibung,
        Typ: 'fluegelrahmen',
        Beschichtung: {
          Id: f.Beschichtung.Id,
          Typ: f.Beschichtung.Typ
        }
      }])(component?.Fluegelrahmen.Outside),
    }
  }
}
