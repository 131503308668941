import {BrowserModule} from '@angular/platform-browser'
import {LOCALE_ID, NgModule} from '@angular/core'
import {FormBuilder, FormsModule, ReactiveFormsModule} from '@angular/forms'
import {HttpClientModule} from '@angular/common/http'
import {CookieModule} from 'ngx-cookie'
import {AppComponent} from './app.component'
import {FormControlPipe} from './classes/util/formControlPipe'
import {RouterModule} from '@angular/router'
import {TranslateModule} from './translate'
import {CustomerStyleDirective} from './style/customer-style'
import {ConfiguratorComponent} from './configurator/configurator.component'
import {LazyLoadImageModule} from 'ng-lazyload-image'
import {HttpService} from './http.service'
import {DeviceModule} from './device/device.module'
import {NgxD3Service} from '@d-m-p/ngx-d3'
import {DoorMaskComponent} from './d3/door.mask'
import {DoorViewComponent} from './d3/door.view'
import {NgxUploaderModule} from '@angular-ex/uploader'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {AccordionComponent} from './configurator/components/accordion/accordion.component'
import {MatDialogModule} from '@angular/material/dialog'
import {DialogueModalComponent} from './configurator/modal/dialogue-modal/dialogue-modal.component'
import {NotesModalComponent} from './configurator/modal/notes-modal/notes-modal.component'
import {PrivacyModalComponent} from './configurator/modal/privacy-modal/privacy-modal.component'
import {MatInputModule} from '@angular/material/input'
import {MatFormFieldModule} from '@angular/material/form-field'
import {MatRadioModule} from '@angular/material/radio'
import {MassblattModalComponent} from './configurator/modal/massblatt-modal/massblatt-modal.component'
import {MasseModalComponent} from './configurator/modal/masse-modal/masse-modal.component'
import {KonstruktionModalComponent} from './configurator/modal/konstruktion-modal/konstruktion-modal.component'
import {MehrpreisModalComponent} from './configurator/modal/mehrpreis-modal/mehrpreis-modal.component'
import {SupportModalComponent} from './configurator/modal/support-modal/support-modal.component'
import {SprossenModalComponent} from './configurator/modal/sprossen-modal/sprossen-modal.component'
import {GlassModalComponent} from './configurator/modal/glass-modal/glass-modal.component'
import {ImageModalComponent} from './configurator/modal/image-modal/image-modal.component'
import {PrivacyComponent} from './privacy/privacy.component'
import {MatSidenavModule} from '@angular/material/sidenav'
import {ToastrModule} from 'ngx-toastr'
import {MatTabsModule} from '@angular/material/tabs'
import {MtxTooltipModule} from '@ng-matero/extensions/tooltip'
import {MatCheckboxModule} from '@angular/material/checkbox'
import {MatDividerModule} from '@angular/material/divider'
import {MatIconModule} from '@angular/material/icon'
import {MatButtonModule} from '@angular/material/button'
import {MatOptionModule} from '@angular/material/core'
import {MatSelectModule} from '@angular/material/select'
import {MatTableModule} from '@angular/material/table'
import {MatGridListModule} from '@angular/material/grid-list'
import {
  KonstruktionsauswahlModalComponent
} from './configurator/modal/konstruktionsauswahl-modal/konstruktionsauswahl-modal.component'
import {ScrollingModule} from '@angular/cdk/scrolling'
import {CatalogueMenuComponent} from './configurator/menu/catalogue-menu/catalogue-menu.component'
import {GrundformMenuComponent} from './configurator/menu/grundform-menu/grundform-menu.component'
import {ModelMenuComponent} from './configurator/menu/model-menu/model-menu.component'
import {BaseMenuComponent} from './configurator/menu/base-menu/base-menu.component'
import {BasisMenuComponent} from './configurator/menu/basis-menu/basis-menu.component'
import {KeyDataMenuComponent} from './configurator/menu/key-data-menu/key-data-menu.component'
import {HausfrontModalComponent} from './configurator/modal/hausfront-modal/hausfront-modal.component'
import BatterMatRadioGroupDirective from './batter-mat-radio-group.directive'
import {OptionsMenuComponent} from './configurator/menu/options-menu/options-menu.component'
import {GlassesMenuComponent} from './configurator/menu/glasses-menu/glasses-menu.component'
import {ColorsMenuComponent} from './configurator/menu/colors-menu/colors-menu.component'
import {InstanceofPipe} from './classes/util/instanceof.pipe'
import {MehrpreisMenuComponent} from './configurator/menu/mehrpreis-menu/mehrpreis-menu.component'
import {HausfrontMenuComponent} from './configurator/menu/hausfront-menu/hausfront-menu.component'
import {ShareModalComponent} from './configurator/modal/share-modal/share-modal.component'
import {ModelConfigurationService} from './classes/service/modelConfiguration.service'
import {ConfiguratorModeService} from './classes/service/configuratorMode.service'
import {ParameterService} from './classes/service/parameter/parameter.service'
import {ModalFrameComponent} from './configurator/modal/modal-frame/modal-frame.component'
import WorkflowModule from './workflow/workflow.module'
import {LoadingModalComponent} from './configurator/modal/loading-modal/loading-modal.component'
import {
  LoadingModalBackdropStyleComponent
} from './configurator/modal/loading-modal/loading-modal-backdrop-style.component'
import {LoadNSearchMenuComponent} from './configurator/menu/load-n-search-menu/load-n-search-menu.component'
import {ClipboardModule} from '@angular/cdk/clipboard'
import {SettingsService} from './classes/service/settings/settings.service'
import {FullscreenToggleComponent} from './configurator/fullscreen-toggle/fullscreen-toggle.component'
import {ComponentSelectorComponent} from './configurator/component-selector/component-selector.component'
import {MatExpansionModule} from '@angular/material/expansion'
import {MatBadgeModule} from '@angular/material/badge'
import {MatTooltipModule} from '@angular/material/tooltip'
import {MenuNavigationComponent} from './configurator/menu/menu-navigation/menu-navigation.component'
import {StorageModule} from './storage/storage.module'
import {NgOptimizedImage} from '@angular/common'
import {RequestMenuComponent} from './configurator/menu/request-menu/request-menu.component'
import {ImageLoadingAnimationDirective} from './image-loading-animation/image-loading-animation.directive'
import {ImageLoadingAnimationComponent} from './image-loading-animation/image-loading-animation.component'
import {
  LoadConfigurationButtonComponent
} from './configurator/components/load-configuration-button/load-configuration-button.component'
import {LoadDoorIdModalComponent} from './configurator/modal/load-door-id-modal/load-door-id-modal.component'
import {CdkTableModule} from '@angular/cdk/table'
import {CookieConsentModule} from './cookie-consent'
import {CharacteristicsMenuComponent} from './configurator/menu/characteristics-menu/characteristics-menu.component'
import {UrlParameterModalComponent} from './configurator/modal/url-parameter-modal/url-parameter-modal.component'
import {MatSortModule} from '@angular/material/sort'
import {LoggerModule, NgxLoggerLevel, TOKEN_LOGGER_MAPPER_SERVICE} from 'ngx-logger'
import {environment} from '../environments/environment'
import LoggerWebpackMapperService from './classes/logging/logger-webpack-mapper-service'
import {
  CaseForTypeDirective,
  CaseForTypeUnknownDirective,
  SwitchOnTypeOfDirective
} from './directives/switch-on-type-of.directive'
import {
  ConstructionChooserModalComponent
} from './configurator/modal/construction-chooser-modal/construction-chooser-modal.component'
import {AccordionGroupComponent} from './configurator/components/accordion/accordion-group.component'
import {SafeHtmlPipe} from './safeHTML/safehtml.pipe'
import {LoadModule} from './configurator/load'
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component'
import {
  preloadConfiguratorRequiredServicesResolver
} from './configurator/./load/preload-configurator-required-services.resolver'

@NgModule({
  declarations: [
    AppComponent,
    PrivacyComponent,
    FormControlPipe,
    CustomerStyleDirective,
    ConfiguratorComponent,
    DoorMaskComponent,
    DoorViewComponent,
    AccordionComponent,
    AccordionGroupComponent,
    DialogueModalComponent,
    NotesModalComponent,
    KonstruktionModalComponent,
    KonstruktionsauswahlModalComponent,
    MassblattModalComponent,
    MasseModalComponent,
    MehrpreisModalComponent,
    HausfrontModalComponent,
    SupportModalComponent,
    SprossenModalComponent,
    GlassModalComponent,
    ImageModalComponent,
    ShareModalComponent,
    BaseMenuComponent,
    CatalogueMenuComponent,
    GrundformMenuComponent,
    CharacteristicsMenuComponent,
    HausfrontMenuComponent,
    ModelMenuComponent,
    BasisMenuComponent,
    KeyDataMenuComponent,
    OptionsMenuComponent,
    GlassesMenuComponent,
    MehrpreisMenuComponent,
    ColorsMenuComponent,
    BatterMatRadioGroupDirective,
    InstanceofPipe,
    ModalFrameComponent,
    LoadConfigurationButtonComponent,
    LoadDoorIdModalComponent,
    LoadingModalComponent,
    LoadingModalBackdropStyleComponent,
    LoadNSearchMenuComponent,
    FullscreenToggleComponent,
    ComponentSelectorComponent,
    MenuNavigationComponent,
    ImageLoadingAnimationComponent,
    ImageLoadingAnimationDirective,
    RequestMenuComponent,
    UrlParameterModalComponent,
    PrivacyModalComponent,
    SwitchOnTypeOfDirective,
    CaseForTypeDirective,
    CaseForTypeUnknownDirective,
    ConstructionChooserModalComponent,
    SafeHtmlPipe,
    LoadingIndicatorComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ClipboardModule,
    CookieModule.forRoot(),
    DeviceModule,
    FormsModule,
    HttpClientModule,
    LazyLoadImageModule,
    LoggerModule.forRoot({
      enableSourceMaps: !environment.production,
      level: environment.production
        ? environment.LOGGER ? NgxLoggerLevel.ERROR : NgxLoggerLevel.OFF
        : NgxLoggerLevel.TRACE,
    }),
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatTableModule,
    MatTabsModule,
    MtxTooltipModule,
    NgxUploaderModule,
    ReactiveFormsModule,
    RouterModule.forRoot([
      {path: '', component: ConfiguratorComponent, data: {name: 'app'}, resolve: { init: preloadConfiguratorRequiredServicesResolver}},
      {path: 'privacy', component: PrivacyComponent, data: {name: 'privacy'}},
      {path: '**', component: ConfiguratorComponent, data: {name: 'app'}},
    ]),
    ScrollingModule,
    StorageModule,
    ToastrModule.forRoot({
      closeButton: true,
      positionClass: 'toast-top-center',
    }),
    WorkflowModule.forRoot(100),
    MatExpansionModule,
    MatBadgeModule,
    MatTooltipModule,
    NgOptimizedImage,
    CdkTableModule,
    CookieConsentModule,
    TranslateModule,
    MatSortModule,
    LoadModule
  ],
  exports: [RouterModule, NgxUploaderModule],
  providers: [
    HttpService,
    FormBuilder,
    NgxD3Service,
    {
      provide: LOCALE_ID,
      useValue: 'de-DE'
    },
    { provide: TOKEN_LOGGER_MAPPER_SERVICE, useClass: LoggerWebpackMapperService },
    ParameterService,
    SettingsService,
    ModelConfigurationService,
    ConfiguratorModeService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}

