import {Attribute, Component, Input, Optional, TemplateRef} from '@angular/core'
import {NavigatorService} from '../../../classes/service/navigator.service'
import {ConfiguratorMode} from '../../../types'
import {ConfiguratorModeService} from '../../../classes/service/configuratorMode.service'

@Component({
  selector: 'configurator-base-menu, configurator-base-menu[component-selector]',
  templateUrl: './base-menu.component.html',
  styleUrls: ['base-menu.component.scss']
})
export class BaseMenuComponent {
  protected componentSelector: boolean
  protected readonly ConfiguratorMode = ConfiguratorMode
  @Input() isMobile: boolean
  @Input() menuClass: string
  @Input() menuHead: TemplateRef<unknown>
  @Input() menuNavigatorIdentifier: NavigatorService['showToggle']

  constructor(
    private _navigatorService: NavigatorService,
    private _configuratorModeService: ConfiguratorModeService,
    @Optional() @Attribute('has-component-selector') componentSelector: string | boolean | null
  ) {
    this.componentSelector = componentSelector !== null && componentSelector !== 'false'
  }

  get navigatorService(): NavigatorService {
    return this._navigatorService
  }

  get configuratorMode(): ConfiguratorMode {
    return this._configuratorModeService.mode
  }
}
