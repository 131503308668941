import {ComponentModel} from '../model/component-model'
import {ConfiguratedDeckschicht, ConfiguratedDienstleistung, ConfiguratedMehrpreis, Konstruktion} from '../../../../class'
import {ConfiguratorMode, InsideOutsideArray, InsideOutsideObject, Oeffnungsart, SideType} from '../../../../types'
import {Deckschicht} from '../../color/deckschicht'
import {Blendrahmen} from '../frame/blendrahmen'
import {Fluegelrahmen} from '../frame/fluegelrahmen'
import {Glasaufbau, GlasaufbauFactory} from '../glassaufbau/glasaufbau'
import {ConfigurationComponent} from '../../../configurationComponent'
import {ColorBase} from '../../color/colorBase'
import {Material} from '../../material'
import {Option} from '../other/option'
import {MehrpreisEntry} from '../extras/zubehoer/mehrpreisEntry'
import {Zubehoer} from '../extras/zubehoer/zubehoer'
import {ZubehoerAddonEntry} from '../extras/zubehoer/zubehoerAddonEntry'
import {ChangeType} from '../../event/events.types'
import {ZUBEHOER_CATEGORY_HAUSNUMMER} from '../extras/zubehoer/zubehoer.type'
import {ConstructionComponentType} from './construction-component-type.enum'
import {AdhesiveSystem} from '../../../api/model/adhesive/adhesive-system.interface'
import {Folie} from '../../color/folie'
import {Pulver} from '../../color/pulver'
import {Lack} from '../../color/lack'
import {isSimpleConstruction, SimpleConstruction, SingleConstruction} from '../other/construction'
import {Beschichtung} from '../../color/beschichtung'
import {ConstructionDimensions} from './construction-dimensions'
import {LetterMapping, Massblatt} from '../extras/massblatt'
import {fbsMehrpreis} from '../extras/mehrpreis/fbsMehrpreis'
import {Dienstleistung} from '../extras/mehrpreis/dienstleistung'

const TRANSLATION_KEY = {
  INFO_REMOVED_GRIFFSCHALE_TITLE: 'ConstructionComponent.Info.GriffschaleRemoved.Title',
  INFO_REMOVED_GRIFFSCHALE_MESSAGE: 'ConstructionComponent.Info.GriffschaleRemoved.Message',
  INFO_REMOVED_GRIFF_MESSAGE: 'ConstructionComponent.Info.GriffRemoved.Message',
  INFO_REMOVED_GRIFF_TITLE: 'ConstructionComponent.Info.GriffRemoved.Title'
} as const

export abstract class ConstructionComponent {
  #KonstruktionsMasse: ConstructionDimensions
  Blendrahmen: InsideOutsideObject<Blendrahmen | null>
  Deckschichten: InsideOutsideArray<Deckschicht>
  Fluegelrahmen: InsideOutsideObject<null | Fluegelrahmen>
  Hoehenverteilung: number
  Index: number
  IndexByType: number
  Klebeset: boolean = false
  Klebesystem: AdhesiveSystem
  Mehrpreise: MehrpreisEntry[]
  ProfilId: number
  Querfries: number
  Zmass: number = 0
  private _breite = 880
  private _din = 0
  private _dinfuellung = 0
  _objectType: ConstructionComponentType
  defaultStaerke = true // show is staerke value has been altered
  fbsKonstruktion: Konstruktion
  glasaufbau: Glasaufbau
  hoehe = 2100
  konstruktion: SimpleConstruction
  material: Material
  model: ComponentModel
  oeffnungsart: Oeffnungsart
  optionen: InsideOutsideArray<Option>
  staerke = 0

  constructor(
    data: Partial<ConstructionComponent>,
    glasaufbauFactory: GlasaufbauFactory
  ) {
    // id is modelId
    this.Index = data && data.Index
    this.Querfries = data && data.Querfries || 0
    this.Hoehenverteilung = data && data.Hoehenverteilung || 0
    this._breite = data && data.breite || 880
    this.hoehe = data && data.hoehe || 2100
    this.Klebesystem = data && data.Klebesystem
    this.Klebeset = data && data.Klebeset
    this.fbsKonstruktion = data && new Konstruktion(data.fbsKonstruktion)
    this.konstruktion = typeof data?.konstruktion === 'number' && isSimpleConstruction(data.konstruktion)
      ? data.konstruktion
      : SimpleConstruction.AufsatzEinseitig
    this.oeffnungsart = data && data.oeffnungsart || 'innen'
    // changed din string to number
    // this.din = data && data.din || 0
    // this.dinfuellung = data && data.dinfuellung || 0
    this.optionen = {
      Inside: [],
      Outside: []
    }
    if (data && data.optionen) {
      this.optionen.Inside = data.optionen.Inside.map((o): Option => new Option(o))
      this.optionen.Outside = data.optionen.Outside.map((o): Option => new Option(o))
    }
    this._din = data && data.din
    this._dinfuellung = data && data.dinfuellung
    this.model = data && data.model && new ComponentModel(data.model)
    this.ProfilId = data && data.ProfilId
    this.KonstruktionsMasse = data && data.KonstruktionsMasse && new ConstructionDimensions(data.KonstruktionsMasse)
    this.material = data && data.material
    this.staerke = data && data.staerke || 0
    this.defaultStaerke = data && data.defaultStaerke || true
    this.glasaufbau = glasaufbauFactory.create(this.model, data?.glasaufbau)
    this.Deckschichten = {
      Inside: [],
      Outside: []
    }
    data?.Deckschichten?.Inside?.forEach(d => void this.Deckschichten.Inside.push(new Deckschicht(d)))
    data?.Deckschichten?.Outside?.forEach(d => void this.Deckschichten.Outside.push(new Deckschicht(d)))
    this.Zmass = data && data.Zmass || 0
    if (data?.Blendrahmen instanceof Array) {
      this.Blendrahmen = {
        Inside: data.Blendrahmen
          .filter((b: unknown): boolean => typeof b === 'object' && 'IsInnen' in b && !!b?.IsInnen)
          .map((b: unknown): Blendrahmen => new Blendrahmen(b)).find((): boolean => true) ?? null,
        Outside: data.Blendrahmen
          .filter((b: unknown): boolean => typeof b === 'object' && 'IsAussen' in b && !!b?.IsAussen)
          .map((b: unknown): Blendrahmen => new Blendrahmen(b)).find((): boolean => true) ?? null
      }
    } else if (data?.Blendrahmen && SideType.Inside in data.Blendrahmen && SideType.Outside in data.Blendrahmen) {
      this.Blendrahmen = data?.Blendrahmen
    } else {
      this.Blendrahmen = {
        Inside: null,
        Outside: null
      }
    }
    if (data?.Fluegelrahmen instanceof Array) {
      this.Fluegelrahmen = {
        Inside: data.Fluegelrahmen
          .filter((f: unknown): boolean => typeof f === 'object' && 'IsInnen' in f && !!f?.IsInnen)
          .map((f: unknown): Fluegelrahmen => new Fluegelrahmen(f)).find((): boolean => true) ?? null,
        Outside: data.Fluegelrahmen
          .filter((f: unknown): boolean => typeof f === 'object' && 'IsAussen' in f && !!f?.IsAussen)
          .map((f: unknown): Fluegelrahmen => new Fluegelrahmen(f)).find((): boolean => true) ?? null
      }
    } else if (data?.Fluegelrahmen && SideType.Inside in data.Fluegelrahmen && SideType.Outside in data.Fluegelrahmen) {
      this.Fluegelrahmen = data?.Fluegelrahmen
    } else {
      this.Fluegelrahmen = {
        Inside: null,
        Outside: null
      }
    }
    this.Mehrpreise = []
    if (data && data.Mehrpreise) {
      data.Mehrpreise.forEach((m): void => {
        this.Mehrpreise.push(new MehrpreisEntry(m))
      })
    }
  }

  addDefaultOptions(): void {
    // add the standard/mandatory options if none are selected
    this.model.Optionen.forEach((o: Option): void => {
      const side = o.IsInnen ? 'Inside' : o.IsAussen ? 'Outside' : undefined
      if (typeof side === 'undefined') {
        return
      }
      if (this.optionen[side].some((option): boolean => option.Id === o.Id && option.Typ === o.Typ)) {
        return
      }
      if (o.IsStandard && o.suitableForMaterial(this.material)) {
        this.optionen[side].push(new Option(o))
      }
    })
  }

  private addOption(option: Option, replace = true): Option | null {
    const side = option.IsAussen ? SideType.Outside : SideType.Inside
    const optionIndex = this.optionen[side].findIndex((o): boolean => o.Gruppe === option.Gruppe && o.Id === option.Id)
    const op = new Option(option)
    if (option.BeschichtungAnpassen) {
      op.Beschichtung = new Beschichtung(this.Deckschichten[side][0].Beschichtung)
    }
    if (optionIndex === -1) {
      this.optionen[side].push(op)
      // Kassette may not be active without a rahmen
    } else if (replace) {
      this.optionen[side][optionIndex] = op
    } else {
      return null
    }
    return op
  }

  public applyDeckschichtenColorsFromConfiguration(config: ConfigurationComponent): void {
    ([SideType.Inside, SideType.Outside] as const).forEach((side): void => {
      this.Deckschichten[side as SideType].forEach((d): void => {
        const cds = config.Deckschichten[side].find((ds): boolean => ds.Id === d.Id)
        if (!cds || !cds.HasBeschichtung) {
          return
        }
        const dbs = this.model.findObjectFromData(cds.Beschichtung)
        if (dbs && dbs instanceof ColorBase) {
          d.colorize(dbs, SideType.Outside, config.Material)
        }
      })
    })
  }

  public applyFluegelrahmenColorsFromConfiguration(config: ConfigurationComponent): void {
    [SideType.Inside, SideType.Outside].forEach((side): void => {
      const configuratedFluegelrahmen = config.Fluegelrahmen[side]?.find((): boolean => true)
      if (configuratedFluegelrahmen.HasBeschichtung) {
        this.Fluegelrahmen[side]?.colorize(
          this.model.findObjectFromData<Lack | Folie | Pulver>(configuratedFluegelrahmen.Beschichtung),
          side,
          config.Material
        )
      }
    })
  }

  public applyMehrpreiseFromConfiguration(config: ConfigurationComponent): void {
    this.Mehrpreise = []
    config.Mehrpreise.forEach((mehrpreis: ConfiguratedMehrpreis): void => {
      if (mehrpreis.HasZubehoer) {
        mehrpreis.Zubehoer.forEach(z =>
          void this.toggleZubehoer(
            this.model.findZubehoer(z.Id) ?? new Zubehoer({...z, Id: undefined})
          )
        )
      }
      if (mehrpreis.HasDienstleistungen) {
        mehrpreis.Dienstleistungen.forEach((d: ConfiguratedDienstleistung): void => {
          if (
            typeof this.getZubehoerEntry(mehrpreis.Typ)?.getAddon(d.Typ)
            === 'undefined'
          ) {
            this.toggleZubehoerAddon(mehrpreis.Typ, d.Typ)
          }
          const addonEntry =
            this.getZubehoerEntry(mehrpreis.Typ).getAddon(d.Typ)
          if (d.Massblaetter.length > 0) {
            const massblatt: Massblatt = this.model
              .Mehrpreise.find((mp: fbsMehrpreis): boolean => mp.Id === mehrpreis.Id)
              ?.Dienstleistungen.find((addon: Dienstleistung): boolean => addon.Id === d.Id)
              ?.Massblaetter.find((mb: Massblatt): boolean => mb.Id === d.Massblaetter[0].Id)
            if (massblatt && addonEntry) {
              addonEntry.Massblatt = new Massblatt(massblatt)
              addonEntry.Massblatt.Values = new LetterMapping(d.Massblaetter[0].Values)
              addonEntry.Massblatt.ScriptId = d.Massblaetter[0].ScriptId
            }
          }
        })
      }
    })
  }

  applyOptionsFromConfig(configuration: ConfigurationComponent): void {
    Object.entries({
      [SideType.Inside]: 'IsInnen',
      [SideType.Outside]: 'IsAussen'
    } as const).forEach(([side, sideIdentifier]): void => {
      configuration.Optionen[side as SideType]?.forEach((confOption): void => {
        const option = this.addOption(
          this.model.Optionen.find((o): boolean => o.Id === confOption.Id && o[sideIdentifier])
        )
        // get DeckschichtenStaerke!!!
        // const option = this.optionen[side as SideType]?.[this.optionen[side as SideType].length - 1]
        if (option) {
          option.applyConfiguratedColor(this.model, confOption, side as SideType, configuration.Material)
        }
      })
    })
  }

  clearHausnummer(): ChangeType | false {
    const zubehoer = this.getZubehoerEntry(ZUBEHOER_CATEGORY_HAUSNUMMER)
    return zubehoer ? this.toggleZubehoer(new Zubehoer({Typ: ZUBEHOER_CATEGORY_HAUSNUMMER})) : false
  }

  clearMehrpreise(): void {
    this.Mehrpreise = []
  }

  clearOptions(): void {
    this.optionen = {
      Inside: [],
      Outside: []
    }
  }

  public clearZubehoerEntryIfEmpty(zubehoer: MehrpreisEntry | undefined): ChangeType.Removed | false {
    if (zubehoer?.isEmpty()) {
      const index = this.Mehrpreise.indexOf(zubehoer)
      if (index > -1 && this.Mehrpreise.splice(index, 1).length !== 0) {
        return ChangeType.Removed
      }
    }
    return false
  }

  private copyDeckschichtenFromConfiguration(config: ConfigurationComponent): void {
    this.Deckschichten = {
      Inside: (config?.Deckschichten?.Inside ?? []).map((ds: ConfiguratedDeckschicht): Deckschicht => new Deckschicht(ds)),
      Outside: (config?.Deckschichten?.Outside ?? []).map((ds: ConfiguratedDeckschicht): Deckschicht => new Deckschicht(ds))
    }
  }

  getHausnummerValue(): string {
    return this.getZubehoerEntry(ZUBEHOER_CATEGORY_HAUSNUMMER)?.Item?.Value || ''
  }

  public getOrCreateZubehoerEntry(categoryName: string): MehrpreisEntry {
    let zubehoerEntry = this.getZubehoerEntry(categoryName)
    if (!zubehoerEntry) {
      zubehoerEntry = new MehrpreisEntry({Typ: categoryName})
      this.Mehrpreise.push(zubehoerEntry)
    }
    return zubehoerEntry
  }

  getZubehoerEntry(categoryName: string): MehrpreisEntry | undefined {
    return this.Mehrpreise.find((z): boolean => z.Typ === categoryName)
  }

  hasDeckschichten(side: SideType): boolean {
    return this.Deckschichten[side].length > 0
  }

  hasGriffSchaleSelected(): MehrpreisEntry | undefined {
    return this.Mehrpreise.find((z: MehrpreisEntry): boolean => z.Typ === 'griffschale')
  }

  hasGriffSelected(): MehrpreisEntry | undefined {
    return this.Mehrpreise.find((z: MehrpreisEntry): boolean => z.Typ === 'griff')
  }

  hasOptionSelected(option: Option, side?: SideType): boolean {
    const options = side
      ? this.optionen[side]
      : this.optionen[SideType.Inside].concat(this.optionen[SideType.Outside])
    return typeof options.find((o): boolean => o.Typ === option.Typ && o.Id === option.Id) !== 'undefined'
  }

  private mergeZubehoerChange(
    zubehoerCreated: boolean,
    zubehoerRemoved: boolean,
    zubehoerUpdated: boolean
  ): ChangeType | false {
    return zubehoerRemoved
      ? zubehoerCreated
        ? false
        : ChangeType.Removed
      : zubehoerCreated
        ? ChangeType.Added
        : zubehoerUpdated
          ? ChangeType.Updated
          : false
  }

  private removeItemFromZubehoerEntry(categoryName: string): ChangeType.Removed | ChangeType.Updated | false {
    const zubehoer = this.getZubehoerEntry(categoryName)
    const itemRemoved = zubehoer?.removeItem()
    const zubehoerRemoved = this.clearZubehoerEntryIfEmpty(zubehoer)
    return zubehoerRemoved !== false ? ChangeType.Removed : itemRemoved !== false ? ChangeType.Updated : false
  }

  resetOptions(): void {
    this.clearOptions()
    this.addDefaultOptions()
    const config = this.model.Konfiguration.Components[0]
    if (config) {
      this.applyOptionsFromConfig(config)
    }
  }

  restoreOptionsStateFromConfig(config: ConfigurationComponent): void {
    ([SideType.Inside, SideType.Outside]).forEach((side): void => {
      this.optionen[side]?.forEach((option): void => {
        const configOption = config.Optionen[side]?.find((o): boolean => o.Id === option.Id)
        if (!configOption) {
          this.toggleOption(option)
        }
      })
    })
  }

  public setConfiguration(config: ConfigurationComponent, mode: ConfiguratorMode): void {
    this.copyDeckschichtenFromConfiguration(config)
    // this.Blendrahmen = config.resolveBlendrahmen(this.model.Blendrahmen)
    this.Fluegelrahmen = config.resolveFluegelrahmen(this.model.Fluegelrahmen)
    this.Klebesystem = config.resolveKlebesystem(this.model.Klebesysteme)
    this.fbsKonstruktion = config.resolveKonstruktionsVariante(this.model.Konstruktionen)
    this.dinfuellung = config.Din
    if (mode === ConfiguratorMode.TTK) {
      this.konstruktion = config.Konstruktion
      this.material = config.Material
    }
    this.applyDeckschichtenColorsFromConfiguration(config)
    this.applyFluegelrahmenColorsFromConfiguration(config)
  }

  singleConstruction(view: SideType): SingleConstruction {
    switch (this.konstruktion) {
      case SimpleConstruction.Einsatz:
        return SingleConstruction.Einsatz
      case SimpleConstruction.AufsatzEinseitig:
        return view === SideType.Outside ? SingleConstruction.Aufsatz : SingleConstruction.Einsatz
      case SimpleConstruction.AufsatzBeidseitig:
        return SingleConstruction.Aufsatz
    }
  }

  toggleOption(option: Option): void {
    const side = option.IsAussen ? SideType.Outside : SideType.Inside
    const sameOptionExists = this.optionen[side].find((o): boolean => o.Id === option.Id)
    const optionWithSameGroupExists = this.optionen[side].find((o): boolean => o.Gruppe === option.Gruppe)
    const optionWithSameTypeExists = this.optionen[side].find((o): boolean => o.Typ === option.Typ)
    if (sameOptionExists) {
      // if there is already the SAME option remove it
      const index = this.optionen[side].findIndex((o): boolean => o.Id === option.Id)
      this.optionen[side].splice(index, 1)
    } else if (
      // if there was no option with the same type -> add it
      !optionWithSameTypeExists
      || // if there is already an option with the same group -> add it
      optionWithSameGroupExists
    ) {
      this.addOption(option)
    } else { // } if (this.optionen[side].find(o => o.Gruppe !== option.Gruppe)) {
      // if the Option group is different, remove all elements of the type, then add option
      // deleting backwards
      let length = this.optionen[side].length - 1
      while (length >= 0) {
        const loopItem = this.optionen[side][length]
        if (loopItem.Typ === option.Typ) {
          this.optionen[side].splice(length, 1)
        }
        length -= 1
      }
      this.addOption(option)
    }
  }

  /**
   * Toggles zubehoer. Returns wether zubehoer was added (true) or removed (false).
   * @param zubehoer
   * @return boolean true = zubehore was added; false = zubehoer was removed
   */
  toggleZubehoer(zubehoer: Zubehoer, additionalChecks: boolean = false): ChangeType | false {
    let zubehoerEntry = this.getZubehoerEntry(zubehoer.Typ)
    if (
      (zubehoerEntry && zubehoer.Typ === ZUBEHOER_CATEGORY_HAUSNUMMER)
      || zubehoerEntry?.Item && zubehoerEntry.Item.Id === zubehoer.Id
    ) {
      return this.removeItemFromZubehoerEntry(zubehoer.Typ)
    } else {
      zubehoerEntry ??= this.getOrCreateZubehoerEntry(zubehoer.Typ)
      const montage = this.model.getMontageDienstleistung(zubehoer.Typ)
      zubehoerEntry.addItem(
        zubehoer,
        typeof montage !== 'undefined' ? ZubehoerAddonEntry.fromDienstleistung(montage) : undefined
      )
      if (additionalChecks) {
        if (zubehoer.Typ === 'griff') {
          const griffschale = this.hasGriffSchaleSelected()
          if (griffschale) {
            this.removeItemFromZubehoerEntry(griffschale.Item.Typ)
            this.glasaufbau?.toastrService.info(
              this.glasaufbau?.translateService.translate(TRANSLATION_KEY.INFO_REMOVED_GRIFFSCHALE_MESSAGE),
              this.glasaufbau?.translateService.translate(TRANSLATION_KEY.INFO_REMOVED_GRIFFSCHALE_TITLE)
            )
          }
        } else if (zubehoer.Typ === 'griffschale') {
          const griff = this.hasGriffSelected()
          if (griff) {
            this.removeItemFromZubehoerEntry(griff.Item.Typ)
            this.glasaufbau?.toastrService.info(
              this.glasaufbau?.translateService.translate(TRANSLATION_KEY.INFO_REMOVED_GRIFF_MESSAGE),
              this.glasaufbau?.translateService.translate(TRANSLATION_KEY.INFO_REMOVED_GRIFF_TITLE)
            )
          }
        }
      }
      const zubehoerRemoved = this.clearZubehoerEntryIfEmpty(zubehoerEntry)
      return zubehoerRemoved !== ChangeType.Removed ? ChangeType.Added : false
    }
  }

  toggleZubehoerAddon(zubehoerCategory: string, addonTyp: string): ChangeType | false {
    const createdZubehoer = typeof this.getZubehoerEntry(zubehoerCategory) === 'undefined'
    let updatedZubehoer = false
    const zubehoerEntry = this.getOrCreateZubehoerEntry(zubehoerCategory)
    const dienstleistung = this.model.Mehrpreise.find((m): boolean => zubehoerEntry.Typ === m.Typ)
      ?.Dienstleistungen.find((d): boolean => d.Typ === addonTyp)
    if (typeof dienstleistung !== 'undefined') {
      zubehoerEntry.toggleAddon(ZubehoerAddonEntry.fromDienstleistung(dienstleistung))
      updatedZubehoer = true
    }
    const removedZubehoer = this.clearZubehoerEntryIfEmpty(zubehoerEntry)
    return this.mergeZubehoerChange(
      createdZubehoer,
      removedZubehoer === ChangeType.Removed,
      updatedZubehoer
    )
  }

  updateHausnummer(newHausnummer: string): ChangeType | false {
    if (newHausnummer.length === 0) {
      return this.clearHausnummer()
    }
    const zubehoer = this.getZubehoerEntry(ZUBEHOER_CATEGORY_HAUSNUMMER)
    if (zubehoer?.Item) {
      zubehoer.Item.Value = newHausnummer
      return ChangeType.Updated
    }
    return this.toggleZubehoer(new Zubehoer({
      Typ: ZUBEHOER_CATEGORY_HAUSNUMMER,
      Value: newHausnummer
    }))
  }

  get KonstruktionsMasse(): ConstructionDimensions {
    return this.#KonstruktionsMasse
  }

  set KonstruktionsMasse(value: ConstructionDimensions) {
    this.#KonstruktionsMasse = value
  }

  get breite(): number {
    return this._breite
  }

  set breite(val: number) {
    this._breite = val
  }

  get currentConstructionInside(): SingleConstruction {
    let construction: SingleConstruction = null
    if (this.konstruktion === SimpleConstruction.Einsatz) {
      construction = SingleConstruction.Einsatz
    } else if (this.konstruktion === SimpleConstruction.AufsatzBeidseitig) {
      construction = SingleConstruction.Aufsatz
    } else if (this.konstruktion === SimpleConstruction.AufsatzEinseitig) {
      construction = SingleConstruction.Einsatz
    }
    return construction
  }

  get currentConstructionOutside(): SingleConstruction {
    let construction: SingleConstruction = null
    if (this.konstruktion === SimpleConstruction.Einsatz) {
      construction = SingleConstruction.Einsatz
    } else if (this.konstruktion === SimpleConstruction.AufsatzBeidseitig) {
      construction = SingleConstruction.Aufsatz
    } else if (this.konstruktion === SimpleConstruction.AufsatzEinseitig) {
      construction = SingleConstruction.Aufsatz
    }
    return construction
  }

  get din(): number {
    return this._din
  }

  set din(din: number) {
    this._din = din
  }

  get dinfuellung(): number {
    return this._dinfuellung
  }

  set dinfuellung(din: number) {
    this._dinfuellung = din
  }

  get objectType(): ConstructionComponentType {
    return this._objectType
  }

  get shortObjectType(): 'S' | 'T' | 'O' {
    let shortObjectType: 'S' | 'T' | 'O'
    switch (this._objectType) {
      case ConstructionComponentType.Door:
        shortObjectType = 'T'
        break
      case ConstructionComponentType.SidePanel:
        shortObjectType = 'S'
        break
      case ConstructionComponentType.Fanlight:
        shortObjectType = 'O'
        break
    }
    return shortObjectType
  }
}

export abstract class ConstructionComponentFactory<Component extends ConstructionComponent, ComponentData = Partial<Component>> {
  protected constructor(
    private glasaufbauFactory: GlasaufbauFactory,
    protected readonly factoryConstructor: new (data: ComponentData, glasaufbauFactoryParam: GlasaufbauFactory) => Component
  ) {
  }

  create(data?: ComponentData): Component {
    return new this.factoryConstructor(data, this.glasaufbauFactory)
  }
}
