<configurator-base-menu
  [isMobile]='isMobile'
  [menuHead]='head'
  menuClass='grundform'
  menuNavigatorIdentifier='Grundform'
>
  <div class='container'>
    <div class='container-inner'>

      <article
        (click)='setGrundform(grundform)'
        *ngFor='let grundform of grundformService.grundformen'
        [class.active]='grundform.Key === currentGrundformKey'
        class='grundform'
      >

        <div class='image-wrapper'>
          <div *ngIf='!grundform.loaded' class='icon loading-image-spinner'>
            <i class='fa fa-spinner fa-pulse fa-3x fa-fw'></i>
          </div>
          <img
            (load)='lazyImageLoaded(grundform)'
            [alt]='grundform.Bezeichnung'
            [lazyLoad]='grundform.Url'
            [offset]='0'
          >
        </div>
        <p>{{ grundform.Bezeichnung | translate }}</p>

      </article>


    </div>
  </div>
</configurator-base-menu>

<ng-template #head>
  <div class='name'>
    <span>{{ TRANSLATION_KEY.TITLE | translate }}</span>
    <span (click)="navigatorService.setShowToggle('')" class='close'></span>
  </div>
</ng-template>
