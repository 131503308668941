import {ConfiguratorMode, Din, InsideOutsideObject, Oeffnungsart, SideType} from '../../types'
import {isMaterial, Material} from '../../classes/model/material'
import {ConfiguratedElement, Konstruktion} from '../../class'
import {Paket} from '../../classes/model/component/other/paket'
import {ConfiguratorConfigurationModel} from '../../classes/model/configuratorConfigurationModel'
import {HttpService} from '../../http.service'
import {ParameterService} from '../../classes/service/parameter/parameter.service'
import {ConfiguratorModeService} from '../../classes/service/configuratorMode.service'
import {ParameterMode, ParameterModes} from '../../classes/service/parameter/parameter-mode'
import {Injectable} from '@angular/core'
import {NGXLogger} from 'ngx-logger'
import {ConfiguratorDataModel} from '../../classes/model/configuratorDataModel'
import {GrundformService} from '../../classes/service/grundform.service'
import {firstValueFrom} from 'rxjs'
import {MaterialConflictResolutionStrategy} from './material-conflict-resolution-strategy.enum'
import {LoadingResult} from './loading-result.enum'
import {ConstructionComponent} from '../../classes/model/component/construction/constructionComponent'
import {ConfigurationComponent} from '../../classes/configurationComponent'
import {ConstructionComponentType} from '../../classes/model/component/construction/construction-component-type.enum'
import {Tuer} from '../../classes/model/component/construction/tuer'
import {Hausfront} from '../../classes/model/component/hausfront/hausfront'
import {ModalService} from '../modal/modal.service'
import {Grundform} from '../../classes/model/component/other/grundform'
import {ResponseHausfront} from '../../classes/api/grundformen/response-hausfront.interface'
import {HttpErrorResponse} from '@angular/common/http'
import {ConstructionDimensions} from '../../classes/model/component/construction/construction-dimensions'
import {AdhesiveSystem} from '../../classes/api/model/adhesive/adhesive-system.interface'
import {ConfigurationNotFoundError, MaterialConflictError, ModelHasDisabilitiesError} from './error.type'

@Injectable()
export class ConfigurationLoaderService {
  loadedConfig: ConfiguratedElement | null = null
  private materialConflictResolutionStrategy: MaterialConflictResolutionStrategy | null = null

  constructor(
    private configuratorConfigurationModel: ConfiguratorConfigurationModel,
    private configuratorDataModel: ConfiguratorDataModel,
    private configuratorModeService: ConfiguratorModeService,
    private httpService: HttpService,
    private logger: NGXLogger,
    private parameterService: ParameterService,
    private grundformService: GrundformService,
    private modalService: ModalService
  ) {
  }

  private applyComponentConfig(
    component: ConstructionComponent,
    configuration: ConfigurationComponent,
    systemMaterial: Material
  ): LoadingResult {
    const model = component.model
    // restore basic values
    component.Zmass = configuration.Zmass
    component.Querfries = configuration.Querfries
    component.Hoehenverteilung = configuration.Hoehenverteilung
    component.breite = configuration.Breite
    component.hoehe = configuration.Hoehe
    component.konstruktion =
      this.parameterService?.model?.konstruktion === 0 || this.parameterService?.model?.konstruktion > 0
        ? this.parameterService?.model?.konstruktion
        : configuration.Konstruktion
    component.Klebesystem = model.Klebesysteme.find((k: AdhesiveSystem): boolean => k.Id === configuration.Klebesystem)
    if (configuration.Klebeset === false) {
      component.Klebeset = false
    } else if (configuration.Klebesystem === 2) {
      component.Klebeset = true
    }
    component.staerke = configuration.Staerke
    component.fbsKonstruktion = component.model?.Konstruktionen
      .find((k: Konstruktion): boolean => k.Id === configuration.KonstruktionsVariante)
    // restore material
    let materialChanged: boolean = false
    const componentMaterial: Material =
      !isMaterial(configuration.Material)
      || (
        this.hasMaterialParameterChanged()
        && this.materialConflictResolutionStrategy === MaterialConflictResolutionStrategy.MatchFillingToSystem
      )
        ? systemMaterial
        : configuration.Material
    if (componentMaterial !== configuration.Material) {
      materialChanged = true
    }
    component.material = componentMaterial
    component.glasaufbau.updateGlasaufbau()
    // Restore DIN
    component.dinfuellung = configuration.Din
    component.din = this.loadedConfig.Din
    // update measures from params
    if (this.configuratorModeService.mode === ConfiguratorMode.FBS) {
      component.KonstruktionsMasse = ConstructionDimensions.fromParameterModel(
        this.parameterService.model,
        component.konstruktion
      )
    } else {
      component.KonstruktionsMasse =
        new ConstructionDimensions(configuration.KonstruktionsMasse)
    }
    // check if model still can be used
    this.configuratorDataModel.filterModels(
      component,
      component.konstruktion,
      undefined,
      undefined,
      [model]
    )
    if (model.disabilityReasons.length > 0) {
      throw new ModelHasDisabilitiesError(model)
    }
    // skip restore if material was adjusted
    if (materialChanged) {
      return LoadingResult.PartialLoad
    }
    // update glasaufbau Min/Max SZR Values with Model-Konfiguration Values
    if (
      model?.Konfiguration?.Components[0]?.Glasaufbau?.MinStaerkeSZR >= 0
      && model?.Konfiguration?.Components[0]?.Glasaufbau?.MaxStaerkeSZR >= 0
    ) {
      configuration.Glasaufbau.MinStaerkeSZR = model.Konfiguration.Components[0].Glasaufbau.MinStaerkeSZR
      configuration.Glasaufbau.MaxStaerkeSZR = model.Konfiguration.Components[0].Glasaufbau.MaxStaerkeSZR
    }
    component.glasaufbau.updateGlasaufbau(configuration.Glasaufbau)
    // restore options
    component.applyOptionsFromConfig(configuration)
    component.restoreOptionsStateFromConfig(configuration)
    // restore mehrpreise
    component.applyMehrpreiseFromConfiguration(configuration)
    // restore flügelrahmen
    const fluegelrahmenInside = configuration.Fluegelrahmen.Inside[0]
    const fluegelrahmenOutside = configuration.Fluegelrahmen.Outside[0]
    component.Fluegelrahmen = {
      Inside: model.findObjectFromData(fluegelrahmenInside, insideFilter),
      Outside: model.findObjectFromData(fluegelrahmenOutside, outsideFilter)
    }
    component.applyFluegelrahmenColorsFromConfiguration(configuration)
    // restore deckschicht
    component.applyDeckschichtenColorsFromConfiguration(configuration)
    return LoadingResult.Success
  }

  async applyLoadedConfig(): Promise<LoadingResult> {
    if (!this.hasLoadedConfig()) {
      return
    }
    if (this.hasUnresolvedMaterialConflict()) {
      throw new MaterialConflictError()
    }
    // update loaded config from params
    if (this.configuratorModeService.mode === ConfiguratorMode.FBS) {
      // update Oeffnungsart and DIN from params
      if (this.parameterService.model?.din) {
        await this.updateDinFromParams()
      }
    }
    // restore grundform
    await this.applyLoadedGrundform()
    if (this.configuratorModeService.mode === ConfiguratorMode.TTK) {
      this.configuratorConfigurationModel.configuratedDoor.Hausfronten = {
        Inside: (
          this.loadedConfig.Hausfronten.Inside?.Id
          && this.loadedConfig.Bauform
          && this.loadedConfig.Bauform === this.configuratorConfigurationModel.configuratedDoor.Grundform?.Key
        ) ? new Hausfront(
            this.grundformService.getHausfront(
              this.loadedConfig.Bauform,
              this.loadedConfig.Hausfronten.Inside.Id,
              SideType.Inside
            )
          )
          : new Hausfront(
            this.grundformService.getHausfrontFallback(
              this.loadedConfig.Bauform,
              SideType.Inside
            )
          ),
        Outside: (
          this.loadedConfig.Hausfronten.Outside?.Id
          && this.loadedConfig.Bauform
          && this.loadedConfig.Bauform
          === this.configuratorConfigurationModel.configuratedDoor.Grundform?.Key
        ) ? new Hausfront(
            this.grundformService.getHausfront(
              this.loadedConfig.Bauform,
              this.loadedConfig.Hausfronten.Outside.Id,
              SideType.Outside
            )
          )
          : new Hausfront(
            this.grundformService.getHausfrontFallback(
              this.loadedConfig.Bauform,
              SideType.Outside
            )
          )
      }
    }
    if (this.loadedConfig.Hausfronten.Inside
      && this.loadedConfig.Hausfronten.Inside.GrundformId === this.configuratorConfigurationModel.configuratedDoor.Grundform.Id
    ) {
      // TODO: ??
    }
    this.configuratorConfigurationModel.configuratedDoor.updateWidths()
    this.configuratorConfigurationModel.configuratedDoor.updateHeights()
    // restore strings
    this.configuratorConfigurationModel.configuratedDoor.notes = this.loadedConfig.Bemerkungen ?? ''
    this.configuratorConfigurationModel.configuratedDoor.Aktion = this.loadedConfig.Aktion
    this.configuratorConfigurationModel.configuratedDoor.Kommission = this.loadedConfig.Kommission
    // restore basic values
    this.configuratorConfigurationModel.configuratedDoor.Werksmontage = this.loadedConfig.Werksmontage
    this.configuratorConfigurationModel.configuratedDoor.Seeklimatauglich = this.loadedConfig.Seeklimatauglich
    // restore material
    const systemMaterial = isMaterial(this.parameterService.model?.material)
      ? this.parameterService.model?.material
      : this.loadedConfig.Material ?? Material.Alu
    this.configuratorConfigurationModel.configuratedDoor.profile.Material = systemMaterial // this.loadedConfig.Material ?? Material.Alu
    // restore components
    const componentTypeIndexes: { [K: string]: number } = {}
    let mainComponent: ConstructionComponent
    let mainComponentConfiguration: ConfigurationComponent
    const loadResult = this.loadedConfig.Components.map(
      (configuration: ConfigurationComponent, index: number): LoadingResult => {
        const component: ConstructionComponent = this.configuratorConfigurationModel.configuratedDoor.Components[index]
        if (
          typeof component !== 'undefined'
          && (component.objectType as string) === configuration.Typ
          // Do not check ModelId. When loading a TTK configuration in Expert, this is not the same
          // && component.model.Id === configuration.ModelId
        ) {
          if (typeof mainComponent === 'undefined' || component instanceof Tuer) {
            mainComponent = component
            mainComponentConfiguration = configuration
          }
          const componentTypeIndex = (componentTypeIndexes[component._objectType] ?? -1) + 1
          componentTypeIndexes[component._objectType] = componentTypeIndex
          component.IndexByType = componentTypeIndex
          component.Index = index
          return this.applyComponentConfig(component, configuration, systemMaterial)
        }
        this.logger.error('Error loading component: MISMATCH', {component, configuration})
        return LoadingResult.Fail
      }
    ).reduce(
      (acc: LoadingResult, curr: LoadingResult): LoadingResult =>
        acc === LoadingResult.Success && curr === LoadingResult.Success
          ? LoadingResult.Success
          : LoadingResult.PartialLoad,
      LoadingResult.Success
    )
    // TODO: what to skip on fail?
    if (loadResult === LoadingResult.PartialLoad) {
      return LoadingResult.PartialLoad
    }
    // TODO: Remove / refactore
    // restore konstruktion from main door
    // this.configuratorConfigurationModel.configuratedDoor.Konstruktion = mainComponent.konstruktion
    // TODO: load Blendrahmen / Color / ... from which model?
    // restore blendrahmen
    const blendrahmenInnen = this.loadedConfig.Blendrahmen.Inside[0]
    const blendrahmenAussen = this.loadedConfig.Blendrahmen.Outside[0]
    this.configuratorConfigurationModel.configuratedDoor.Blendrahmen = {
      Inside: mainComponent.model.findObjectFromData(blendrahmenInnen, insideFilter),
      Outside: mainComponent.model.findObjectFromData(blendrahmenAussen, outsideFilter)
    }
    // load blendrahmen from first element in following order:
    // doors
    // sidepanels
    // fanlights
    this.configuratorConfigurationModel.configuratedDoor.applyBlendrahmenColorFromConfig(this.loadedConfig.Blendrahmen)
    // restore packets
    this.configuratorConfigurationModel.configuratedDoor.schallschutzPaket =
      mainComponent.model.Pakete.find(
        (p: Paket): boolean => p.Id === mainComponentConfiguration.Schallschutz
      )
    this.configuratorConfigurationModel.configuratedDoor.sicherheitsPaket =
      mainComponent.model.Pakete.find(
        (p: Paket): boolean => p.Id === mainComponentConfiguration.Sicherheit
      )
    this.configuratorConfigurationModel.configuratedDoor.waermeschutzPaket =
      mainComponent.model.Pakete.find(
        (p: Paket): boolean => p.Id === mainComponentConfiguration.Waermeschutz
      )
    await this.configuratorConfigurationModel.updateDeckschichtStaerke()
    // update / correct packets from glasaufbau
    // TODO: set packet fitting glasses on non-main components
    // TODO: are packets or glasses the relevant leading main thing to adjust the other to
    mainComponent.glasaufbau.updateGlasaufbau(mainComponentConfiguration.Glasaufbau)
    this.removeLoadedConfig()
    return LoadingResult.Success
  }

  async applyLoadedGrundform(): Promise<void> {
    if (!this.hasLoadedConfig()) {
      return
    }
    // restore grundform
    const loadedGrundformKey = this.getLoadedGrundformKey()
    if (this.configuratorConfigurationModel.configuratedDoor.Grundform.Key !== loadedGrundformKey) {
      const grundform = this.grundformService.grundformen.find((g): boolean => g.Key === loadedGrundformKey)
      if (!grundform) {
        throw new Error(`Unknown loaded grundform with key '${loadedGrundformKey}'`)
      }
      // TODO: are these the right models?
      this.configuratorConfigurationModel.configuratedDoor.Components.forEach(c => void (c.model = null))
      this.configuratorConfigurationModel.grundform = grundform
      await firstValueFrom(this.configuratorConfigurationModel.modelsLoadedAfterGrundformChange)
    }
    await Promise.all([this.loadedConfig?.Hausfronten?.Inside, this.loadedConfig?.Hausfronten?.Outside]
      .filter((h): boolean => !!h && !!h.Custom)
      .map((h): Promise<void> => new Promise<void>((resolve, reject): void => {
        this.logger.trace('loadCustomHausfront', h)
        this.httpService.getCustomHausfront(h.Id).subscribe({
          next: (data: Hausfront): void => {
            this.grundformService.addCustomHausfront(this.configuratorConfigurationModel.configuratedDoor.Grundform.Id, new Hausfront(data))
            resolve()
          },
          error: (error): void => {
            this.logger.error(error)
            reject()
          }
        })
      }))
    )
  }

  dinChangeInteraction(component: ConfigurationComponent, paramsDin: Din): Promise<void> {
    return new Promise<void>((resolve): void => {
      this.modalService.showDinChangedModal()
        .afterClosed()
        .subscribe((result): void => {
          if (result) {
            if (component.Oeffnungsart === 'innen') {
              component.Din = paramsDin === Din.Left ? Din.Right : Din.Left
            } else {
              component.Din = paramsDin
            }
          }
          resolve()
        })
    })
  }

  private getConfig(tuerIdOrTranskey: string, pos?: string): Promise<void> {
    return new Promise<void>((resolve, reject): void => {
      this.httpService.getConfig(tuerIdOrTranskey, pos).subscribe({
        next: (data): void => {
          this.loadedConfig = new ConfiguratedElement(data)
          resolve()
        },
        error: (error): void => {
          if (error instanceof HttpErrorResponse && error.status === 404) {
            reject(new ConfigurationNotFoundError(error))
          }
          this.logger.error(error)
          this.loadedConfig = null
          reject(error)
        }
      })
    })
  }

  getConfigHausfronten(): InsideOutsideObject<Omit<ResponseHausfront, 'ElementX' | 'ElementY'> | null> {
    return this.loadedConfig?.Hausfronten
  }

  private getLastLoadedDoor(): ConfigurationComponent | undefined {
    const doors = this.loadedConfig?.Components.filter(
      (component): boolean => component.Typ === ConstructionComponentType.Door as string
    ) ?? []
    return doors.length > 0 ? doors[doors.length - 1] : undefined
  }

  getLoadedDoorFillingMaterial(): Material {
    const door = this.getLastLoadedDoor()
    return door
    && isMaterial(door.Material)
    && (
      !this.hasMaterialParameterChanged()
      || this.materialConflictResolutionStrategy !== MaterialConflictResolutionStrategy.MatchFillingToSystem
    )
      ? door.Material
      : this.getLoadedSystemMaterial()
  }

  getLoadedDoorModelId(): number | undefined {
    return this.getLastLoadedDoor()?.ModelId
  }

  public getLoadedGrundformKey(): string {
    if (this.hasLoadedConfig()) {
      return this.loadedConfig.Components.reduce(
        (acc, curr): string => acc + curr.Typ.slice(0, 1),
        ''
      ).toUpperCase()
    }
    return undefined
  }

  private getLoadedSystemMaterial(): Material {
    return isMaterial(this.parameterService.model?.material)
      ? this.parameterService.model?.material
      : this.loadedConfig.Material ?? Material.Alu
  }

  hasLoadedConfig(): boolean {
    return this.loadedConfig !== null
  }

  private hasMaterialParameterChanged(): boolean {
    return this.hasLoadedConfig()
      && typeof this.parameterService.model?.material !== 'undefined'
      && this.parameterService.model.material !== this.loadedConfig.Material
  }

  hasUnresolvedMaterialConflict(): boolean {
    return this.hasMaterialParameterChanged()
      && this.materialConflictResolutionStrategy === null
  }

  loadConfig(tuerId?: string): Promise<void> {
    if (this.configuratorModeService.mode === ConfiguratorMode.FBS) {
      if (
        this.parameterService.parameter.mode === ParameterModes.Open as ParameterMode
        || this.parameterService.parameter.mode === ParameterModes.Edit as ParameterMode
      ) {
        const transKey = this.parameterService.parameter.transkey
        const pos = this.parameterService.model?.pos
        if (typeof transKey === 'undefined' || typeof pos === 'undefined') {
          return Promise.reject('transkey and / or pos are / is missing!')
        }
        return this.getConfig(transKey, pos)
      }
    } else if (this.configuratorModeService.mode === ConfiguratorMode.TTK) {
      if (tuerId ?? this.parameterService.parameter.tuerId) {
        return this.getConfig(tuerId ?? this.parameterService.parameter.tuerId)
      }
    }
    return Promise.resolve()
  }

  loadConfigFromDoorId(doorId: string): Promise<void> {
    return this.getConfig(doorId)
  }

  private removeLoadedConfig(): void {
    this.loadedConfig = null
  }

  resolveMaterialConflict(materialConflictResolutionStrategy: MaterialConflictResolutionStrategy): void {
    this.materialConflictResolutionStrategy = materialConflictResolutionStrategy
  }

  retainLoadedComponentConfig(componentIndex: number): void {
    if (!this.hasLoadedConfig()) {
      return
    }
    const component = this.loadedConfig.Components[componentIndex]
    // TODO: Remove this fake of a grundform
    const grundform = this.grundformService.grundformen.find((g): boolean => g.Key === 'T')
    if (typeof component !== 'undefined' && typeof grundform !== 'undefined') {
      this.grundformService.grundformen.push(new Grundform({
        ...grundform,
        Key: component.Typ.slice(0, 1).toUpperCase()
      }))
    }
    this.loadedConfig.Components = [component]
  }

  async updateDinFromParams(): Promise<void> {
    const paramsDin: Din = this.parameterService.model.din === 'L' ? Din.Left : Din.Right
    const paramsOeffnungsart: Oeffnungsart = this.parameterService.model.tuertyp === 'HTA' ? 'aussen' : 'innen'
    const component = this.loadedConfig.Components[0]
    const oeffnungsartChanged: boolean = component.Oeffnungsart !== paramsOeffnungsart
    const dinChanged: boolean = this.loadedConfig.Din as Din !== paramsDin
    if (oeffnungsartChanged) {
      component.Din = component.Din === Din.Left ? Din.Right : Din.Left
    }
    let waitForDinChangedModal: boolean = false
    // check if Loaded Config System DIN differs to URL Param DIN
    if (dinChanged) {
      const insideDefaultDin: boolean = (component.Oeffnungsart === 'innen' && component.Din === this.loadedConfig.Din as Din)
      const outsideDefaultDin: boolean = (component.Oeffnungsart === 'aussen' && component.Din !== this.loadedConfig.Din as Din)
      // if it was the same (for innen) or different (for aussen) before, keep it that way
      if (insideDefaultDin) {
        component.Din = paramsDin
      } else if (outsideDefaultDin) {
        component.Din = paramsDin === Din.Left ? Din.Right : Din.Left
      } else {
        // otherwise ask what should be done
        waitForDinChangedModal = true
      }
    }
    if (dinChanged && waitForDinChangedModal) {
      await this.dinChangeInteraction(component, paramsDin)
    }
    this.loadedConfig.Din = paramsDin
  }
}

const insideFilter = <T extends { IsInnen: boolean }>(object: T): boolean => object.IsInnen
const outsideFilter = <T extends { IsAussen: boolean }>(object: T): boolean => object.IsAussen
