import {Component, ComponentRef, Injector, ViewContainerRef} from '@angular/core'
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, ResolveEnd, Router} from '@angular/router'
import {NGXLogger} from 'ngx-logger'
import {LoadingIndicatorComponent} from './loading-indicator/loading-indicator.component'
import {SettingsService} from './classes/service/settings/settings.service'
import {Title} from '@angular/platform-browser'
import {TranslateService, TranslationKeys} from './translate'
import {ConfiguratorComponent} from './configurator/configurator.component'

@Component({
  selector: 'app',
  templateUrl: './app.component.html'
})

export class AppComponent {
  private loadingIndicatorRef: ComponentRef<LoadingIndicatorComponent> | undefined
  constructor(
    private readonly router: Router,
    private readonly logger: NGXLogger,
    private readonly view: ViewContainerRef,
    private readonly titleService: Title,
    private readonly injector: Injector,
  ) {
    this.registerLoadingIndicator()
    this.registerTitleUpdateHandler()
  }

  private registerLoadingIndicator(): void {
    const subscription = this.router.events
      .subscribe((event): void => {
        if (event instanceof NavigationStart) {
          this.logger.trace(
            'Navigation event subscriber in AppComponent invoked for start of navigation',
            {event}
          )
          this.loadingIndicatorRef?.destroy()
          this.loadingIndicatorRef = this.view.createComponent(LoadingIndicatorComponent)
        } else if (event instanceof ResolveEnd) {
          // Reload loading indicator for updated api translations
          this.loadingIndicatorRef?.destroy()
          this.loadingIndicatorRef = this.view.createComponent(LoadingIndicatorComponent)
          subscription.unsubscribe()
        } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
          subscription.unsubscribe()
        }
      })
  }

  private registerTitleUpdateHandler(): void {
    const subscription = this.router.events
      .subscribe((event): void => {
        if (event instanceof ResolveEnd) {
          const settingsService = this.injector.get(SettingsService)
          const translateService = this.injector.get(TranslateService)
          this.titleService.setTitle(
            (settingsService.settings.Name ? settingsService.settings.Name : 'Rodenberg')
            + ' | ' + translateService.translate(TranslationKeys.APP_TITLE)
          )
          subscription.unsubscribe()
        } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
          subscription.unsubscribe()
        }
      })
  }

  protected routerOutletActivate(component: unknown): void {
    if (component instanceof ConfiguratorComponent) {
      component.ngInit.subscribe((): void => {
        this.loadingIndicatorRef?.destroy()
        this.loadingIndicatorRef = undefined
      })
    } else {
      this.loadingIndicatorRef?.destroy()
      this.loadingIndicatorRef = undefined
    }
  }
}


