import {EventEmitter, Injectable} from '@angular/core'
import {TranslateService} from '../../translate'
import {NGXLogger} from 'ngx-logger'
import {ConfiguratorComponent} from '../../configurator/configurator.component'
import {ConfiguratorModeService} from './configuratorMode.service'
import {ConfiguratorMode} from '../../types'
import {ComponentModel} from '../model/component/model/component-model'
import {ComponentSelectionService} from './componentSelectionService'

const TRANSLATION_KEY = {
  MENU_ENTRY_LOAD: 'MenuEntry.Load',
  MENU_ENTRY_BASIS: 'MenuEntry.Basis',
  MENU_ENTRY_KATALOG: 'MenuEntry.Katalog',
  MENU_ENTRY_MODELL: 'MenuEntry.Modell',
  MENU_ENTRY_MASSE_FBS: 'MenuEntry.Masse.FBS',
  MENU_ENTRY_MASSE_TTK: 'MenuEntry.Masse.TTK',
  MENU_ENTRY_GLAESER: 'MenuEntry.Glaeser',
  MENU_ENTRY_FARBEN: 'MenuEntry.Farben',
  MENU_ENTRY_OPTIONEN: 'MenuEntry.Optionen',
  MENU_ENTRY_GRUNDFORM: 'MenuEntry.Grundform',
  MENU_ENTRY_HAUSFRONT: 'MenuEntry.Hausfront',
  MENU_ENTRY_MEHRPREISE_FBS: 'MenuEntry.Mehrpreise.FBS',
  MENU_ENTRY_MEHRPREISE_TTK: 'MenuEntry.Mehrpreise.TTK',
  MENU_ENTRY_FERTIG: 'MenuEntry.Fertig',
  MENU_ENTRY_CHARACTERISTICS: 'MenuEntry.Characteristics',
  MENU_ENTRY_PREISBERECHNUNG: 'MenuEntry.Calculation',
  MENU_NAVIGATION_BUTTON_NEXT: 'MenuNavigation.Button.Next'

} as const

export type MenuClosed = ''
type NavigationEntry = {
  canActivate?: ((model: ComponentModel) => boolean)[]
  hideNext?: boolean
  icon: string
  nextFunc?: {
    arguments: string[]
    func: string
  }
  nextString?: string
  title: string
}
export type NavigationKey =
  'Load'
  | 'Basis'
  | 'Katalog'
  | 'Modell'
  | 'Masse'
  | 'Glaeser'
  | 'Farben'
  | 'Optionen'
  | 'Grundform'
  | 'Hausfront'
  | 'Mehrpreise'
  | 'Fertig'
  | 'Characteristics'

@Injectable()
export class NavigatorService {
  private _configurator: ConfiguratorComponent
  _menuEntries: NavigationKey[]
  private _navigationEntries: Record<NavigationKey, NavigationEntry> = {
    Load: {
      icon: 'fa fa-spinner fa-2x',
      title: `${this._translate.translate(TRANSLATION_KEY.MENU_ENTRY_LOAD )}`,
    },
    Basis: {
      icon: 'fa fa-cogs fa-2x',
      title: this._translate.translate(TRANSLATION_KEY.MENU_ENTRY_BASIS),
      canActivate: [doesModelExist]
    },
    Katalog: {
      icon: 'icon-catalog',
      title: this._translate.translate(TRANSLATION_KEY.MENU_ENTRY_KATALOG)
    },
    Modell: {
      icon: 'icon-model',
      title: this._translate.translate(TRANSLATION_KEY.MENU_ENTRY_MODELL)
    },
    Masse: {
      icon: this._configuratorModeService?.mode === ConfiguratorMode.FBS
        ? 'icon-basic-form'
        : 'fa fa-pencil fa-2x',
      title: this._configuratorModeService?.mode === ConfiguratorMode.FBS
        ? this._translate.translate(TRANSLATION_KEY.MENU_ENTRY_MASSE_FBS)
        : this._translate.translate(TRANSLATION_KEY.MENU_ENTRY_MASSE_TTK)
    },
    Glaeser: {
      icon: 'icon-glas',
      title: this._translate.translate(TRANSLATION_KEY.MENU_ENTRY_GLAESER),
      canActivate: [doesModelExist, isGlassPossible]
    },
    Farben: {
      icon: 'icon-color',
      title: this._translate.translate(TRANSLATION_KEY.MENU_ENTRY_FARBEN),
      canActivate: [doesModelExist, areColorsAvailable]
    },
    Optionen: {
      icon: 'icon-options',
      title: this._translate.translate(TRANSLATION_KEY.MENU_ENTRY_OPTIONEN),
      canActivate: [doesModelExist, areOptionsAvailable]
    },
    Grundform: {
      icon: 'icon-basic-form',
      title: this._translate.translate(TRANSLATION_KEY.MENU_ENTRY_GRUNDFORM)
    },
    Hausfront: {
      icon: 'icon-facade',
      title: this._translate.translate(TRANSLATION_KEY.MENU_ENTRY_HAUSFRONT)
    },
    Mehrpreise: {
      icon: 'icon-handle',
      title: this._configuratorModeService?.mode === ConfiguratorMode.FBS
        ? this._translate.translate(TRANSLATION_KEY.MENU_ENTRY_MEHRPREISE_FBS)
        : this._translate.translate(TRANSLATION_KEY.MENU_ENTRY_MEHRPREISE_TTK),
      nextString: this._translate.translate(TRANSLATION_KEY.MENU_ENTRY_PREISBERECHNUNG),
      canActivate: [doesModelExist],
      nextFunc: {
        func: 'setShowNextToggle',
        arguments: ['calculateFbs']
      },
    },
    Fertig: {
      icon: 'fa fa-check-square-o',
      title: this._translate.translate(TRANSLATION_KEY.MENU_ENTRY_FERTIG),
      hideNext: true
    },
    Characteristics: {
      icon: 'fa fa-list-ul',
      title: this._translate.translate(TRANSLATION_KEY.MENU_ENTRY_CHARACTERISTICS),
    }}
  private _onShowToggleChange: EventEmitter<NavigationKey | MenuClosed> = new EventEmitter<NavigationKey | MenuClosed>()
  private _showToggle: NavigationKey | MenuClosed
  mobileNav: boolean = false

  // readonly navigationItems = Object.getOwnPropertyNames(this.navigationEntries) as (keyof typeof this.navigationEntries)[]
  constructor(
    private _translate: TranslateService,
    private _configuratorModeService: ConfiguratorModeService,
    private componentSelection: ComponentSelectionService,
    private _logger: NGXLogger
  ) {
    if (this._configuratorModeService?.mode === ConfiguratorMode.FBS) {
      this._menuEntries = [
        'Katalog',
        'Modell',
        'Basis',
        'Optionen',
        'Glaeser',
        'Farben',
        'Mehrpreise'
      ]
    } else if (this._configuratorModeService?.mode === ConfiguratorMode.TTK) {
      this._menuEntries = [
        'Load',
        'Grundform',
        'Masse',
        'Hausfront',
        'Katalog',
        'Modell',
        'Optionen',
        'Glaeser',
        'Farben',
        'Mehrpreise',
        'Fertig'
      ]

      // Remove next function of Mehrpreise because Fertig is the next item in TTK
      delete this._navigationEntries.Mehrpreise.nextFunc
      delete this._navigationEntries.Mehrpreise.nextString
    }
  }

  activateNav(navToggle: NavigationKey | MenuClosed): boolean {
    return navToggle === MenuClosed || (this.navigationEntries?.[navToggle]?.canActivate || [])
      .reduce(
        (activate: boolean, canActivate: (model: ComponentModel) => boolean): boolean =>
          activate && canActivate(this.componentSelection.selectedComponent?.model),
        true
      )
  }

  getNextEntry(showToggleString: NavigationKey | MenuClosed): NavigationKey | MenuClosed {
    if (showToggleString === MenuClosed) {
      return MenuClosed
    }
    const currentIndex = this._menuEntries.indexOf(showToggleString)
    if (currentIndex === this._menuEntries.length - 1) {
      return this._menuEntries[currentIndex]
    }
    for (let nearestNextIndex = currentIndex + 1; nearestNextIndex < this._menuEntries.length; nearestNextIndex++) {
      if (this.activateNav(this._menuEntries[nearestNextIndex])) {
        return this._menuEntries[nearestNextIndex]
      }
    }
    return this._menuEntries[currentIndex]
  }

  // calls a function by its name
  getNextFunc(): void {
    const menuNextFunc: { arguments: string[]; func: string } = (this.navigationEntries[this._showToggle] as NavigationEntry)?.nextFunc
    if (menuNextFunc) {
      const func = this._configurator?.[menuNextFunc.func] as () => void
      func.call(this._configurator, ...menuNextFunc.arguments)
    } else {
      this.goToNext()
    }
  }

  getNextString(): string {
    return (this.navigationEntries[this._showToggle] as NavigationEntry)?.nextString
      ?? this._translate.translate(TRANSLATION_KEY.MENU_NAVIGATION_BUTTON_NEXT)
  }

  getPrevEntry(showToggleString: NavigationKey | MenuClosed): NavigationKey | MenuClosed {
    if (showToggleString === MenuClosed) {
      return MenuClosed
    }
    const currentIndex = this._menuEntries.indexOf(showToggleString)
    if (currentIndex === 0) {
      return this._menuEntries[currentIndex]
    }
    for (let nearestPreviousIndex = currentIndex - 1; nearestPreviousIndex >= 0; --nearestPreviousIndex) {
      if (this.activateNav(this._menuEntries[nearestPreviousIndex])) {
        return this._menuEntries[nearestPreviousIndex]
      }
    }
    return this._menuEntries[currentIndex]
  }

  goToFirst(): void {
    if (!this.isFirstNavEntryActive()) {
      void this.setShowToggle(this._menuEntries[0])
    }
  }

  goToNext(): boolean {
    const currentIndex = this._menuEntries.indexOf(this._showToggle as NavigationKey)
    // return if already last menu entry
    if (currentIndex >= (this._menuEntries.length - 1)) {
      return false
    }
    void this.setShowToggle(this.getNextEntry(this._showToggle), 'next')
  }

  goToPrev(): boolean {
    const currentIndex = this._menuEntries.indexOf(this._showToggle as NavigationKey)
    // return if already first menu entry
    if (currentIndex <= 0) {
      return false
    }
    void this.setShowToggle(this.getPrevEntry(this._showToggle), 'prev')
  }

  isFirstNavEntryActive(): boolean {
    return this._menuEntries.indexOf(this._showToggle as NavigationKey) === 0
  }

  setShowToggle(show: NavigationKey | '', skipTo?: 'prev' | 'next'): void {
    this._logger.trace('setShowToggle: ' + show)
    this._logger.trace('currentShowToggle: ' + this._showToggle)
    if (show === '' || show === this._showToggle) {
      this._showToggle = ''
      return
    }
    if (!this.activateNav(show)) {
      if (skipTo && skipTo === 'next') {
        show = this.getNextEntry(show)
      } else if (skipTo && skipTo === 'prev') {
        show = this.getPrevEntry(show)
      } else {
        // go to first entry if no direction to skip to is given
        show = this._menuEntries[0]
      }
    }
    this._showToggle = show
    this._onShowToggleChange.emit(show)
  }

  toggleNav(): void {
    this.mobileNav = !this.mobileNav
  }

  updateNavSquareNavigation(): void {
    // append navigation squares in mobile
    const menuEntries = document.querySelectorAll(
      '#mainNavigation ul li[data-showtoggle]'
    )
    const numOfSquares = document
      .querySelector('.navSquares')
      .querySelectorAll('.navSquare').length
    const numOfCurrentSquares = document.querySelectorAll(
      '.subNavigationContainer.active .navSquare'
    ).length
    if (menuEntries.length !== numOfSquares || numOfCurrentSquares === 0) {
      Array.from(document.querySelectorAll('.navSquare')).forEach((e): Element =>
        e.parentNode.removeChild(e)
      )
      if (menuEntries) {
        const navSquareWrappers = Array.from(
          document.querySelectorAll('.navSquares')
        )
        const widthHeight = (window.innerWidth * 0.3) / menuEntries.length
        navSquareWrappers.forEach((w): void => {
          for (const menuEntry of Array.from(menuEntries) as HTMLElement[]) {
            let active = ''
            if (menuEntry.dataset.showtoggle === this.showToggle) {
              active = ' active'
            }
            w.insertAdjacentHTML(
              'beforeend',
              '<div class="navSquare' +
              active +
              '" data-showtoggle="' +
              menuEntry.dataset.showtoggle +
              '"  style="width:' +
              widthHeight +
              'px;height:' +
              widthHeight +
              'px"></div>'
            )
          }
        })
      }
      this.updateNavSquares(this.showToggle)
    }
  }

  updateNavSquares(show: string): boolean {
    const navSquares = document.querySelectorAll('.navSquare')
    for (const navSquare of Array.from(navSquares)) {
      if (navSquare instanceof HTMLElement) {
        if (navSquare.dataset.showtoggle === show) {
          navSquare.classList.add('active')
        } else {
          navSquare.classList.remove('active')
        }
      }
    }
    return true
  }

  get menuEntries(): NavigationKey[] {
    return this._menuEntries
  }

  get navigationEntries(): typeof this._navigationEntries {
    return this._navigationEntries
  }

  get showToggle(): typeof this._showToggle {
    return this._showToggle
  }

  get onShowToggleChange(): EventEmitter<string> {
    return this._onShowToggleChange
  }

  set configurator(value: ConfiguratorComponent) {
    this._configurator = value
  }
}

const doesModelExist = (model: ComponentModel | undefined): model is ComponentModel => typeof model === 'object' && model !== null
const areColorsAvailable = (model: ComponentModel): boolean =>
  model?.Farben.length > 0 || model?.Folien.length > 0 || model?.Pulver.length > 0
const isGlassPossible = (model: ComponentModel): boolean => model?.isGlassPossible()
const areOptionsAvailable = (model: ComponentModel): boolean => model?.hasOptions()
const MenuClosed: MenuClosed = ''
