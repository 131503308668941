<configurator-base-menu
        has-component-selector
        [isMobile]="isMobile"
        [menuHead]="head"
        menuNavigatorIdentifier="Modell"
>
    <div class="container">
        <div class="container-inner modelMenu ">
            <div class="inputArea">
                <ng-template [ngTemplateOutlet]="modelSearchForm"></ng-template>
            </div>
            <div class="contentArea virtualscroll">
                <ng-template [ngTemplateOutlet]="modelList"
                             [ngTemplateOutletContext]="{
                                 selectedModelId: this.selectedComponent?.model?.Id || null,
                                 selectedElement: this.selectedComponent
                             }"
                >
                </ng-template>
            </div>


        </div>
    </div>
</configurator-base-menu>
<ng-template #modelSearchForm>
    <mat-form-field>
        <mat-label>{{ TRANSLATION_KEY.DROPDOWN_KATALOG_PLACEHOLDER | translate }}</mat-label>
        <mat-select [(ngModel)]="this.selectedCatalogue">
            <mat-option [value]="-1">
                {{ TRANSLATION_KEY.DROPDOWN_KATALOG_ALL_OPTION | translate }}
            </mat-option>
            <mat-option *ngFor="let catalog of this.catalogs" [value]="catalog.KatalogId">
                {{ catalog.Bezeichnung | translate }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>{{ TRANSLATION_KEY.INPUT_MODEL_SEARCH_PLACEHOLDER | translate }}</mat-label>
        <input #modelSearchInput type="text" matInput [(ngModel)]="modelTerm" (input)="modelSearchTermChange.emit(modelTerm)">
        <button
                (click)="modelTerm = ''"
                matSuffix
                mat-icon-button
                [attr.aria-label]="TRANSLATION_KEY.INPUT_MODEL_SEARCH_CLEAR | translate"
                [title]="TRANSLATION_KEY.INPUT_MODEL_SEARCH_CLEAR | translate"
        >
            <i (click)="modelTerm = ''" class="fa fa-times"></i>
        </button>
    </mat-form-field>
</ng-template>
<ng-template #modelList let-selectedModelId="selectedModelId" let-selectedElement="selectedElement">
    <cdk-virtual-scroll-viewport #modelViewPort class="viewport" [itemSize]="isMobile ? 300 : 360">
        <ng-container *cdkVirtualFor="let row of filteredModels; templateCacheSize: 3000">
            <ng-container *ngFor="let model of row; let index = index">

                <article [attr.data.index]="index" class="modell"
                         (click)="model.disabilityReasons.length === 0 ? modelSelected(model) : ''"
                         [class.active]="model.Id === selectedModelId">
                    <div [class.disabled]="model.disabilityReasons.length > 0"
                         [mtxTooltip]="StringUtil.concatStringArray(model.disabilityReasons)"
                         [mtxTooltipDisabled]="model.disabilityReasons.length === 0" class="image-wrapper">
                        <img *loadingAnimation="model.PreviewImageUrl, sizing: 'fill', background: true"
                             [attr.data.id]="model.Id" [class.flipped]="selectedElement.dinfuellung === 1">
                    </div>
                    <p>{{ model.Bezeichnung | translate: [true] }} {{ model.Katalog  | translate  }}</p>
                </article>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="filteredModels.length === 0">
            <article class="modell noResults"
                     [class.active]="false">
                <div [class.disabled]="false"
                     [mtxTooltip]=""
                     [mtxTooltipDisabled]="true">
                </div>
                <span>{{ this.noModelsFoundText() }}</span>
                <button mat-flat-button *ngIf="this.selectedCatalogue > -1" (click)="this.selectedCatalogue = -1">
                    {{ TRANSLATION_KEY.BUTTON_KATALOG_SEARCH_ALL | translate}}
                </button>
            </article>
        </ng-container>
    </cdk-virtual-scroll-viewport>
</ng-template>

<ng-template #head>
    <div class="name">
        <span>{{ TRANSLATION_KEY.MODEL | translate }}</span>
        <span class="close" (click)="navigatorService.setShowToggle('')"></span>
    </div>
</ng-template>
