import {Massblatt} from '../massblatt'
import {Dienstleistung} from '../mehrpreis/dienstleistung'

import {ResponseMassblaetter} from '../../../../api/massblaetter/response-massblaetter.interface'

export class ZubehoerAddonEntry {
  Id: number
  Massblatt?: Massblatt
  Typ: string

  constructor(data: Omit<ZubehoerAddonEntry, 'updateMassblatt'>) {
    this.Id = data.Id
    this.Typ = data.Typ
    if (typeof data.Massblatt !== 'undefined') {
      this.Massblatt = new Massblatt(data.Massblatt)
    }
  }

  static fromDienstleistung(dienstleistung: Dienstleistung): ZubehoerAddonEntry {
    return new ZubehoerAddonEntry({
      Id: dienstleistung.Id,
      Typ: dienstleistung.Typ,
      ...(dienstleistung.Massblaetter.length ? {Massblatt: dienstleistung.Massblaetter[0]} : {})
    })
  }

  updateMassblatt(massblaetterUpdate: ResponseMassblaetter): void {
    if (this.Massblatt) {
      massblaetterUpdate.Massblaetter
        ?.filter((massblattUpdate): boolean => massblattUpdate.Id === this.Massblatt.Id)
        .forEach(massblattUpdate => void this.Massblatt.updateValues(massblattUpdate.Values ?? {}))
    }
  }
}
