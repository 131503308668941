<configurator-base-menu
  [isMobile]='isMobile'
  [menuHead]='head'
  menuClass='katalog'
  menuNavigatorIdentifier='Katalog'
>
  <div class='container'>
    <div #scrollCatalogContainer class='container-inner max'>
      <article
        *ngFor='let catalog of catalogueService.catalogues' [class.active]='catalog.KatalogId === selectedCatalogue'
        class='catalog'
      >
        <div class='image-wrapper'>
          <div *ngIf='!catalog.loaded' class='icon loading-image-spinner'>
            <i class='fa fa-spinner fa-pulse fa-3x fa-fw'></i>
          </div>
          <img
            (click)='onSelectCatalog(catalog)'
            (load)='lazyImageLoaded(catalog)'
            [alt]='catalog.Bezeichnung'
            [lazyLoad]='catalog.PreviewImageUrl'
            [offset]='0'
            [scrollTarget]='scrollCatalogContainer'
          >
        </div>
        <p>{{ catalog.Bezeichnung | translate }}</p>
      </article>
    </div>
  </div>
</configurator-base-menu>
<ng-template #head>
  <div class='name'>
    <span>{{ TRANSLATION_KEY.TITLE | translate }}</span>
    <span (click)="navigatorService.setShowToggle('')" class='close'></span>
  </div>
</ng-template>
