


export class BaseMenuConfigEntry {
  readonly Aenderbar: boolean
  readonly Sichtbar: boolean

  constructor(data?: BaseMenuConfigEntry) {
    this.Aenderbar = data?.Aenderbar ?? false
    this.Sichtbar = data?.Sichtbar ?? false
  }
}

export class BaseMenuConfig {
  readonly DinRichtungTuerfuellung: BaseMenuConfigEntry
  readonly DinRichtungTuersystem: BaseMenuConfigEntry
  readonly Hoehenverteilung: BaseMenuConfigEntry
  readonly Klebeset: BaseMenuConfigEntry
  readonly Klebesystem: BaseMenuConfigEntry
  readonly Kommission: BaseMenuConfigEntry
  readonly Konstruktion: BaseMenuConfigEntry
  readonly KonstruktionsVariante: BaseMenuConfigEntry
  readonly MasseTuerfuellung: BaseMenuConfigEntry
  readonly MaterialTuerfuellung: BaseMenuConfigEntry
  readonly MaterialTuersystem: BaseMenuConfigEntry
  readonly Oeffnungsart: BaseMenuConfigEntry
  readonly Pakete: BaseMenuConfigEntry
  readonly Querfries: BaseMenuConfigEntry
  readonly Seeklimatauglich: BaseMenuConfigEntry
  readonly StaerkeTuerfuellung: BaseMenuConfigEntry
  readonly Werksmontage: BaseMenuConfigEntry

  constructor( data?: BaseMenuConfig) {
    this.DinRichtungTuerfuellung = data?.DinRichtungTuerfuellung ? new BaseMenuConfigEntry(data.DinRichtungTuerfuellung) : null
    this.DinRichtungTuersystem = data?.DinRichtungTuersystem ? new BaseMenuConfigEntry(data.DinRichtungTuersystem) : null
    this.Hoehenverteilung = data?.Hoehenverteilung ? new BaseMenuConfigEntry(data.Hoehenverteilung) : null
    this.Klebesystem = data?.Klebesystem ? new BaseMenuConfigEntry(data.Klebesystem) : null
    this.Kommission = data?.Kommission ? new BaseMenuConfigEntry(data.Kommission) : null
    this.Konstruktion = data?.Konstruktion ? new BaseMenuConfigEntry(data.Konstruktion) : null
    this.Klebeset = data?.Klebeset ? new BaseMenuConfigEntry(data.Klebeset) : null
    this.KonstruktionsVariante = data?.KonstruktionsVariante ? new BaseMenuConfigEntry(data.KonstruktionsVariante) : null
    this.MasseTuerfuellung = data?.MasseTuerfuellung ? new BaseMenuConfigEntry(data.MasseTuerfuellung) : null
    this.MaterialTuerfuellung = data?.MaterialTuerfuellung ? new BaseMenuConfigEntry(data.MaterialTuerfuellung) : null
    this.MaterialTuersystem = data?.MaterialTuersystem ? new BaseMenuConfigEntry(data.MaterialTuersystem) : null
    this.Oeffnungsart = data?.Oeffnungsart ? new BaseMenuConfigEntry(data.Oeffnungsart) : null
    this.Pakete = data?.Pakete ? new BaseMenuConfigEntry(data.Pakete) : null
    this.Querfries = data?.Querfries ? new BaseMenuConfigEntry(data.Querfries) : null
    this.Seeklimatauglich = data?.Seeklimatauglich ? new BaseMenuConfigEntry(data.Seeklimatauglich) : null
    this.StaerkeTuerfuellung = data?.StaerkeTuerfuellung ? new BaseMenuConfigEntry(data.StaerkeTuerfuellung) : null
    this.Werksmontage = data?.Werksmontage ? new BaseMenuConfigEntry(data.Werksmontage) : null
  }
}

export class MenuConfig {
  readonly Basis: BaseMenuConfig

  constructor(data?: MenuConfig) {
    this.Basis = data?.Basis ? new BaseMenuConfig(data.Basis) : null
  }
}
