import {Component, EventEmitter, Input, Output} from '@angular/core'
import {NavigatorService} from '../../../classes/service/navigator.service'
import {HausfrontService} from '../../../classes/service/hausfront.service'
import {Hausfront} from '../../../classes/model/component/hausfront/hausfront'
import {ConfiguratorConfigurationModel} from '../../../classes/model/configuratorConfigurationModel'
import {InsideOutsideObject, SideType} from '../../../types'
import {HausfrontDoorPosition} from '../../../class'
import {ModalService} from '../../modal/modal.service'
import {Grundform, GrundformTupel} from '../../../classes/model/component/other/grundform'
import {ViewService} from '../../../classes/service/view.service'
import {GrundformService} from '../../../classes/service/grundform.service'
import {ConfiguratedDoor} from '../../../classes/model/configuratedDoor'

const TRANSLATION_KEY = {
  TITLE: 'HausfrontMenuComponent.Title',
  USE_CUSTOM_MILIEU: 'HausfrontMenuComponent.UseCustomMilieu'
}

@Component({
  selector: 'configurator-hausfront-menu',
  templateUrl: './hausfront-menu.component.html',
  styleUrls: ['./hausfront-menu.component.scss']
})
export class HausfrontMenuComponent {
  #doorPosition: HausfrontDoorPosition
  protected readonly SideType = SideType
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  @Output() readonly doorPositionChange: EventEmitter<HausfrontDoorPosition>
  @Input() isMobile: boolean
  @Input() lazyImageLoaded: (object: unknown) => void
  loadingBackground: boolean
  @Output() readonly renderRequest: EventEmitter<void>
  uploadImagePath: string

  constructor(
    private _navigatorService: NavigatorService,
    private _modalService: ModalService,
    private _hausfrontService: HausfrontService,
    private _viewService: ViewService,
    private _configuratorConfigurationModel: ConfiguratorConfigurationModel,
    private grundformService: GrundformService
  ) {
    this.renderRequest = new EventEmitter<void>()
    this.doorPositionChange = new EventEmitter<HausfrontDoorPosition>()
    this.loadingBackground = false
    this._configuratorConfigurationModel.grundformChange.subscribe((g) => void this.onGrundformChange(g))
  }

  applyAndSet(grundformen: GrundformTupel): void {
    this.hausfrontService.applyHausfrontenFromGrundform(
      this.grundformService.grundformen.find((g: Grundform): boolean => g.Id === grundformen.Current.Id)
    )
    if (this.currentHausfronten) {
      [SideType.Inside, SideType.Outside].filter((side): boolean => (this.currentHausfronten[side] instanceof Hausfront))
        .forEach(side =>
          void this.setHausfront(
            this.hausfrontService.findHausfront(side, this.currentHausfronten[side]?.Bezeichnung)
            ?? this.hausfrontService.findHausfront(side)
            ?? new Hausfront()
            , side
          )
        )
      this._configuratorConfigurationModel.configuratedDoor.updateWidths()
      this._configuratorConfigurationModel.configuratedDoor.updateHeights()
    }
  }

  checkHausfrontReset(hausfront: Hausfront | null, side: SideType): void {
    // ohne Abfrage wenn bestehende maße identisch sind mit der gewählten hausfront, oder man von einer standard
    // hausfront zu einer standard hausfront wechselt
    const massePassen: boolean = this.configuratedDoor.measuresMatch(hausfront)
    const bestehendeHausfrontIsDefault: boolean =
      this._configuratorConfigurationModel.configuratedDoor.Hausfronten[side]
      && this._configuratorConfigurationModel.configuratedDoor.Hausfronten[side].Default
    if (massePassen || (bestehendeHausfrontIsDefault && hausfront.Default)) {
      this.onSelectDefaultHausfront(hausfront)
      this.setHausfront(hausfront, side)
      this.emitRenderRequest()
    } else {
      this._modalService.showChooseDefaultHausfrontModal()
        .afterClosed()
        .subscribe((result): void => {
          if (!result) {
            this.onSelectDefaultHausfront(hausfront)
            this.setHausfront(hausfront, side)
            this.emitRenderRequest()
          }
        })
    }
  }

  deleteCustomHausfront(hausfront: Hausfront): void {
    // this.customHausfronten.splice(this.customHausfronten.indexOf(hausfront), 1)
    const hausfrontenInGrundform: Hausfront[] = this.grundformService.grundformen.filter(
      (g: Grundform): boolean => g.Id === hausfront.GrundformId
    )[0].Hausfronten
    hausfrontenInGrundform.splice(hausfrontenInGrundform.indexOf(hausfront), 1)
    this.setHausfront(hausfrontenInGrundform.filter((h: Hausfront): boolean => h.IsAussen === hausfront.IsAussen)[0], this.view)
  }

  doorOffset(): number {
    const contentWrapperWidth = document.getElementById('contentWrapper')?.offsetWidth || 0
    const backgroundImageWidth = document.getElementById('image_background')?.offsetWidth || 0
    return (contentWrapperWidth - backgroundImageWidth) / 2
  }

  editCustomHausfront(hausfront: Hausfront): void {
    this._modalService.showHausfrontModal(hausfront)
      .afterClosed()
      .subscribe((hf: Hausfront): void => {
        if (hf) {
          this.updateHausfront(hf, this.view)
          this.emitRenderRequest()
        }
      })
  }

  emitRenderRequest(): void {
    this.renderRequest.emit()
  }

  newHausfront(): void {
    this.uploadImagePath = null
    this._modalService.showHausfrontModal()
      .afterClosed()
      .subscribe((hf: Hausfront | false): void => {
        if (hf) {
          if (this.view === SideType.Inside) {
            hf.IsInnen = true
            hf.IsAussen = false
          } else {
            hf.IsInnen = false
            hf.IsAussen = true
          }
          // push grundform into service
          this._hausfrontService.applyHausfrontenFromGrundform(this.grundformService.grundformen
            .filter((g: Grundform): boolean => g.Id === this._configuratorConfigurationModel.configuratedDoor.Grundform.Id)[0])
          // update grundform in configuratedDoor so the new hausfront is visible in the menu
          this._configuratorConfigurationModel.configuratedDoor.Hausfronten[this.view] = new Hausfront(hf)
          this._configuratorConfigurationModel.configuratedDoor.Grundform = this.grundformService.grundformen
            .filter((g: Grundform): boolean => g.Id === this._configuratorConfigurationModel.configuratedDoor.Grundform.Id)[0]
          this.setHausfront(hf, this.view)
          this.emitRenderRequest()
        }
      })
  }

  onBackgroundLoaded(): void {
    this.loadingBackground = false
    this.updateDoorPosition()
  }

  onGrundformChange(grundformen: GrundformTupel): void {
    if (!grundformen.Current) {
      return
    }
    const currentHausfront: Hausfront = this._configuratorConfigurationModel.configuratedDoor.Hausfronten[this.view]
    if (currentHausfront?.Custom) {
      // currentHausfront.GrundformId = grundformen.Current.Id
      const hausfrontFromGrundform: Hausfront = grundformen.Current.Hausfronten.find(
        (h: Hausfront): boolean => h.Id === currentHausfront.Id
      )
      hausfrontFromGrundform.GrundformId = grundformen.Current.Id
      this.grundformService.updateCustomHausfront(
        hausfrontFromGrundform,
        this._configuratorConfigurationModel.configuratedDoor.width, this._configuratorConfigurationModel.configuratedDoor.height
      ).then((): void => {
        this.applyAndSet(grundformen)
      }).catch((error): void => {
        console.error(error)
      })
    } else {
      this.applyAndSet(grundformen)
    }
  }

  onSelectDefaultHausfront(hausfront: Hausfront): void {
    this._configuratorConfigurationModel.configuratedDoor.setDefaultSizes(hausfront)
  }

  setDoorPosition(): void {
    const doorPosition = new HausfrontDoorPosition()
    const contentWrapper: HTMLImageElement = document.getElementById('image_background') as HTMLImageElement
    if (!contentWrapper) {
      return
    }
    const elementBreite = this.currentHausfronten[this.view].ElementBreite
    const elementHoehe = this.currentHausfronten[this.view].ElementHoehe
    const scaleFactor = contentWrapper.offsetHeight / contentWrapper.naturalHeight
    const minWidth = scaleFactor * contentWrapper.naturalWidth
    const shiftLeft = (minWidth - contentWrapper.offsetWidth) * 0.5
    doorPosition.x0 = this.currentHausfronten[this.view].X1
    doorPosition.y0 = this.currentHausfronten[this.view].Y1
    doorPosition.x1 = this.currentHausfronten[this.view].X2
    doorPosition.y1 = this.currentHausfronten[this.view].Y2
    doorPosition.x2 = this.currentHausfronten[this.view].X3
    doorPosition.y2 = this.currentHausfronten[this.view].Y3
    doorPosition.x3 = this.currentHausfronten[this.view].X4
    doorPosition.y3 = this.currentHausfronten[this.view].Y4
    // const faktorBreite = tuerBreite / bildBreite
    doorPosition.backgroundWidth = contentWrapper.offsetWidth
    doorPosition.backgroundHeight = contentWrapper.offsetHeight
    doorPosition.doorHeight = scaleFactor * elementHoehe
    doorPosition.doorWidth = scaleFactor * elementBreite
    doorPosition.y0 = doorPosition.y0 * scaleFactor
    doorPosition.y1 = doorPosition.y1 * scaleFactor
    doorPosition.y2 = doorPosition.y2 * scaleFactor
    doorPosition.y3 = doorPosition.y3 * scaleFactor
    doorPosition.x0 = doorPosition.x0 * scaleFactor - shiftLeft
    doorPosition.x1 = doorPosition.x1 * scaleFactor - shiftLeft
    doorPosition.x2 = doorPosition.x2 * scaleFactor - shiftLeft
    doorPosition.x3 = doorPosition.x3 * scaleFactor - shiftLeft
    doorPosition.doorOffset = this.doorOffset()
    this.#doorPosition = doorPosition
    this.doorPositionChange.emit(doorPosition)
  }

  setHausfront(hausfront: Hausfront, side: SideType): void {
    // only update if same hausfront as currently selected
    if (hausfront && this.currentHausfronten?.[side] && this.currentHausfronten[side].Url === hausfront.Url) {
      this.updateHausfront(hausfront, side)
      return
    }
    // otherwise set a new one
    this._configuratorConfigurationModel.configuratedDoor.Hausfronten[side] = hausfront ?? null
    if (hausfront) {
      this.loadingBackground = true
    }
  }

  setMiniDoorPosition(): void {
    document.querySelectorAll('.miniDoor').forEach((miniDoor: HTMLImageElement): void => {
      const miniHintergrundBild: HTMLElement = miniDoor.parentNode.querySelector('img:not(.miniDoor)')
      const hintergrundBreite: number = parseInt(miniHintergrundBild.dataset.bgwidth, 10)
      const hintergrundHoehe: number = parseInt(miniHintergrundBild.dataset.bgheight, 10)
      const abstandOben: number = parseInt(miniHintergrundBild.dataset.y1, 10)
      const abstandLinks: number = parseInt(miniHintergrundBild.dataset.x1, 10)
      const oeffnungsbreite: number = parseInt(miniHintergrundBild.dataset.x2, 10) - abstandLinks
      const oeffnungshoehe: number = parseInt(miniHintergrundBild.dataset.y3, 10) - abstandOben
      const backgroundWidth = miniHintergrundBild.offsetWidth
      const backgroundHeight = miniHintergrundBild.offsetHeight
      const skalierungsFaktor = hintergrundBreite / miniHintergrundBild.getBoundingClientRect().width
      miniDoor.style.setProperty('height', (oeffnungshoehe / skalierungsFaktor).toString() + 'px')
      miniDoor.style.setProperty('width', (oeffnungsbreite / skalierungsFaktor).toString() + 'px')
      miniDoor.style.setProperty('left', `${abstandLinks / hintergrundBreite * backgroundWidth + miniHintergrundBild.offsetLeft}px`)
      miniDoor.style.setProperty('top', `${abstandOben / hintergrundHoehe * backgroundHeight + miniHintergrundBild.offsetTop}px`)
      miniDoor.style.setProperty('display', 'block')
    })
  }

  updateDoorPosition(): void {
    this.setDoorPosition()
    this.setMiniDoorPosition()
  }

  updateHausfront(hausfront: Hausfront, side: SideType): void {
    this._configuratorConfigurationModel.configuratedDoor.Hausfronten[side] = hausfront
    this.onBackgroundLoaded()
  }

  get backgroundImage(): string {
    return this._configuratorConfigurationModel.configuratedDoor.Hausfronten[this.view].Url
  }

  get configuratedDoor(): ConfiguratedDoor {
    return this._configuratorConfigurationModel.configuratedDoor
  }

  get currentHausfronten(): InsideOutsideObject<Hausfront> {
    return this._configuratorConfigurationModel?.configuratedDoor?.Hausfronten
  }

  get customHausfronten(): Hausfront[] {
    return this.grundformService.grundformen
      .filter((g: Grundform): boolean => g.Id === this._configuratorConfigurationModel.configuratedDoor.Grundform.Id)[0]
      .Hausfronten.filter((h: Hausfront): boolean => h.Custom)
  }

  get doorPosition(): HausfrontDoorPosition {
    return this.#doorPosition
  }

  get hausfrontService(): HausfrontService {
    return this._hausfrontService
  }

  get hausfronten(): Hausfront[] {
    return this.grundformService.grundform.Hausfronten
  }

  get navigatorService(): NavigatorService {
    return this._navigatorService
  }

  get offsetHeight(): number {
    return document.getElementById('image_background')?.offsetHeight
  }

  get view(): SideType {
    return this._viewService.currentSide
  }
}
