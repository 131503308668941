import {ZubehoerAddonEntry} from './zubehoerAddonEntry'
import {Zubehoer} from './zubehoer'
import {ChangeType} from '../../../event/events.types'
import {ResponseMassblaetter} from '../../../../api/massblaetter/response-massblaetter.interface'

export class MehrpreisEntry {
  addons?: ZubehoerAddonEntry[]
  Item?: Zubehoer
  Typ: string

  constructor(data?: Partial<MehrpreisEntry>) {
    this.Typ = data && data.Typ
    this.Item = data && data.Item && new Zubehoer(data.Item)
    this.addons = []
    if (data && data.addons) {
      data.addons.forEach((a): void => {
        this.addons.push(new ZubehoerAddonEntry(a))
      })
    }
  }

  addAddon(addon: ZubehoerAddonEntry): void {
    if (
      typeof this.getAddon(addon.Typ) === 'undefined'
      && ((this.Item?.IsMontageMoeglich ?? true) || !addon.Typ.includes('montage'))
    ) {
      this.addons.push(addon)
    }
  }

  addItem(item: Zubehoer, montageAddon: ZubehoerAddonEntry): void {
    this.Item = new Zubehoer(item)
    const hasMontageAddon = this.addons?.some((a): boolean => a.Typ.includes('montage'))
    if (hasMontageAddon && !this.Item.IsMontageMoeglich || !hasMontageAddon && this.Item.IsMontageStandard) {
      this.toggleAddon(montageAddon)
    }
  }

  getAddon(addonTyp: string): ZubehoerAddonEntry | undefined {
    return this.addons.find((a): boolean => a.Typ === addonTyp)
  }

  isEmpty(): boolean {
    return this.addons.length <= 0 && !this.Item
  }

  removeAddon(addon: ZubehoerAddonEntry): void {
    const index = this.addons.indexOf(addon)
    if (index > -1) {
      this.addons.splice(index, 1)
    }
  }

  removeItem(): ChangeType.Removed | false {
    if (this.Item?.IsOptional !== false) {
      delete this.Item
      return ChangeType.Removed
    }
    return false
  }

  toggleAddon(addonData: ZubehoerAddonEntry): void {
    if (!addonData) {
      console.warn('Adding addon is impossible. Addon is falsy. (undefined or something...)')
      return
    }
    const zubehoerAddon = this.getAddon(addonData.Typ)
    if (typeof zubehoerAddon === 'undefined') {
      this.addAddon(new ZubehoerAddonEntry(addonData))
    } else {
      this.removeAddon(zubehoerAddon)
    }
  }

  updateAddonsMassblaetter(massblaetterUpdate: ResponseMassblaetter): void {
    this.addons.forEach(zubehoerAddonEntry => void zubehoerAddonEntry.updateMassblatt(massblaetterUpdate))
  }
}
