import {EventEmitter, Injectable} from '@angular/core'
import {LocalStorageService, LocalStorageServiceFactory} from '../../../storage/local-storage.service'
import MemoListItem from './memo-list-item'
import {HttpService} from '../../../http.service'
import {ParameterService} from '../parameter/parameter.service'
import {ConfiguratorConfigurationModel} from '../../model/configuratorConfigurationModel'
import {NGXLogger} from 'ngx-logger'
import {NavigatorService} from '../navigator.service'
import {NextObserver} from 'rxjs'
import {ToastrService} from 'ngx-toastr'
import {TranslateService} from '../../../translate'

const TRANSLATION_KEY = {
  TOAST_ADDED_CONFIG_TO_LIST_MESSAGE: 'MemoListService.Toast.AddedConfigToList.Message',
  TOAST_ADDED_CONFIG_TO_LIST_TITLE: 'MemoListService.Toast.AddedConfigToList.Title',
  TOAST_REMOVED_CONFIG_FROM_LIST_MESSAGE: 'MemoListService.Toast.RemovedConfigFromList.Message',
  TOAST_REMOVED_CONFIG_FROM_LIST_TITLE: 'MemoListService.Toast.RemovedConfigFromList.Title'
}
type DoorId = string
type StoredMemoListItem = {
  description: string
  doorId: DoorId
}
@Injectable()
export default class MemoListService {
  private readonly itemAdded: EventEmitter<MemoListItem>
  private readonly itemRemoved: EventEmitter<MemoListItem>
  private readonly memoList: MemoListItem[]
  private readonly memoListHolder: LocalStorageService<{ memoList: StoredMemoListItem[] }>

  constructor(
    private logger: NGXLogger,
    private httpService: HttpService,
    private configuratorConfigurationModel: ConfiguratorConfigurationModel,
    private parameterService: ParameterService,
    private navigatorService: NavigatorService,
    private toastrService: ToastrService,
    private _translate: TranslateService,
    localStorageServiceFactory: LocalStorageServiceFactory
  ) {
    this.itemAdded = new EventEmitter<MemoListItem>()
    this.itemRemoved = new EventEmitter<MemoListItem>()
    this.memoListHolder = localStorageServiceFactory.create('memoList')
    if (!this.memoListHolder.has('memoList')) {
      this.memoListHolder.put('memoList', [])
    }
    this.memoList = this.memoListHolder.getObject('memoList')
      .map((item): MemoListItem => new MemoListItem(this.httpService, item.description, item.doorId))
    this.insertCountToNavigation()
  }

  addCurrentDoor(): void {
    if (this.parameterService.parameter.tuerId) {
      if (!this.has(this.parameterService.parameter.tuerId)) {
        this.unshift({
          description: this.configuratorConfigurationModel.configuratedDoor.tueren[0]?.model.Bezeichnung,
          doorId: this.parameterService.parameter.tuerId
        })
      }
    } else {
      this.logger.warn('Can not add door to memo list because doorId is not set')
    }
    this.toastrService.success(
      this._translate.translate(TRANSLATION_KEY.TOAST_ADDED_CONFIG_TO_LIST_MESSAGE),
      this._translate.translate(TRANSLATION_KEY.TOAST_ADDED_CONFIG_TO_LIST_TITLE)
    )
  }

  public get(): MemoListItem[]

  public get(index: number): MemoListItem

  public get(index?: number): MemoListItem | MemoListItem[] {
    if (index) {
      return this.memoList[index]
    }
    return this.memoList
  }

  public has(doorId: DoorId): boolean {
    return this.memoList.findIndex((item): boolean => item.doorId === doorId) !== -1
  }

  private insertCountToNavigation(): void {
    const fertigNavigationEntry = this.navigatorService.navigationEntries?.Fertig
    if (fertigNavigationEntry?.title) {
      fertigNavigationEntry.title = `(0) ${fertigNavigationEntry.title}`
      this.updateNavigation()
    }
  }

  public push(item: StoredMemoListItem): void {
    const listItem = new MemoListItem(this.httpService, item.description, item.doorId)
    this.memoList.push(listItem)
    this.storeList()
    this.itemAdded.emit(listItem)
  }

  public remove(item: MemoListItem): void {
    const index = this.memoList.indexOf(item)
    if (index !== -1) {
      const listItems = this.memoList.splice(index, 1)
      this.storeList()
      listItems.forEach((listItem) => void this.itemRemoved.emit(listItem))
    }
  }

  removeCurrentDoor(): void {
    if (this.parameterService.parameter.tuerId) {
      const currentDoor = this.memoList.find((item): boolean => item.doorId === this.parameterService.parameter.tuerId)
      if (currentDoor) {
        this.remove(currentDoor)
        this.toastrService.warning(
          this._translate.translate(TRANSLATION_KEY.TOAST_REMOVED_CONFIG_FROM_LIST_MESSAGE),
          this._translate.translate(TRANSLATION_KEY.TOAST_REMOVED_CONFIG_FROM_LIST_TITLE)
        )
      }
    } else {
      this.logger.warn('Can not remove door from memo list because doorId is not set')
    }
  }

  private storeList(): void {
    this.memoListHolder.put('memoList', this.memoList.map((listItem): StoredMemoListItem => ({
      description: listItem.description,
      doorId: listItem.doorId
    })))
    this.updateNavigation()
  }

  subscribeItemAdded(observer: NextObserver<MemoListItem> | NextObserver<MemoListItem>['next']): void {
    this.itemAdded.subscribe(observer)
  }

  subscribeItemRemoved(observer: NextObserver<MemoListItem> | NextObserver<MemoListItem>['next']): void {
    this.itemRemoved.subscribe(observer)
  }

  private unshift(item: StoredMemoListItem): void {
    const listItem = new MemoListItem(this.httpService, item.description, item.doorId)
    this.memoList.unshift(listItem)
    this.storeList()
    this.itemAdded.emit(listItem)
  }

  private updateNavigation(): void {
    const fertigNavigationEntry = this.navigatorService.navigationEntries?.Fertig
    if (fertigNavigationEntry?.title) {
      const match = fertigNavigationEntry?.title.match(/^\(\d+\) (?<title>.*)$/)
      if (match?.groups.title) {
        fertigNavigationEntry.title = `(${this.length}) ${match.groups.title}`
      }
    }
  }

  get length(): number {
    return this.memoList.length
  }
}
