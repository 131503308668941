import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {StringUtil} from '../../../classes/util/stringUtil'
import {Zubehoer} from '../../../classes/model/component/extras/zubehoer/zubehoer'
import {MehrpreisCategory} from '../../../classes/model/component/extras/mehrpreis/fbsMehrpreis'
import {Material} from '../../../classes/model/material'
import {ComponentSelectionService} from '../../../classes/service/componentSelectionService'
import {TranslateService} from 'src/app/translate'

const TRANSLATION_KEY = {
  TITLE_PREFIX: 'MehrpreisModal.Title.Prefix',
  TITLE_SUFFIX: 'MehrpreisModal.Title.Suffix',
  MODAL_SEARCH_INPUT_LABEL: 'MehrpreisModal.Search.Input.Label',
  MODAL_BUTTON_SAVE: 'MehrpreisModal.Button.Save',
  REASON_THICKNESS_TOO_SMALL_PREFIX: 'MehrpreisModal.DisabilityReason.ThicknessTooSmall.Prefix',
  REASON_THICKNESS_TOO_SMALL_INFIX: 'MehrpreisModal.DisabilityReason.ThicknessTooSmall.Infix',
  REASON_THICKNESS_TOO_SMALL_SUFFIX: 'MehrpreisModal.DisabilityReason.ThicknessTooSmall.Suffix',
  REASON_THICKNESS_TOO_LARGE_PREFIX: 'MehrpreisModal.DisabilityReason.ThicknessTooLarge.Prefix',
  REASON_THICKNESS_TOO_LARGE_INFIX: 'MehrpreisModal.DisabilityReason.ThicknessTooLarge.Infix',
  REASON_THICKNESS_TOO_LARGE_SUFFIX: 'MehrpreisModal.DisabilityReason.ThicknessTooLarge.Suffix',
  REASON_ALU_IMPOSSIBLE: 'MehrpreisModal.DisabilityReason.Alu.Impossible',
  REASON_GLASS_IMPOSSIBLE: 'MehrpreisModal.DisabilityReason.Glass.Impossible',
  REASON_KU_IMPOSSIBLE: 'MehrpreisModal.DisabilityReason.Ku.Impossible',
  REASON_DINLEFT_IMPOSSIBLE: 'MehrpreisModal.DisabilityReason.DinLeft.Impossible',
  REASON_DINRIGHT_IMPOSSIBLE: 'MehrpreisModal.DisabilityReason.DinRight.Impossible',
  REASON_NOT_UNSELECTABLE: 'MehrpreisModal.DisabilityReason.NotUnselectable',
  REASON_NOT_SELECTABLE: 'MehrpreisModal.DisabilityReason.NotSelectable'
}
export type MehrpreisModalData = {
  category: MehrpreisCategory
}

@Component({
  templateUrl: './mehrpreis-modal.component.html',
  styleUrls: ['./mehrpreis-modal.component.scss']
})
export class MehrpreisModalComponent {
  protected readonly StringUtil = StringUtil
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  category: string
  filterTerm: string
  preselectedZubehoer: Zubehoer
  selectedZubehoerIds: number[]
  zubehoer: Zubehoer[]

  constructor(
    public dialogRef: MatDialogRef<MehrpreisModalComponent, Zubehoer | undefined>,
    private componentSelection: ComponentSelectionService,
    private translator: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: MehrpreisModalData
  ) {
    this.category = data.category
    this.filterTerm = ''
    this.zubehoer = this.filterZubehoer(this.componentSelection.selectedComponent.model.Zubehoer, this.category)
    this.preselectedZubehoer = undefined
    this.selectedZubehoerIds = []
    this.selectedZubehoerIds = this.getSelectedZubehoerIds()
  }

  filterZubehoer(zubehoer: readonly Zubehoer[], category: string): Zubehoer[] {
    if (zubehoer) {
      const filtered = zubehoer.filter((z): boolean => z.Typ === this.category)
      // order by Sort then By Bezeichnung and Beschreibung
      /* filtered = filtered.sort(
        (a, b): number => (a.Sort > b.Sort)
          ? 1
          : (a.Bezeichnung > b.Bezeichnung)
            ? 1
            : (a.Bezeichnung === b.Bezeichnung)
              ? (
                (a.Beschreibung > b.Beschreibung)
                  ? 1
                  : -1
              )
              : -1
      )*/
      const selected = this.getSelectedZubehoer(category)
      if (selected) {
        const index = filtered.map((e): number => e.Id).indexOf(selected.Id)
        filtered.splice(index, 1)
        filtered.unshift(selected)
      }
      return filtered
    } else {
      return []
    }
  }

  getSelectedZubehoer(category: string): Zubehoer {
    const foundZubehoer = this.componentSelection.selectedComponent.Mehrpreise.find(
      (z): boolean => z.Typ === category && !!z.Item
    )
    if (foundZubehoer) {
      return foundZubehoer.Item
    }
    return null
  }

  getSelectedZubehoerIds(): number[] {
    return [...this.componentSelection.selectedComponent.Mehrpreise.map((z): number => z?.Item?.Id)]
  }

  mehrpreisBezeichnung(): string {
    return this.componentSelection.selectedComponent.model.Mehrpreise.find((m): boolean => m.Typ === this.category).Bezeichnung
  }

  onConfirm(): void {
    this.dialogRef.close(
      this.preselectedZubehoer
    )
  }

  preselectZubehoer(item: Zubehoer): void {
    if (this.preselectedZubehoer === item) {
      this.preselectedZubehoer = undefined
    } else {
      this.preselectedZubehoer = item
    }
  }

  updateZubehoerVisibility(zubehoer: Zubehoer): true {
    const reasons: string[] = []
    if (zubehoer.StaerkeVon === 0 && zubehoer.StaerkeBis === 0) {
      return true
    }
    if (zubehoer.StaerkeVon > 0 && this.componentSelection.selectedComponent.staerke < zubehoer.StaerkeVon) {
      reasons.push(this.translator.translate(TRANSLATION_KEY.REASON_THICKNESS_TOO_SMALL_PREFIX)
        + zubehoer.StaerkeVon
        + this.translator.translate(TRANSLATION_KEY.REASON_THICKNESS_TOO_SMALL_INFIX)
        + this.componentSelection.selectedComponent.staerke
        + this.translator.translate(TRANSLATION_KEY.REASON_THICKNESS_TOO_SMALL_SUFFIX)
      )
    }
    if (zubehoer.StaerkeBis > 0 && this.componentSelection.selectedComponent.staerke > zubehoer.StaerkeBis) {
      reasons.push(this.translator.translate(TRANSLATION_KEY.REASON_THICKNESS_TOO_LARGE_PREFIX)
        + zubehoer.StaerkeBis
        + this.translator.translate(TRANSLATION_KEY.REASON_THICKNESS_TOO_LARGE_INFIX)
        + this.componentSelection.selectedComponent.staerke
        + this.translator.translate(TRANSLATION_KEY.REASON_THICKNESS_TOO_LARGE_SUFFIX))
    }
    if (this.componentSelection.selectedComponent.material === Material.Alu && !zubehoer.IsAluMoeglich) {
      reasons.push(this.translator.translate(TRANSLATION_KEY.REASON_ALU_IMPOSSIBLE))
    }
    if (this.componentSelection.selectedComponent.material === Material.Glas && !zubehoer.IsGlasMoeglich) {
      reasons.push(this.translator.translate(TRANSLATION_KEY.REASON_GLASS_IMPOSSIBLE))
    }
    if (this.componentSelection.selectedComponent.material === Material.Kunststoff && !zubehoer.IsKunststoffMoeglich) {
      reasons.push(this.translator.translate(TRANSLATION_KEY.REASON_KU_IMPOSSIBLE))
    }
    if (!zubehoer.IsDinRechtsMoeglich && !zubehoer.IsDinLinksMoeglich) {
      // alles ok wenn beides FALSE ist
    } else if (this.componentSelection.selectedComponent.din === 0 && !zubehoer.IsDinLinksMoeglich) {
      reasons.push(this.translator.translate(TRANSLATION_KEY.REASON_DINLEFT_IMPOSSIBLE))
    } else if (this.componentSelection.selectedComponent.din === 1 && !zubehoer.IsDinRechtsMoeglich) {
      reasons.push(this.translator.translate(TRANSLATION_KEY.REASON_DINRIGHT_IMPOSSIBLE))
    }
    if (zubehoer.IsStandard && !zubehoer.IsOptional) {
      reasons.push(this.translator.translate(TRANSLATION_KEY.REASON_NOT_UNSELECTABLE))
    } else if (!zubehoer.IsStandard && !zubehoer.IsOptional) {
      reasons.push(this.translator.translate(TRANSLATION_KEY.REASON_NOT_SELECTABLE))
    }
    zubehoer.disabilityReasons = reasons
    return true
  }

  zubehoerByTerm(): Zubehoer[] {
    if (this.filterTerm.length === 0) {
      return this.zubehoer
    }
    return this.zubehoer.filter((z): boolean => {
      const bezeichnung = z.Bezeichnung && this.translator.translate(z.Bezeichnung).toLowerCase().includes(this.filterTerm.toLowerCase())
      const beschreibung = z.Beschreibung && this.translator.translate(z.Beschreibung).toLowerCase().includes(this.filterTerm.toLowerCase())
      const id = z.Id.toString().includes(this.filterTerm)
      return bezeichnung || beschreibung || id
    })
  }

  zubehoerPreselected(item: Zubehoer): boolean {
    return this.preselectedZubehoer?.Id === item.Id
  }
}
