
    <button
        class="before"
        [class.disable]="previousButtonConfiguration.isDisabled()"
        (click)="previousButtonConfiguration.onClick()"
>
    {{ previousButtonConfiguration.getDisplayString() }}
</button>
<button
        class="next active"
        [class.disable]="nextButtonConfiguration.isDisabled()"
        (click)="nextButtonConfiguration.onClick()"
>
    {{ nextButtonConfiguration.getDisplayString() }}
</button>

<div class="navSquares" (click)="this.navigatorService.toggleNav()">
    <ng-container *ngIf="this.isMobile && this.navigatorService.updateNavSquareNavigation()">
    </ng-container>
</div>
