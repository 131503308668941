import {ZubehoerCategoryIdentifier, ZubehoerCategoryName} from './zubehoer.type'

export class Zubehoer {
  AnzahlBohrungen: number
  Beschreibung: string
  Bezeichnung: string
  Bohrabstand1: number
  Bohrabstand2: number
  Bohrabstand3: number
  Breite: number
  disabilityReasons: string[] = []
  Hoehe: number
  Id: number
  ImageUrl: string
  IsAluMoeglich: boolean
  IsAussenMoeglich: boolean
  IsDigital: boolean
  IsDinLinksMoeglich: boolean
  IsDinRechtsMoeglich: boolean
  IsGlasMoeglich: boolean
  IsInnenMoeglich: boolean
  IsKunststoffMoeglich: boolean
  IsMontageMoeglich: boolean
  IsMontageStandard: boolean
  IsOptional: boolean
  IsStandard: boolean
  loaded: boolean
  // Massblatt: any
  PreviewImageUrl: string
  Sort: number
  StaerkeBis: number
  StaerkeVon: number
  Typ: string
  Value: string

  constructor(data?: Partial<Zubehoer>) {
    this.AnzahlBohrungen = data && data.AnzahlBohrungen
    this.Beschreibung = data && data.Beschreibung
    this.Bezeichnung = data && data.Bezeichnung
    this.Bohrabstand1 = data && data.Bohrabstand1
    this.Bohrabstand2 = data && data.Bohrabstand2
    this.Bohrabstand3 = data && data.Bohrabstand3
    this.Breite = data && data.Breite
    this.Hoehe = data && data.Hoehe
    this.Id = data && data.Id
    this.Sort = data && data.Sort
    this.ImageUrl = data && data.ImageUrl
    this.IsDigital = data && data.IsDigital
    this.PreviewImageUrl = data && data.PreviewImageUrl
    this.StaerkeBis = data && data.StaerkeBis
    this.StaerkeVon = data && data.StaerkeVon
    this.Typ = data && data.Typ
    // this.Massblatt = data && data.Massblatt
    this.loaded = false
    this.IsAluMoeglich = data && data.IsAluMoeglich
    this.IsAussenMoeglich = data && data.IsAussenMoeglich
    this.IsDinLinksMoeglich = data && data.IsDinLinksMoeglich
    this.IsDinRechtsMoeglich = data && data.IsDinRechtsMoeglich
    this.IsGlasMoeglich = data && data.IsGlasMoeglich
    this.IsInnenMoeglich = data && data.IsInnenMoeglich
    this.IsKunststoffMoeglich = data && data.IsKunststoffMoeglich
    this.IsMontageMoeglich = data && data.IsMontageMoeglich
    this.IsMontageStandard = data && data.IsMontageStandard
    this.IsStandard = data && data.IsStandard
    this.IsOptional = data && data.IsOptional
    this.Value = data && data.Value
  }

  public static getCategoryName(typ: ZubehoerCategoryIdentifier): ZubehoerCategoryName {
    return {
      briefdurchwurf: 'Briefdurchwürfe',
      drueckergarnitur: 'Drückergarnituren',
      fingerprint: 'Fingerprint',
      griffschale: 'Griffschalen',
      indoor_design: 'Indoor Designs',
      rosette: 'Rosetten',
      sockelleiste: 'Sockelleisten',
      spion: 'Türspione',
      stangengriff: 'Stangengriffe',
      tierklappen: 'Tierklappen',
      tuerklopfer: 'Türklopfer',
      zubehoer_allgemein: 'Allgemein',
    }[typ] ?? typ
  }
}
