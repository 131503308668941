import {Component, EventEmitter, Input, Output} from '@angular/core'
import {NavigatorService} from '../../../classes/service/navigator.service'
import {TranslateService} from '../../../translate'
import {ParameterModelConfiguration} from '../../../classes/model/modelConfiguration/parameterModelConfiguration'
import {GrundformService} from '../../../classes/service/grundform.service'
import {Grundform} from '../../../classes/model/component/other/grundform'
import {ConfiguratorConfigurationModel} from '../../../classes/model/configuratorConfigurationModel'
import {firstValueFrom} from 'rxjs'

const TRANSLATION_KEY = {
  TITLE: 'GrundformMenuComponent.Title'
}

@Component({
  selector: 'configurator-grundform-menu',
  styleUrls: ['./grundform-menu.component.scss'],
  templateUrl: './grundform-menu.component.html'
})
export class GrundformMenuComponent {
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  @Input() isMobile: boolean
  @Input() lazyImageLoaded: (object: unknown) => void
  @Input() parameterModel: ParameterModelConfiguration
  @Output() readonly renderRequest: EventEmitter<void>

  constructor(
    private _navigatorService: NavigatorService,
    private _translateService: TranslateService,
    private _grundformService: GrundformService,
    private configuratorConfigurationModel: ConfiguratorConfigurationModel
  ) {
    this.renderRequest = new EventEmitter<void>()
  }

  setGrundform(grundform: Grundform): void {
    this.configuratorConfigurationModel.grundform = grundform
    void firstValueFrom(this.configuratorConfigurationModel.modelsLoadedAfterGrundformChange)
      .then(() => void this.renderRequest.emit())
  }

  get currentGrundformKey(): string {
    return this.configuratorConfigurationModel.configuratedDoor?.Grundform?.Key
  }

  get grundformService(): GrundformService {
    return this._grundformService
  }

  get navigatorService(): NavigatorService {
    return this._navigatorService
  }

  get translateService(): TranslateService {
    return this._translateService
  }
}
