import {ColorType} from '../../../types'

export abstract class ColorBase {
  Beschreibung: string
  Bezeichnung: string
  Id: number
  IsAluMoeglich: boolean
  IsBlendrahmenMoeglich: boolean
  IsFluegelrahmenMoeglich: boolean
  IsGlasMoeglich: boolean
  IsKunststoffMoeglich: boolean
  RGB: string
  RodenbergCode: string

  constructor(data?: Partial<ColorBase>) {
    this.Beschreibung = data?.Beschreibung
    this.Bezeichnung = data?.Bezeichnung
    this.Id = data?.Id
    this.IsAluMoeglich = data?.IsAluMoeglich
    this.IsBlendrahmenMoeglich = data?.IsBlendrahmenMoeglich
    this.IsFluegelrahmenMoeglich = data?.IsFluegelrahmenMoeglich
    this.IsGlasMoeglich = data?.IsGlasMoeglich
    this.IsKunststoffMoeglich = data?.IsKunststoffMoeglich
    this.RGB = data?.RGB
    this.RodenbergCode = data?.RodenbergCode
  }

  abstract getType(): ColorType
}
