import {Component, DoCheck, EventEmitter, KeyValueDiffer, KeyValueDiffers, Output} from '@angular/core'
import {NavigatorService} from '../../../classes/service/navigator.service'
import {ConfiguratorConfigurationModel} from '../../../classes/model/configuratorConfigurationModel'
import {ConfiguratedDoor} from '../../../classes/model/configuratedDoor'
import {CharacteristicsData} from '../../../classes/model/dataProvider/characteristics-data/characteristics-data'
import {HttpService} from '../../../http.service'
import {
  CharacteristicsDataFactory
} from '../../../classes/model/dataProvider/characteristics-data/characteristics-data.factory'
import {
  CharacteristicsDataItem
} from '../../../classes/model/dataProvider/characteristics-data/characteristics-data-item'
import {ConfiguratorModeService} from '../../../classes/service/configuratorMode.service'
import {ConfiguratorMode} from '../../../types'
import {ComponentSelectionService} from '../../../classes/service/componentSelectionService'
import {Tuer} from '../../../classes/model/component/construction/tuer'
import {Seitenteil} from '../../../classes/model/component/construction/seitenteil'
import {Oberlicht} from '../../../classes/model/component/construction/oberlicht'
import {TranslateService} from '../../../translate'
import {NGXLogger} from 'ngx-logger'
import {Observable, Observer} from 'rxjs'
import {ResponseInsulation} from '../../../classes/api/insulations/response-insulation.interface'

const TRANSLATION_KEY = {
  COMPONENT_IDENTIFIER_DESIGNATION_DOOR_PREFIX:
    'CharacteristicsMenuComponent.Heading.ComponentIdentifierDesignation.Door.Prefix',
  COMPONENT_IDENTIFIER_DESIGNATION_DOOR_SUFFIX:
    'CharacteristicsMenuComponent.Heading.ComponentIdentifierDesignation.Door.Suffix',
  COMPONENT_IDENTIFIER_DESIGNATION_SIDE_PANEL_PREFIX:
    'CharacteristicsMenuComponent.Heading.ComponentIdentifierDesignation.SidePanel.Prefix',
  COMPONENT_IDENTIFIER_DESIGNATION_SIDE_PANEL_SUFFIX:
    'CharacteristicsMenuComponent.Heading.ComponentIdentifierDesignation.SidePanel.Suffix',
  COMPONENT_IDENTIFIER_DESIGNATION_SKYLIGHT_PREFIX:
    'CharacteristicsMenuComponent.Heading.ComponentIdentifierDesignation.Skylight.Prefix',
  COMPONENT_IDENTIFIER_DESIGNATION_SKYLIGHT_SUFFIX:
    'CharacteristicsMenuComponent.Heading.ComponentIdentifierDesignation.Skylight.Suffix',
  COMPONENT_IDENTIFIER_DESIGNATION_UNKNOWN_PREFIX:
    'CharacteristicsMenuComponent.Heading.ComponentIdentifierDesignation.Unknown.Prefix',
  COMPONENT_IDENTIFIER_DESIGNATION_UNKNOWN_SUFFIX:
    'CharacteristicsMenuComponent.Heading.ComponentIdentifierDesignation.Unknown.Suffix',
  MENU_NAME: 'CharacteristicsMenuComponent.Heading.MenuName',
  TABLE_HEADING_COMMON: 'CharacteristicsMenuComponent.Heading.CommonTable',
  TABLE_CALC_LABEL_WEIGHT: 'CharacteristicsMenuComponent.Table.CalcValues.Label.Weight',
  TABLE_CALC_VALUE_WEIGHT_PREFIX: 'CharacteristicsMenuComponent.Table.CalcValues.Value.Weight.Prefix',
  TABLE_CALC_VALUE_WEIGHT_SUFFIX: 'CharacteristicsMenuComponent.Table.CalcValues.Value.Weight.Suffix',
  TABLE_CALC_LABEL_INSULATION_UP: 'CharacteristicsMenuComponent.Table.CalcValues.Label.Insulation.Up',
  TABLE_CALC_LABEL_INSULATION_UG: 'CharacteristicsMenuComponent.Table.CalcValues.Label.Insulation.Ug',
  TABLE_SUMMARY_LABEL_PREFIX: 'CharacteristicsMenuComponent.Table.Summary.Label.Prefix',
  TABLE_SUMMARY_LABEL_TITLE: 'CharacteristicsMenuComponent.Table.Summary.Label.Titel',
  TABLE_SUMMARY_LABEL_TEXT: 'CharacteristicsMenuComponent.Table.Summary.Label.Text',
  TABLE_SUMMARY_NO_DATA: 'CharacteristicsMenuComponent.Table.Summary.NoData',
  LOADING_IN_PROGRESS: 'CharacteristicsMenuComponent.Table.LoadingInProgress'
} as const


@Component({
  selector: 'configurator-characteristics-menu',
  templateUrl: './characteristics-menu.component.html',
  styleUrls: ['./characteristics-menu.component.scss']
})
export class CharacteristicsMenuComponent implements DoCheck {
  protected characteristics: CharacteristicsData
  @Output() protected closeMenu: EventEmitter<void>
  protected readonly ConfiguratorMode = ConfiguratorMode
  private differ: KeyValueDiffer<string, unknown>
  private dirty: boolean
  protected doorWeight: number | null
  protected insulation: ResponseInsulation | null
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY

  constructor(
    protected readonly navigatorService: NavigatorService,
    private configuratorConfigurationModel: ConfiguratorConfigurationModel,
    private httpService: HttpService,
    private characteristicsDataFactory: CharacteristicsDataFactory,
    private configuratorModeService: ConfiguratorModeService,
    private componentSelectionService: ComponentSelectionService,
    private translateService: TranslateService,
    private logger: NGXLogger,
    differs: KeyValueDiffers,
  ) {
    this.differ = differs.find({}).create<string, unknown>()
    this.characteristics = new CharacteristicsData()
    this.closeMenu = new EventEmitter<void>()
    this.configuratorConfigurationModel.grundformChange.subscribe((): void => {
      this.characteristics = new CharacteristicsData()
    })
    this.doorWeight = null
    this.insulation = null
  }

  ngDoCheck(): void {
    if (!this.dirty) {
      const changes = this.differ.diff(this.configuratedDoor as unknown as { [key: string]: unknown })
      if (changes) {
        changes.forEachItem((item): void => {
          if (item.key === 'Images' && item.currentValue && item.currentValue !== item.previousValue) {
            this.dirty = true
          }
        })
        if (this.dirty) {
          const subscriberToPromise = <T>(subscribable: Observable<T>, next: Observer<T>['next']): Promise<void> =>
            new Promise<void>((resolve, reject): void => {
              subscribable.subscribe({
                next,
                error: (error): void => {
                  this.logger.error(error)
                  reject(error)
                },
                complete: (): void => {
                  resolve()
                }
              })
            })
          const element = this.configuratorConfigurationModel.configuratedDoorDto
          this.characteristics = new CharacteristicsData()
          const promises = []
          promises.push(
            subscriberToPromise(
              this.httpService.fbsGetTexts(element),
              (data): void => {
                this.characteristics = this.characteristicsDataFactory.create(data)
              }
            )
          )
          if (this.configuratorMode === ConfiguratorMode.FBS) {
            this.doorWeight = this.insulation = null
            promises.push(
              subscriberToPromise(
                this.httpService.fbsGetWeights(element),
                (data): void => {
                  this.doorWeight = this.configuratedDoor.DoorWeight = data.Weight
                }
              ),
              subscriberToPromise(
                this.httpService.fbsGetInsulations(element),
                (data): void => {
                  this.insulation = this.configuratedDoor.Insulation = data
                }
              ),
            )
          }
          void Promise.all(promises).then(() => void (this.dirty = false))
        }
      }
    }
  }

  get categoryCount(): number {
    return this.characteristics.getCategoryCount()
  }

  get characteristicsData(): readonly CharacteristicsDataItem[] {
    return this.characteristics.dataArray
  }
  get configuratedDoor(): ConfiguratedDoor {
    return this.configuratorConfigurationModel.configuratedDoor
  }

  get configuratorMode(): ConfiguratorMode {
    return this.configuratorModeService.mode
  }

  get selectedComponentIndex(): number {
    return this.componentSelectionService.selectedComponentIndex
  }

  get selectedComponentLabel(): string {
    const component = this.componentSelectionService.selectedComponent
    const [prefix, suffix] = component instanceof Tuer
      ? [TRANSLATION_KEY.COMPONENT_IDENTIFIER_DESIGNATION_DOOR_PREFIX,
        TRANSLATION_KEY.COMPONENT_IDENTIFIER_DESIGNATION_DOOR_SUFFIX]
      : component instanceof Seitenteil
        ? [TRANSLATION_KEY.COMPONENT_IDENTIFIER_DESIGNATION_SIDE_PANEL_PREFIX,
          TRANSLATION_KEY.COMPONENT_IDENTIFIER_DESIGNATION_SIDE_PANEL_SUFFIX]
        : component instanceof Oberlicht
          ? [TRANSLATION_KEY.COMPONENT_IDENTIFIER_DESIGNATION_SKYLIGHT_PREFIX,
            TRANSLATION_KEY.COMPONENT_IDENTIFIER_DESIGNATION_SKYLIGHT_SUFFIX]
          : [TRANSLATION_KEY.COMPONENT_IDENTIFIER_DESIGNATION_UNKNOWN_PREFIX,
            TRANSLATION_KEY.COMPONENT_IDENTIFIER_DESIGNATION_UNKNOWN_SUFFIX]
    return`${this.translateService.translate(prefix)}${component.model.Bezeichnung}${this.translateService.translate(suffix)}`
  }
}
