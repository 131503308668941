<div
        class="subNavigationContainer"
        [attr.data-showtoggle]="menuNavigatorIdentifier"
        [class.active]="navigatorService.showToggle === menuNavigatorIdentifier"
        [class.fbs]="this.configuratorMode === ConfiguratorMode.FBS"
        [ngClass]="menuClass"
>
    <ng-template [ngTemplateOutlet]="menuHead"></ng-template>


    <configurator-component-selector *ngIf="componentSelector"></configurator-component-selector>

    <ng-container *ngIf="navigatorService.showToggle === menuNavigatorIdentifier">
        <ng-content></ng-content>
    </ng-container>

    <configurator-menu-navigation [isMobile]="isMobile">
    </configurator-menu-navigation>
</div>
