import {NGXLogger} from 'ngx-logger'
import { Inject, Injectable} from '@angular/core'
import {ConfiguratorConfigurationModel} from '../classes/model/configuratorConfigurationModel'
import {ParameterService} from '../classes/service/parameter/parameter.service'
import {WorkflowAccessor} from './workflow.types'
import {Material} from '../classes/model/material'
import {Dienstleistung} from '../classes/model/component/extras/mehrpreis/dienstleistung'
import {MehrpreisEntry} from '../classes/model/component/extras/zubehoer/mehrpreisEntry'
import {ZubehoerAddonEntry} from '../classes/model/component/extras/zubehoer/zubehoerAddonEntry'
import WorkflowParser from './workflow-parser'
import WorkflowModule, {MassblattUpdateDebounceTimeMs} from './workflow.module'
import {Zubehoer} from '../classes/model/component/extras/zubehoer/zubehoer'
import { PackageType } from '../classes/model/component/other/paket'
import {Massblatt} from '../classes/model/component/extras/massblatt'
import {isSimpleConstruction, SimpleConstruction} from '../classes/model/component/other/construction'
import {ConfiguratorDataModel} from '../classes/model/configuratorDataModel'
import {Glasaufbau} from '../classes/model/component/glassaufbau/glasaufbau'
import {GlasaufbauPositionShort} from '../types'
import {ConstructionComponent} from '../classes/model/component/construction/constructionComponent'

const supportedMehrpreisKeys: string[] = ['Typ'] satisfies (keyof MehrpreisEntry)[]
const supportedDienstleistungKeys: string[] = ['Typ', 'Id'] satisfies (keyof ZubehoerAddonEntry & keyof Dienstleistung)[]
const supportedMassblattKeys: string[] = ['Id'] satisfies (keyof Massblatt)[]

@Injectable({providedIn: WorkflowModule})
export class WorkflowAccessorBuilder {
  private readonly ccmMassblattDebounceMap: WeakMap<ConfiguratorConfigurationModel, MassblattUpdateDebounceData>
  constructor(
    private logger: NGXLogger,
    @Inject(MassblattUpdateDebounceTimeMs) private readonly massblattUpdateDebounceTimeMs: number
  ) {
    this.ccmMassblattDebounceMap = new WeakMap()
  }

  public buildAccessor(selector: string): WorkflowAccessor<unknown> {
    const selectorParts = selector.split('.') as string[] & [string]
    const firstSelectorPartLc = selectorParts?.[0].toLowerCase()
    switch (firstSelectorPartLc) {
      case 'basis':
      case '!basis':
        return this.buildBasisAccessor(selectorParts.slice(1))
      case 'deckschichten':
      case '!deckschichten':
        return this.buildCoverLayerAccessor(selectorParts.slice(1))
      case 'gläser':
      case '!gläser':
      case 'glaeser':
      case '!glaeser':
        return this.buildGlassesAccessor(selectorParts.slice(1))
      case 'parameter':
      case '!parameter':
        if (selectorParts[1].toLowerCase() === 'modelle') {
          return this.buildParameterModelAccessor(selectorParts.slice(2))
        } else {
          return this.buildParameterAccessor(selectorParts.slice(1))
        }
    }
    if (firstSelectorPartLc.startsWith('mehrpreise') || firstSelectorPartLc.startsWith('!mehrpreise')) {
      return this.buildMehrpreiseAccessor(selectorParts)
    }
    this.logger.error('Unknown workflow selector key "' + selectorParts[0] + '"!')
    return this.getUndefinedAccessor(false)
  }

  private buildBasisAccessor(selectorParts: string[]): WorkflowAccessor<unknown> {
    if (selectorParts.length > 1) {
      this.logger.warn('Too many workflow selector child keys in "basis"!', selectorParts)
    }
    switch (selectorParts[0]?.toLowerCase()) {
      case 'konstruktion':
        return {
          get: (ccm: ConfiguratorConfigurationModel): 'E' | 'AE' | 'AB' => {
            switch (ccm.selectedComponent.konstruktion) {
              case SimpleConstruction.AufsatzEinseitig:
                return 'AE'
              case SimpleConstruction.AufsatzBeidseitig:
                return 'AB'
              case SimpleConstruction.Einsatz:
              default:
                return 'E'
            }
          },
          set: (ccm: ConfiguratorConfigurationModel, _, __, value: number): void => {
            if (isSimpleConstruction(value)) {
              ccm.selectedComponent.konstruktion = value
            }
          }
        }
      case 'konstruktionsvariante':
        return {
          get: (ccm: ConfiguratorConfigurationModel): number => ccm.selectedComponent.fbsKonstruktion.Id,
          set: (ccm: ConfiguratorConfigurationModel, _, __, value: string): void => {
            const constructionId = Number.parseInt(value, 10)
            ccm.selectedComponent.fbsKonstruktion = ccm.selectedComponent.model.Konstruktionen.find(
              (construction): boolean => construction.Id === constructionId
            )
          }
        }
      case 'materialtürfüllung':
      case 'materialtuerfüllung':
      case 'materialtürfuellung':
      case 'materialtuerfuellung':
        return {
          get: (ccm: ConfiguratorConfigurationModel): Material => ccm.selectedComponent.material,
          set: (ccm: ConfiguratorConfigurationModel, _,  __, value: Material): void =>
            ccm.configuratedDoor.Components.forEach((c): void => {
              c.material = value
            })
        }
      case 'materialtürsystem':
      case 'materialtuersystem':
        return {
          get: (ccm: ConfiguratorConfigurationModel): Material => ccm.configuratedDoor.profile.Material,
          set: (ccm: ConfiguratorConfigurationModel, _, __, value: Material): void => {
            ccm.material = value
          }
        }
      case 'seeklimatauglich':
        return {
          get: (ccm: ConfiguratorConfigurationModel): boolean => ccm.configuratedDoor.Seeklimatauglich,
          set: (ccm: ConfiguratorConfigurationModel, _, __, value: boolean): void => {
            ccm.configuratedDoor.Seeklimatauglich = value
          }
        }
      case 'sicherheitspaket':
        return {
          get: (ccm: ConfiguratorConfigurationModel): string => ccm.configuratedDoor.sicherheitsPaket.Key,
          set: (ccm: ConfiguratorConfigurationModel, _, __, value: string): void => {
            ccm.configuratedDoor.sicherheitsPaket = ccm.selectedComponent.model
              .getPackages({material: ccm.selectedComponent.material, type: PackageType.Security})
              .find((x): boolean => x.Key === value)
            ccm.selectedComponent.glasaufbau.setSicherheitsGlaeser()
          }
        }
      case 'werksmontage':
        return {
          get: (ccm: ConfiguratorConfigurationModel): boolean => ccm.configuratedDoor.Werksmontage,
          set: (ccm: ConfiguratorConfigurationModel, _,  __, value: boolean): void => {
            ccm.configuratedDoor.Werksmontage = value
          }
        }
      case 'wärmeschutzpaket':
      case 'waermeschutzpaket':
        return {
          get: (ccm: ConfiguratorConfigurationModel): string => ccm.configuratedDoor.waermeschutzPaket.Key,
          set: (ccm: ConfiguratorConfigurationModel, _, __, value: string): void => {
            ccm.configuratedDoor.waermeschutzPaket = ccm.selectedComponent.model
              .getPackages({material: ccm.selectedComponent.material, type: PackageType.ThermalInsulation})
              .find((x): boolean => x.Key === value)
            ccm.selectedComponent.glasaufbau.setWaermeschutzGlaeser()
          }
        }
      case 'z-maß':
      case 'zmaß':
      case 'z-mass':
      case 'zmass':
        return {
          get: (ccm: ConfiguratorConfigurationModel): number => ccm.configuratedDoor.selectedComponent.Zmass,
          set: (ccm: ConfiguratorConfigurationModel, _, __, value: string): void => {
            ccm.configuratedDoor.Components.forEach((c): void => {
              c.Zmass = Number.parseFloat(value)
              ccm.zMassChanged.emit()
            })
          }
        }
      default:
        this.logger.error('Unknown workflow selector key "' + selectorParts[0] + '" in "basis"!')
        return this.getUndefinedAccessor(true)
    }
  }

  private buildCoverLayerAccessor(selectorParts: string[]): WorkflowAccessor<unknown> {
    if (selectorParts.length > 2) {
      this.logger.warn('Too many workflow selector child keys in "deckschichten"!', selectorParts)
    }
    let side: keyof ConstructionComponent['Deckschichten']

    switch (selectorParts[0]?.toLowerCase()) {
      case 'innen':
        side = 'Inside'
        break
      case 'aussen':
      case 'außen':
        side = 'Outside'
        break
      default:
        this.logger.error('Unknown workflow selector key "' + selectorParts[0] + '" in "deckschichten"!')
        return this.getUndefinedAccessor(false)
    }

    switch (selectorParts[1]?.toLowerCase()) {
      case 'oberflächentyp':
      case 'oberflaechentyp':
        return {
          get: (ccm: ConfiguratorConfigurationModel): string =>
            ccm.selectedComponent.Deckschichten[side].find((): true => true).OberflaechenTyp
        }
      default:
        this.logger.error(`Unknown workflow selector key "${selectorParts[1]}" in "deckschichten.${selectorParts[0]}"!`)
        return this.getUndefinedAccessor(false)
    }
  }

  private buildGlassesAccessor(selectorParts: string[]): WorkflowAccessor<unknown> {
    if (selectorParts.length > 1) {
      this.logger.warn('Too many workflow selector child keys in "gläser"!', selectorParts)
    }
    switch (selectorParts[0]?.toLowerCase()) {
      case 'anzahl':
        return {
          get: (ccm: ConfiguratorConfigurationModel): number =>
            ccm.configuratedDoor.selectedComponent.glasaufbau.numGlasses,
          set: (ccm: ConfiguratorConfigurationModel, cdm: ConfiguratorDataModel, _, value: unknown): void => {
            const requestedAmountGlasses = parseInt(String(value), 10)
            if (requestedAmountGlasses !== 2 && requestedAmountGlasses !== 3 && requestedAmountGlasses !== 4) {
              this.logger.error(
                `Wrong value '${JSON.stringify(value)}' on key "gläser.anzahl", ` +
                'only the values \'2\', \'3\' and \'4\' are permitted!'
              )
              return
            }
            const selectorAmountMapping = {
              mitte1: 3,
              mitte2: 4
            } as const satisfies Record<Exclude<GlasaufbauPositionShort, 'aussen' | 'innen'>, Glasaufbau['numGlasses']>
            ccm.configuratedDoor.Components.forEach((component): void => {
              for (const selector in selectorAmountMapping) {
                if (selectorAmountMapping.hasOwnProperty(selector)) {
                  if (selectorAmountMapping[selector] <= requestedAmountGlasses) {
                    component?.glasaufbau
                      ?.setGlas(selector as keyof typeof selectorAmountMapping, cdm.getDefaultMiddleGlass())
                  } else {
                    component?.glasaufbau?.unsetGlas(selector as keyof typeof selectorAmountMapping)
                  }
                }
              }
            })
          }
        }
      default:
        this.logger.error('Unknown workflow selector key "' + selectorParts[0] + '" in "gläser"!')
        return this.getUndefinedAccessor(true)
    }
  }

  private buildMehrpreiseAccessor(selectorParts: string[] & [string]): WorkflowAccessor<unknown> {
    const mehrpreisePredicates = this.buildPredicates(selectorParts[0], this.isSupportedMehrpreisKey, 'mehrpeise')

    const mehrpreisCreator = <T>(
      selectorPart: string,
      isFilterKeyValid: ((key: unknown) => key is keyof T),
      workflowKey?: string
    ): Partial<T> => {
      const valueMap: Partial<T> = {}
      selectorPart.match(WorkflowParser.filterFinder)?.forEach((encodedFilter): void => {
        const filterParts = encodedFilter.match(WorkflowParser.filterSplitter)
        const key = filterParts.groups.key
        if (isFilterKeyValid(key)) {
          if (filterParts.groups.value === undefined) {
            valueMap[key] = undefined
          } else {
            valueMap[key] = filterParts.groups.value.toLowerCase() as T[typeof key]
          }
        } else {
          this.logger.warn(
            'Unknown workflow filter key "' + key
            + (typeof workflowKey !== 'undefined' ? '" on "' + workflowKey + '' : '')
            + '"!'
          )
        }
      })
      return valueMap
    }

    const mehrpreisAccessor = (ccm: ConfiguratorConfigurationModel): MehrpreisEntry[] =>
      mehrpreisePredicates.reduce<MehrpreisEntry[]>(
        (acc, curr): MehrpreisEntry[] => acc.filter(curr),
        ccm.selectedComponent.Mehrpreise
      )

    if (selectorParts.length === 1) {
      return {
        get: mehrpreisAccessor
      }
    }

    const childSelectorPartLc = selectorParts[1].toLowerCase()

    switch (childSelectorPartLc) {
      case 'item':
        return {
          get: (ccm): Zubehoer =>
            mehrpreisAccessor(ccm)?.[0]?.Item
        }
    }

    if (childSelectorPartLc.startsWith('dienstleistung')) {
      const addonPredicates = this.buildPredicates(selectorParts[1], this.isSupportedDienstleistungKey, 'dienstleistung')

      const addonAccessor = (ccm: ConfiguratorConfigurationModel): ZubehoerAddonEntry[] =>
        addonPredicates.reduce(
          (acc, curr): ZubehoerAddonEntry[] => acc.filter(curr),
          mehrpreisAccessor(ccm).reduce(
            (acc, curr): ZubehoerAddonEntry[] => acc.concat(curr?.addons || []),
            [] as ZubehoerAddonEntry[]
          )
        )

      const dienstleistungAccessor =
        (ccm: ConfiguratorConfigurationModel): [MehrpreisEntry, Dienstleistung][] =>
          mehrpreisAccessor(ccm).flatMap((zubehoer: MehrpreisEntry): [MehrpreisEntry, Dienstleistung][] =>
            addonPredicates.reduce(
              (acc, curr): Dienstleistung[] => acc.filter(curr),
              ccm.selectedComponent.model.Mehrpreise.find((m): boolean => zubehoer.Typ === m.Typ)
                ?.Dienstleistungen
            ).map(
              (dienstleistung): [MehrpreisEntry, Dienstleistung] => [zubehoer, dienstleistung]
            )
          )


      const castBool = (value: unknown): boolean =>
        typeof value === 'boolean'
          ? value
          : typeof value === 'string'
            ? value.toLowerCase() === 'true'
            : !!value

      if (selectorParts.length === 2) {
        return {
          get: addonAccessor,
          set: async (
            ccm: ConfiguratorConfigurationModel,
            _,
            __,
            value: unknown
          ): Promise<void> => {
            let zubehoerEntry: MehrpreisEntry
            let updateMassblaetter = false
            value = castBool(value)
            if (mehrpreisAccessor(ccm).length === 0) {
              const zuebehoerData = mehrpreisCreator(selectorParts[0], this.isSupportedMehrpreisKey, 'mehrpeise')
              if (typeof zuebehoerData.Typ === 'string' && zuebehoerData.Typ !== '') {
                zubehoerEntry = ccm.selectedComponent.getOrCreateZubehoerEntry(zuebehoerData.Typ)
              }
            }
            dienstleistungAccessor(ccm).forEach(([zubehoer, dienstleistung]): void => {
              const setAddon = zubehoer.getAddon(dienstleistung.Typ)
              const isAddonSet = typeof setAddon !== 'undefined'
              if (isAddonSet && !value) {
                zubehoer.removeAddon(setAddon)
              } else if (!isAddonSet && value) {
                zubehoer.addAddon(ZubehoerAddonEntry.fromDienstleistung(dienstleistung))
                updateMassblaetter = true
              }
            })
            ccm.selectedComponent.clearZubehoerEntryIfEmpty(zubehoerEntry)
            if (updateMassblaetter) {
              await this.debounceMassblattUpdate(ccm)
            }
          }
        }
      }

      if (
        selectorParts[2].toLowerCase().startsWith('maßblatt')
        || selectorParts[2].toLowerCase().startsWith('massblatt')
      ) {
        const massblattPredicates = this.buildPredicates(selectorParts[2], this.isSupportedMassblattKey, 'massblatt')

        const massblattAccessor = (ccm: ConfiguratorConfigurationModel): Massblatt[] =>
          massblattPredicates.reduce(
            (acc, curr): Massblatt[] => acc.filter(curr),
            addonAccessor(ccm).reduce(
              (acc, curr): Massblatt[] =>
                acc.concat([curr?.Massblatt].filter((m): boolean => typeof m !== 'undefined')),
              [] as Massblatt[]
            )
          )

        const updateMassblatt = async (
          ccm: ConfiguratorConfigurationModel,
          values: Massblatt['Values']
        ): Promise<void> => {
          await Promise.all(
            dienstleistungAccessor(ccm).map(
              async ([zubehoer, dienstleistung]): Promise<void> => {
                const addon = zubehoer.getAddon(dienstleistung.Typ)
                const massblatt = massblattPredicates.reduce(
                  (acc, curr): Massblatt[] => acc.filter(curr),
                  dienstleistung.Massblaetter ?? []
                )?.[0]
                if (addon && massblatt) {
                  addon.Massblatt = massblatt
                  await this.debounceMassblattUpdate(ccm).then((): void => {
                    if (Object.getOwnPropertyNames(values).length > 0) {
                      massblatt.updateValues(values)
                    }
                  })
                }
              }
            )
          )
        }

        if (selectorParts.length === 3) {
          const parseValues = (value: unknown): Massblatt['Values'] | false => {
            const values: Massblatt['Values'] = {}
            if (typeof value !== 'boolean') {
              let parsedValue: unknown
              if (typeof value === 'object') {
                parsedValue = value
              } else {
                if (typeof value !== 'string') {
                  this.logger.error('Only json string values are permitted on key "mehrpreise[...].dienstleistung[...].massblatt[...]"')
                  return false
                }
                try {
                  parsedValue = JSON.parse(value)
                } catch (e) {
                  this.logger.error('Only json string values are permitted on key "mehrpreise[...].dienstleistung[...].massblatt[...]"')
                  return false
                }
              }
              if (typeof parsedValue === 'object') {
                for (const key in parsedValue) {
                  if (parsedValue.hasOwnProperty(key)) {
                    const numValue: unknown = parsedValue[key]
                    if (typeof key === 'string' && typeof numValue === 'number') {
                      values[key] = numValue
                    } else {
                      if (typeof key !== 'string') {
                        this.logger.warn(
                          `Wrong type '${typeof key}' for object key which should be 'string' on workflow key` +
                          '"mehrpreise[...].dienstleistung[...].massblatt[...]"',
                          {
                            key,
                            parsedObject: parsedValue,
                            rawValue: value
                          }
                        )
                      }
                      if (typeof numValue !== 'number') {
                        this.logger.warn(
                          `Wrong type '${typeof numValue}' for object value which should be 'number' on workflow` +
                          ' key "mehrpreise[...].dienstleistung[...].massblatt[...]"',
                          {
                            key,
                            value: numValue,
                            parsedObject: parsedValue,
                            rawValue: value
                          }
                        )
                      }
                    }
                  }
                }
              } else if (typeof parsedValue !== 'boolean') {
                this.logger.error(
                  'Only json encoded object and boolean values are permitted on key ' +
                  '"mehrpreise[...].dienstleistung[...].massblatt[...]"'
                )
                return false
              }
            }
            return values
          }
          return {
            get: massblattAccessor,
            set: (
              ccm: ConfiguratorConfigurationModel,
              _,
              __,
              value: unknown
            ): Promise<void> => {
              const values: Massblatt['Values'] | false = parseValues(value)
              if (values !== false) {
                return updateMassblatt(ccm, values)
              }
            }
          }
        }

        if (selectorParts.length === 4) {
          return {
            get: (ccm: ConfiguratorConfigurationModel): number =>
              massblattAccessor(ccm)
                .find((value): boolean => value.Values.hasOwnProperty(selectorParts[3]))
                ?.Values?.[selectorParts[3]],
            set: (
              ccm: ConfiguratorConfigurationModel,
              _,
              __,
              value: unknown
            ): Promise<void> => {
              if (typeof value !== 'number') {
                this.logger.error(
                  `Wrong type '${typeof value}' for object value which should be 'number' on workflow` +
                  ` key "mehrpreise[...].dienstleistung[...].massblatt[...].${selectorParts[3]}"`,
                  {
                    key: selectorParts[3],
                    value,
                  }
                )
                return
              }

              return updateMassblatt(ccm, {[selectorParts[3]]: value})
            }
          }
        }
      }
    }

    this.logger.error('Unknown workflow selector key "' + selectorParts[1] + '" in "mehrpreise"!')
    return this.getUndefinedAccessor(true)
  }

  private buildParameterAccessor(selectorParts: string[]): WorkflowAccessor<unknown> {
    switch (selectorParts[0].toLowerCase()) {
      case 'profil':
        return {
          get: (_, __, ps: ParameterService): string => ps.parameter.profil
        }
      case 'software':
        return {
          get: (_, __, ps: ParameterService): string => ps.parameter.software
        }
      default:
        this.logger.error('Unknown workflow selector key "' + selectorParts[0] + '" in "parameter"!')
        return this.getUndefinedAccessor(false)
    }
  }

  private buildParameterModelAccessor(selectorParts: string[]): WorkflowAccessor<unknown> {
    switch (selectorParts[0]?.toLowerCase()) {
      case 'farbefüllung':
      case 'farbefuellung':
        return {
          get: (_, __, ps: ParameterService): string => ps.modelle[0]?.FarbeFuellung
        }
      case 'farbefüllunginnen':
      case 'farbefuellunginnen':
      case 'farbefüllung_innen':
      case 'farbefuellung_innen':
        return {
          get: (_, __, ps: ParameterService): string => ps.modelle[0]?.FarbeFuellungInnen
        }
      case 'farberahmen':
        return {
          get: (_, __, ps: ParameterService): string => ps.modelle[0]?.FarbeRahmen
        }
      case 'farberahmen_innen':
        return {
          get: (_, __, ps: ParameterService): string => ps.modelle[0]?.FarbeRahmenInnen
        }
      case 'fingerprint':
      case 'fingerprintfräsung':
      case 'fingerprintfraesung':
      case 'fingerprint_fräsung':
      case 'fingerprint_fraesung':
        return this.buildParameterModelFingerprintAccessor(selectorParts.slice(1))
      case 'griffposition':
      case 'griff_position':
        return this.buildParameterModelHandlePositionAccessor(selectorParts.slice(1))
      case 'griffverstärkung':
      case 'griffverstaerkung':
      case 'griff_verstärkung':
      case 'griff_verstaerkung':
        return this.buildParameterModelHandleStrengtheningAccessor(selectorParts.slice(1))
      case 'pz_position':
      case 'pzposition':
        return this.buildParameterModelPzPositionAccessor(selectorParts.slice(1))
      case 'seitenteilhöhenverteilung':
      case 'seitenteilhoehenverteilung':
      case 'sthöhenverteilung':
      case 'sthoehenverteilung':
      case 'st_höhen_verteilung':
      case 'st_hoehen_verteilung':
      case 'seitenteil_höhen_verteilung':
      case 'seitenteil_hoehen_verteilung':
        return this.buildParameterModelSidePanelHeightDistributionAccessor(selectorParts.slice(1))
      default:
        this.logger.error('Unknown workflow selector key "' + selectorParts[0] + '" in "parameter.modelle"!')
        return this.getUndefinedAccessor(false)
    }
  }

  private buildParameterModelFingerprintAccessor(selectorParts: string[]): WorkflowAccessor<unknown> {
    const selector = selectorParts[0]?.toLowerCase()
    switch (selector) {
      case 'b':
      case 'h':
      case 'r':
      case 't':
      case 'v':
        return {
          get: (_, __, ps: ParameterService): number => ps.modelle[0]?.fingerprintFraesung?.[selector]
        }
      case 'aktiv':
        return {
          get: (_, __, ps: ParameterService): boolean => ps.modelle[0]?.fingerprintFraesung?.aktiv
        }
      case undefined:
        return {
          get: (_, __, ps: ParameterService): object => ps.modelle[0]?.fingerprintFraesung
        }
      default:
        this.logger.error('Unknown workflow selector key "' + selectorParts[0] + '" in "parameter.modelle.fingerprint"!')
        return this.getUndefinedAccessor(false)
    }
  }

  private buildParameterModelHandlePositionAccessor(selectorParts: string[]): WorkflowAccessor<unknown> {
    const selector = selectorParts[0]?.toLowerCase()
    switch (selector) {
      case 's':
      case 't':
        return {
          get: (_, __, ps: ParameterService): number => ps.modelle[0]?.griffPosition?.[selector]
        }
      case 'aktiv':
        return {
          get: (_, __, ps: ParameterService): boolean => ps.modelle[0]?.griffPosition?.aktiv
        }
      case undefined:
        return {
          get: (_, __, ps: ParameterService): object => ps.modelle[0]?.griffPosition
        }
      default:
        this.logger.error('Unknown workflow selector key "' + selectorParts[0] + '" in "parameter.modelle.griff_position"!')
        return this.getUndefinedAccessor(false)
    }
  }

  private buildParameterModelHandleStrengtheningAccessor(selectorParts: string[]): WorkflowAccessor<unknown> {
    const selector = selectorParts[0]?.toLowerCase()
    switch (selector) {
      case 'ga':
      case 'gb':
      case 'gd':
        return {
          get: (_, __, ps: ParameterService): number => ps.modelle[0]?.griffVerstaerkung?.[selector]
        }
      case 'aktiv':
        return {
          get: (_, __, ps: ParameterService): boolean => ps.modelle[0]?.griffVerstaerkung?.aktiv
        }
      case undefined:
        return {
          get: (_, __, ps: ParameterService): object => ps.modelle[0]?.griffVerstaerkung
        }
      default:
        this.logger.error('Unknown workflow selector key "' + selectorParts[0] + '" in "parameter.modelle.griff_verstaerkung"!')
        return this.getUndefinedAccessor(false)
    }
  }

  private buildParameterModelPzPositionAccessor(selectorParts: string[]): WorkflowAccessor<unknown> {
    const selector = selectorParts[0]?.toLowerCase()
    switch (selector) {
      case 'd':
      case 'v':
      case 'w':
        return {
          get: (_, __, ps: ParameterService): number => ps.modelle[0]?.pzPosition?.[selector]
        }
      case 'aktiv':
        return {
          get: (_, __, ps: ParameterService): boolean => ps.modelle[0]?.pzPosition?.aktiv
        }
      case 'typ':
        return {
          get: (_, __, ps: ParameterService): string => ps.modelle[0]?.pzPosition?.typ
        }
      case undefined:
        return {
          get: (_, __, ps: ParameterService): object => ps.modelle[0]?.pzPosition
        }
      default:
        this.logger.error('Unknown workflow selector key "' + selectorParts[0] + '" in "parameter.modelle.pz_position"!')
        return this.getUndefinedAccessor(false)
    }
  }

  private buildParameterModelSidePanelHeightDistributionAccessor(selectorParts: string[]): WorkflowAccessor<unknown> {
    const selector = selectorParts[0]?.toLowerCase()
    switch (selector) {
      case 'a':
      case 'b':
        return {
          get: (_, __, ps: ParameterService): number => ps.modelle[0]?.seitenteilHoehenverteilung?.[selector]
        }
      case 'aktiv':
        return {
          get: (_, __, ps: ParameterService): boolean => ps.modelle[0]?.seitenteilHoehenverteilung?.aktiv
        }
      case undefined:
        return {
          get: (_, __, ps: ParameterService): object => ps.modelle[0]?.seitenteilHoehenverteilung
        }
      default:
        this.logger.error('Unknown workflow selector key "' + selectorParts[0] + '" in "parameter.modelle.seitenteil_hoehen_verteilung"!')
        return this.getUndefinedAccessor(false)
    }
  }

  private buildPredicates<T>(
    selectorPart: string,
    isFilterKeyValid: ((key: unknown) => key is keyof T),
    workflowKey?: string
  ): ((t: T) => boolean)[] {
    const predicates: ((t: T) => boolean)[] = []
    selectorPart.match(WorkflowParser.filterFinder)?.forEach((encodedFilter): void => {
      const filterParts = encodedFilter.match(WorkflowParser.filterSplitter)
      const key = filterParts.groups.key
      if (isFilterKeyValid(key)) {
        if (filterParts.groups.value === undefined) {
          // which undefined logic to use here? 'in', 'typeof undefined', 'hasOwnProperty'
          predicates.push((m: T): boolean => typeof m[key] !== 'undefined')
        } else {
          predicates.push(
            (m: T): boolean => String(m[key]).toLowerCase() === filterParts.groups.value.toLowerCase()
          )
        }
      } else {
        this.logger.warn(
          'Unknown workflow filter key "' + key
          + (typeof workflowKey !== 'undefined' ? '" on "' + workflowKey + '' : '' )
          + '"!'
        )
      }
    })
    return predicates
  }

  private debounceMassblattUpdate(ccm: ConfiguratorConfigurationModel): Promise<void> {
    const doUpdate = async (resolvers: MassblattUpdateDebounceData['resolver']): Promise<void> => {
      try {
        await ccm.updateMassblaetter()
      } finally {
        resolvers.forEach((resolve): void => resolve())
      }
    }
    let debounceData: MassblattUpdateDebounceData = {
      resolver: [],
      timerId: null
    }
    if (this.ccmMassblattDebounceMap.has(ccm)) {
      debounceData = this.ccmMassblattDebounceMap.get(ccm)
      clearTimeout(debounceData.timerId)
      this.ccmMassblattDebounceMap.delete(ccm)
    } else {
      this.ccmMassblattDebounceMap.set(ccm, debounceData)
    }
    if (this.massblattUpdateDebounceTimeMs >= 0) {
      debounceData.timerId = setTimeout(
        async (): Promise<void> => {
          const resolvers = this.ccmMassblattDebounceMap.get(ccm)?.resolver || debounceData.resolver || []
          this.ccmMassblattDebounceMap.delete(ccm)
          await doUpdate(resolvers)
        },
        this.massblattUpdateDebounceTimeMs
      )
      this.ccmMassblattDebounceMap.set(ccm, debounceData)
      return new Promise((resolve): void => {
        debounceData.resolver.push(resolve)
      })

    }
    return doUpdate(debounceData.resolver)
  }

  private getUndefinedAccessor(includeSetter: boolean): WorkflowAccessor<unknown> {
    return includeSetter
      ? {
        get: (): undefined => undefined,
        set: (): void => {
        }
      }
      : {
        get: (): undefined => undefined
      }
  }


  private isSupportedDienstleistungKey(this: void, key: unknown): key is (keyof ZubehoerAddonEntry & keyof Dienstleistung) {
    return typeof key === 'string' && supportedDienstleistungKeys.includes(key)
  }

  private isSupportedMassblattKey(this: void, key: unknown): key is (keyof Massblatt) {
    return typeof key === 'string' && supportedMassblattKeys.includes(key)
  }

  private isSupportedMehrpreisKey(this: void, key: unknown): key is keyof MehrpreisEntry {
    return typeof key === 'string' && supportedMehrpreisKeys.includes(key)
  }
}

type MassblattUpdateDebounceData<T = unknown> = {
  resolver: ((value?: T) => void)[]
  timerId: ReturnType<typeof setTimeout> | null
}
