<configurator-base-menu
  [isMobile]='isMobile'
  [menuHead]='head'
  menuClass='hausfront'
  menuNavigatorIdentifier='Hausfront'
>
  <div class='container'>
    <div class='container-inner'>

      <article (click)='newHausfront()' class='milieu upload'>
        <div class='iconWrapper'>
          <i class='icon-facade'></i>
          <p>{{ TRANSLATION_KEY.USE_CUSTOM_MILIEU | translate }}</p>
        </div>
      </article>

      <ng-container *ngFor='let m of this.hausfronten'>
        <ng-template
          *ngIf='m.IsAussen && this.view === SideType.Outside || m.IsInnen && this.view === SideType.Inside'
          [ngTemplateOutletContext]='{item: m}'
          [ngTemplateOutlet]='hausfront'
        >
        </ng-template>
      </ng-container>
    </div>
  </div>
</configurator-base-menu>

<ng-template #head>
  <div class='name'>
    <span>{{ TRANSLATION_KEY.TITLE | translate }}</span>
    <span (click)="navigatorService.setShowToggle('')" class='close'></span>
  </div>
</ng-template>

<ng-template #hausfront let-hausfront='item'>
  <article
    (click)='checkHausfrontReset(hausfront, this.view)'
    [class.active]='hausfront.Id === currentHausfronten?.[this.view]?.Id'
    class='milieu'
  >

    <ng-container *ngIf='hausfront.Custom'>
      <div (click)='deleteCustomHausfront(hausfront)' class='delete'>
        <i class='fa fa-times'></i>
      </div>
      <div (click)='editCustomHausfront(hausfront)' class='edit'>
        <i class='fa fa-wrench'></i>
      </div>
    </ng-container>


    <div class='image-wrapper'>
      <div *ngIf='!hausfront.loaded' class='icon loading-image-spinner'>
        <i class='fa fa-spinner fa-pulse fa-3x fa-fw'></i>
      </div>
      <img
        #hausfrontPreviewImage (load)='this.setMiniDoorPosition();lazyImageLoaded(hausfront)'
        [alt]='hausfront.Bezeichnung'
        [attr.data-bgHeight]='hausfront.BildPxHoehe'
        [attr.data-bgWidth]='hausfront.BildPxBreite'
        [attr.data-elementHeight]='hausfront.ElementHoehe'
        [attr.data-elementWidth]='hausfront.ElementBreite'
        [attr.data-x1]='hausfront.X1'
        [attr.data-x2]='hausfront.X2'
        [attr.data-y1]='hausfront.Y1'
        [attr.data-y3]='hausfront.Y3'
        [lazyLoad]='hausfront.PreviewUrl'
        [offset]='0'

      >
      <ng-container *ngIf='this.configuratedDoor && !hausfront.Custom && this.configuratedDoor?.Images?.[this.view]'>
        <img [class.hidden]='this.loadingBackground' [src]='this.configuratedDoor?.Images?.[this.view]' class='miniDoor'>
      </ng-container>
    </div>
  </article>
</ng-template>
