import {FbsGlas, Konstruktion, Motiv} from '../../../../class'
import {Konfiguration} from '../../../konfiguration'
import {Sprossen} from '../glassaufbau/sprossen'
import {Option} from '../other/option'
import {Pulver} from '../../color/pulver'
import {Folie} from '../../color/folie'
import {Lack} from '../../color/lack'
import {Blendrahmen} from '../frame/blendrahmen'
import {Fluegelrahmen} from '../frame/fluegelrahmen'
import {InsideOutsideObject, OptionType, SideType} from '../../../../types'
import {PackageType, Paket} from '../other/paket'
import {fbsMehrpreis} from '../extras/mehrpreis/fbsMehrpreis'
import {Zubehoer} from '../extras/zubehoer/zubehoer'
import {Material} from '../../material'
import {Dienstleistung} from '../extras/mehrpreis/dienstleistung'
import {FbsModelTyp} from './component-model-type.enum'
import {CompactSkylight} from '../../../api/model/component/compact-skylight.interface'
import {CompactSidepanel} from '../../../api/model/component/compact-sidepanel.interface'
import {ModelLoadResponse} from '../../../api/model/model-load-response.interface'
import {CompactComponentModel} from '../../../api/model/compact-component-model.interface'
import {AdhesiveSystem} from '../../../api/model/adhesive/adhesive-system.interface'
import {SimpleConstruction, SingleConstruction} from '../other/construction'
import {ZubehoerCategory, ZubehoerCategoryIdentifier, ZubehoerCategoryName} from '../extras/zubehoer/zubehoer.type'
import {DesignOberflaeche} from '../../color/design'
import {MenuConfig} from './MenuConfig'
import {ModelRungs} from '../../../api/model/rungs/model-rungs.interface'
import {ModelGlass} from '../../../api/model/glass/model-glass.interface'
import {ModelMehrpreis} from '../../../api/model/addons/model-mehrpreis.interface'
import {Powder} from '../../../api/model/color/powder.interface'
import {ModelOption} from '../../../api/model/addons/model-option.interface'
import {Foil} from '../../../api/model/color/foil.interface'

export class ComponentModel implements CompactComponentModel {
  Aktion: string
  Bezeichnung: string
  Blendrahmen: Blendrahmen[]
  DesignOberflaechen: DesignOberflaeche[]
  Farben: Lack[] = []
  Fluegelrahmen: Fluegelrahmen[]
  Folien: Folie[] = []
  Glasscheiben: FbsGlas[] = []
  Hinweise: string[]
  Hoehenverteilung: number
  Id: number
  IsAluMoeglich: boolean
  IsAufsatzBeidseitigMoeglich: boolean
  IsAufsatzEinseitigMoeglich: boolean
  IsEinsatzMoeglich: boolean
  IsGlasMoeglich: boolean
  IsKunststoffMoeglich: boolean
  IsVerglas: boolean
  Katalog: string
  KatalogId: number
  Klebesysteme: AdhesiveSystem[] = []
  Konfiguration: Konfiguration
  Konstruktionen: Konstruktion[] = []
  MaxBreiteAufsatzAluminium: number
  MaxBreiteAufsatzKunststoff: number
  MaxBreiteEinsatzAluminium: number
  MaxBreiteEinsatzKunststoff: number
  MaxHoeheAufsatzAluminium: number
  MaxHoeheAufsatzKunststoff: number
  MaxHoeheEinsatzAluminium: number
  MaxHoeheEinsatzKunststoff: number
  Mehrpreise: fbsMehrpreis[] = []
  MenueEinstellungen: MenuConfig
  MinBreiteAufsatz: number
  MinBreiteEinsatz: number
  MinHoeheAufsatz: number
  MinHoeheEinsatz: number
  MinStaerkeAufsatzBeidseitig: number
  MinStaerkeAufsatzEinseitig: number
  MinStaerkeEinsatz: number
  ModellId: number
  Motive: Motiv[] = []
  Oberlichter: CompactSkylight[]
  Optionen: Option[] = []
  Pakete: Paket[] = []
  PreviewGuid: string
  PreviewImageUrl: string
  Pulver: Pulver[] = []
  Querfries: number
  Seitenteile: CompactSidepanel[]
  Sort: string
  Sprossen: Sprossen[] = []
  Typ: FbsModelTyp
  Zubehoer: Zubehoer[] = []
  private _orderedOptions: InsideOutsideObject<{ [key: OptionType]: Option[] }>
  disabilityReasons: string[]
  loaded: boolean
  private zubehoerCategories: readonly ZubehoerCategory[]

  constructor(data?: ModelLoadResponse | ComponentModel) {
    this.Sort = data && data.Sort
    this.PreviewGuid = data && data.PreviewGuid
    this.PreviewImageUrl = data && data.PreviewImageUrl
    this.Querfries = data && data.Querfries || 0
    this.Hinweise = []
    data?.Hinweise?.forEach( (s: string): void => {
      this.Hinweise.push(s)
    })
    this.Hoehenverteilung = data instanceof Object && 'Hoehenverteilung' in data && data.Hoehenverteilung || 0
    this.IsAluMoeglich = data && data.IsAluMoeglich
    this.IsGlasMoeglich = data && data.IsGlasMoeglich
    this.IsKunststoffMoeglich = data && data.IsKunststoffMoeglich
    this.Klebesysteme = data && data.Klebesysteme
    this.KatalogId = data && data.KatalogId
    this.Katalog = data && data.Katalog
    this.Bezeichnung = data && data.Bezeichnung
    this.Id = data && data.Id
    this.ModellId = data && data.ModellId
    this.Blendrahmen = []
    if (Array.isArray(data?.Blendrahmen)) {
      data.Blendrahmen.forEach(item => void this.Blendrahmen.push(new Blendrahmen(item)))
    }
    this.Konfiguration = data && data.Konfiguration && new Konfiguration(data.Konfiguration)
    this.MaxBreiteAufsatzAluminium = data && data.MaxBreiteAufsatzAluminium
    this.MaxBreiteAufsatzKunststoff = data && data.MaxBreiteAufsatzKunststoff
    this.MaxBreiteEinsatzAluminium = data && data.MaxBreiteEinsatzAluminium
    this.MaxBreiteEinsatzKunststoff = data && data.MaxBreiteEinsatzKunststoff
    this.MaxHoeheAufsatzAluminium = data && data.MaxHoeheAufsatzAluminium
    this.MaxHoeheAufsatzKunststoff = data && data.MaxHoeheAufsatzKunststoff
    this.MaxHoeheEinsatzAluminium = data && data.MaxHoeheEinsatzAluminium
    this.MaxHoeheEinsatzKunststoff = data && data.MaxHoeheEinsatzKunststoff
    this.MinBreiteAufsatz = data && data.MinBreiteAufsatz
    this.MinBreiteEinsatz = data && data.MinBreiteEinsatz
    this.MinHoeheAufsatz = data && data.MinHoeheAufsatz
    this.MinHoeheEinsatz = data && data.MinHoeheEinsatz
    this.MinStaerkeAufsatzBeidseitig = data && data.MinStaerkeAufsatzBeidseitig
    this.MinStaerkeAufsatzEinseitig = data && data.MinStaerkeAufsatzEinseitig
    this.MinStaerkeEinsatz = data && data.MinStaerkeEinsatz
    this.MenueEinstellungen = data && new MenuConfig(data.MenueEinstellungen)
    this.IsAufsatzBeidseitigMoeglich = data && data.IsAufsatzBeidseitigMoeglich
    this.IsAufsatzEinseitigMoeglich = data && data.IsAufsatzEinseitigMoeglich
    this.IsEinsatzMoeglich = data && data.IsEinsatzMoeglich
    this.IsVerglas = data && data.IsVerglas
    this.Aktion = data && data.Aktion
    this.Typ = data && data.Typ
    this.Seitenteile = []
    this.Seitenteile = data?.Seitenteile ?? []
    this.Oberlichter = data?.Oberlichter ?? []
    this.Fluegelrahmen = []
    if (Array.isArray(data?.Fluegelrahmen)) {
      data.Fluegelrahmen.forEach(r => void this.Fluegelrahmen.push(new Fluegelrahmen(r)))
    }
    this.DesignOberflaechen = []
    if (Array.isArray(data?.DesignOberflaechen)) {
      data.DesignOberflaechen.forEach(d => void this.DesignOberflaechen.push(new DesignOberflaeche(d)))
    }
    this.Mehrpreise = []
    if (Array.isArray(data?.Mehrpreise)) {
      data.Mehrpreise.forEach((m: fbsMehrpreis | ModelMehrpreis) => void this.Mehrpreise.push(new fbsMehrpreis(m)))
    }
    this.Pakete = []
    if (Array.isArray(data?.Pakete)) {
      data.Pakete.forEach((item: Partial<Paket>) => void this.Pakete.push(new Paket(item)))
    }
    this.Sprossen = []
    if (Array.isArray(data?.Sprossen)) {
      data.Sprossen.forEach((item: ModelRungs | Sprossen) => void this.Sprossen.push(new Sprossen(item)))
    }
    this.Optionen = []
    if (Array.isArray(data?.Optionen)) {
      data.Optionen.forEach((item: ModelOption | Option) => void this.Optionen.push(new Option(item)))
    }

    const zubehoerCategory: { [key in ZubehoerCategoryIdentifier]: ZubehoerCategoryName } = {}

    this.zubehoerCategories =[]
    this.Zubehoer = []
    if (Array.isArray(data?.Zubehoer)) {
      data.Zubehoer.forEach((item): void => {
        this.Zubehoer.push(new Zubehoer(item))
        zubehoerCategory[item.Typ] ??= Zubehoer.getCategoryName(item.Typ)
      })
      this.zubehoerCategories = Object.getOwnPropertyNames(zubehoerCategory)
        .map((key): [ZubehoerCategoryIdentifier, ZubehoerCategoryName] => [key, zubehoerCategory[key]])
        .sort((a, b): number => -(a[1] < b[1]) + (+(a[1] > b[1])))
    }
    if (Array.isArray(data?.Konstruktionen)) {
      data.Konstruktionen.forEach(item => void this.Konstruktionen.push(new Konstruktion(item)))
    }
    if (Array.isArray(data?.Glasscheiben)) {
      data.Glasscheiben.forEach((item: ModelGlass | FbsGlas) => void this.Glasscheiben.push(new FbsGlas(item)))
    }
    if (Array.isArray(data?.Motive)) {
      data.Motive.forEach(item => void this.Motive.push(new Motiv(item)))
    }
    if (Array.isArray(data?.Farben)) {
      data.Farben.forEach(i => void this.Farben.push(new Lack(i)))
    }
    if (Array.isArray(data?.Folien)) {
      data.Folien.forEach((i: Folie | Foil) => void this.Folien.push(new Folie(i)))
    }
    if (Array.isArray(data?.Pulver)) {
      data.Pulver.forEach((i: Pulver | Powder) => void this.Pulver.push(new Pulver(i)))
    }
    this.loaded = false
  }

  constructionPossible(construction: SingleConstruction, side: SideType): boolean {
    switch (construction){
      case SingleConstruction.Einsatz:
        if (this.IsEinsatzMoeglich){
          return true
        }
        if (this.IsAufsatzEinseitigMoeglich && side === SideType.Inside){
          return true
        }
        break
      case SingleConstruction.Aufsatz:
        if (this.IsAufsatzBeidseitigMoeglich){
          return true
        }
        if (this.IsAufsatzEinseitigMoeglich && side === SideType.Outside){
          return true
        }
        break
    }
    return false
  }

  findObjectFromData<ObjectType extends Blendrahmen | Fluegelrahmen | Lack | Folie | Pulver | DesignOberflaeche>(
    object: unknown,
    predicate?: (ObjectType) => boolean
  ): ObjectType | null {
    if (
      typeof object !== 'object'
      || object === null
      || !('Typ' in object)
      || !('Id' in object)
      || typeof object.Typ !== 'string'
    ) {
      return null
    }
    const filter = typeof predicate !== 'undefined'
      ? (o: Partial<{ Id: unknown }>): boolean => o.Id === object.Id && predicate(o)
      : (o: Partial<{ Id: unknown }>): boolean => o.Id === object.Id
    let found: unknown = null
    switch (object.Typ) {
      case 'blendrahmen':
        found = this.Blendrahmen.find(filter)
        break
      case 'fluegelrahmen':
        found = this.Fluegelrahmen.find(filter)
        break
      case 'farbe':
        found = this.Farben.find(filter)
        break
      case 'folie':
        found = this.Folien.find(filter)
        break
      case 'designoberflaeche':
        found = this.DesignOberflaechen.find(filter)
        break
      case 'pulver':
        found = this.Pulver.find(filter)
        break
    }
    return found as ObjectType || null
  }

  findZubehoer(idOrType: number | ZubehoerCategoryIdentifier): Zubehoer | undefined

  findZubehoer(id: number, type: ZubehoerCategoryIdentifier): Zubehoer | undefined

  findZubehoer(idOrType: number | string, maybeType?: string): Zubehoer | undefined {
    const id = typeof idOrType === 'number' ? idOrType : undefined
    const type = typeof idOrType === 'string' ? idOrType : maybeType
    return this.Zubehoer?.find((zubehoer): boolean =>
      (typeof id === 'undefined' || zubehoer.Id === id)
      && (typeof type === 'undefined' || zubehoer.Typ === type)
    )
  }

  // returns the Typ of the first Dienstleistung that has the word 'montage' in it's Typ Attribute
  getMontageDienstleistung(mehrpreisTyp: string): Dienstleistung | undefined {
    return this.Mehrpreise.find((m): boolean => m.Typ === mehrpreisTyp)
      ?.Dienstleistungen.find((d): boolean => d.Typ.includes('montage'))
  }

  getPackages({material, type}: { material?: Material; type?: PackageType } = {}): Paket[] {
    return this.Pakete.filter((p): boolean =>
      (!material || p.isMaterialSupported(material))
      && (!type || p.hasType(type))
    )
  }

  getZubehoerCategories(): readonly ZubehoerCategory[] {
    return this.zubehoerCategories
  }

  getZubehoerCategoryName(
    categoryIdentifier: ZubehoerCategoryIdentifier
  ): ZubehoerCategoryName   {
    return this.getZubehoerCategories().find((c): boolean => c[0] === categoryIdentifier)?.[1] ?? categoryIdentifier
  }

  hasOptions(side?: SideType): boolean {
    return typeof side === 'undefined'
      ? this.hasOptions(SideType.Inside) || this.hasOptions(SideType.Outside)
      : Object.keys(this.orderedOptions[side])?.length > 0
  }

  isGlassPossible(): boolean {
    // TODO: rework to take all components into account
    return this.Konfiguration?.Components?.[0]?.Glasaufbau?.Id > 0
  }

  maxheight(construction: SimpleConstruction, material: Material): number {
    let maxHeight = 0
    if (construction === SimpleConstruction.Einsatz && material === Material.Alu) {
      maxHeight = this.MaxHoeheEinsatzAluminium
    }
    if (construction === SimpleConstruction.Einsatz && material === Material.Kunststoff) {
      maxHeight = this.MaxHoeheEinsatzKunststoff
    }
    if (construction === SimpleConstruction.AufsatzEinseitig && material === Material.Alu) {
      maxHeight = this.MaxHoeheAufsatzAluminium
    }
    if (construction === SimpleConstruction.AufsatzEinseitig && material === Material.Kunststoff) {
      maxHeight = this.MaxHoeheAufsatzKunststoff
    }
    if (construction === SimpleConstruction.AufsatzBeidseitig && material === Material.Alu) {
      maxHeight = this.MaxHoeheAufsatzAluminium
    }
    if (construction === SimpleConstruction.AufsatzBeidseitig && material === Material.Kunststoff) {
      maxHeight = this.MaxHoeheAufsatzKunststoff
    }
    return maxHeight
  }

  maxwidth(construction: SimpleConstruction, material: Material): number {
    let maxWidth = 0
    if (construction === SimpleConstruction.Einsatz && material === Material.Alu) {
      maxWidth = this.MaxBreiteEinsatzAluminium
    }
    if (construction === SimpleConstruction.Einsatz && material === Material.Kunststoff) {
      maxWidth = this.MaxBreiteEinsatzKunststoff
    }
    if (construction === SimpleConstruction.AufsatzEinseitig && material === Material.Alu) {
      maxWidth = this.MaxBreiteAufsatzAluminium
    }
    if (construction === SimpleConstruction.AufsatzEinseitig && material === Material.Kunststoff) {
      maxWidth = this.MaxBreiteAufsatzKunststoff
    }
    if (construction === SimpleConstruction.AufsatzBeidseitig && material === Material.Alu) {
      maxWidth = this.MaxBreiteAufsatzAluminium
    }
    if (construction === SimpleConstruction.AufsatzBeidseitig && material === Material.Kunststoff) {
      maxWidth = this.MaxBreiteAufsatzKunststoff
    }
    return maxWidth
  }

  minheight(construction: SimpleConstruction): number {
    let minHeight = 0
    if (construction === SimpleConstruction.Einsatz) {
      minHeight = this.MinHoeheEinsatz
    }
    if (construction === SimpleConstruction.AufsatzEinseitig) {
      minHeight = this.MinHoeheAufsatz
    }
    if (construction === SimpleConstruction.AufsatzBeidseitig) {
      minHeight = this.MinHoeheAufsatz
    }
    return minHeight
  }

  minwidth(construction: SimpleConstruction): number {
    let minWidth = 0
    if (construction === SimpleConstruction.Einsatz) {
      minWidth = this.MinBreiteEinsatz
    }
    if (construction === SimpleConstruction.AufsatzEinseitig) {
      minWidth = this.MinBreiteAufsatz
    }
    if (construction === SimpleConstruction.AufsatzBeidseitig) {
      minWidth = this.MinBreiteAufsatz
    }
    return minWidth
  }

  get orderedOptions(): InsideOutsideObject<{ [p: OptionType]: Option[] }> {
    if (!this._orderedOptions) {
      const categories: this['orderedOptions'] = {
        Inside: {},
        Outside: {}
      }
      this.Optionen.forEach((o): void => {
        // check if option and current view are matching
        if (o.IsAussen) {
          if (!(o.Typ in categories.Outside)) {
            categories.Outside[o.Typ] = []
          }
          categories.Outside[o.Typ].push(o)
        } else if (o.IsInnen) {
          if (!(o.Typ in categories.Inside)) {
            categories.Inside[o.Typ] = []
          }
          categories.Inside[o.Typ].push(o)
        }
      })
      this._orderedOptions = categories
    }
    return this._orderedOptions
  }


}
