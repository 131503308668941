import {Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren} from '@angular/core'
import {NGXLogger} from 'ngx-logger'
import {NavigatorService} from '../../../classes/service/navigator.service'
import {ConfiguratorConfigurationModel} from '../../../classes/model/configuratorConfigurationModel'
import {ConfiguratedDoor} from '../../../classes/model/configuratedDoor'
import {ComponentModel} from '../../../classes/model/component/model/component-model'
import {ToastrService} from 'ngx-toastr'
import {TranslateService} from '../../../translate'
import {ModalService} from '../../modal/modal.service'
import * as constEnumModule from '../../../types'
import {Din, minMax, SideType, SizeType} from '../../../types'
import {ConstructionComponent} from '../../../classes/model/component/construction/constructionComponent'
import {Material} from '../../../classes/model/material'
import {ConstructionComponentType} from '../../../classes/model/component/construction/construction-component-type.enum'
import {ViewService} from '../../../classes/service/view.service'
import {GrundformService} from '../../../classes/service/grundform.service'
import {ComponentSelectionService} from '../../../classes/service/componentSelectionService'
import * as ConstructionModule from '../../../classes/model/component/other/construction'
import {SimpleConstruction, SingleConstruction} from '../../../classes/model/component/other/construction'

type ErrorMessage = string
const TRANSLATION_KEY = {
  DIN_TITLE: 'KeyDataMenu.DinTitle',
  DIN_LEFT: 'KeyDataMenu.DinLeft',
  DIN_RIGHT: 'KeyDataMenu.DinRight',
  CONSTRUCTION_TITLE: 'KeyDataMenu.ConstructionTitle',
  CONSTRUCTION_OUTSIDE: 'KeyDataMenu.ConstructionOutside',
  CONSTRUCTION_INSIDE: 'KeyDataMenu.ConstructionInside',
  CONSTRUCTION_FILLING_INSERTED: 'KeyDataMenu.FillingInserted',
  CONSTRUCTION_FILLING_INSERTED_SHORT: 'KeyDataMenu.FillingInsertedShort',
  CONSTRUCTION_FILLING_INSERTED_TEXT: 'KeyDataMenu.FillingInsertedText',
  CONSTRUCTION_FILLING_CONCEALING: 'KeyDataMenu.FillingConsealing',
  CONSTRUCTION_FILLING_CONCEALING_SHORT: 'KeyDataMenu.FillingConsealingShort',
  CONSTRUCTION_FILLING_CONCEALING_TEXT: 'KeyDataMenu.FillingConsealingText',
  MEASURES_TITLE: 'KeyDataMenu.MeasuresTitle',
  MEASURES_DOOR: 'KeyDataMenu.MeasuresDoor',
  MEASURES_FANLIGHT: 'KeyDataMenu.MeasuresFanlight',
  MEASURES_SIDEPANEL: 'KeyDataMenu.MeasuresSidepanel',
  MEASURES_WIDTH: 'KeyDataMenu.MeasuresWidth',
  MEASURES_HEIGHT: 'KeyDataMenu.MeasuresHeight',
  MEASURES_FULL_WIDTH: 'KeyDataMenu.MeasuresFullWidth',
  MEASURES_FULL_HEIGHT: 'KeyDataMenu.MeasuresFullHeight',
  MEASURES_MM: 'KeyDataMenu.MeasuresMM',
  MEASURES_FOOTNOTE: 'KeyDataMenu.MeasuresFootnote',
  MEASURES_THICKNESS: 'KeyDataMenu.MeasuresThickness',
  MEASURES_SAVE: 'KeyDataMenu.MeasuresSave',
  MEASURES_WARNING_WIDTH_SET_TO_MAX_TITLE: 'KeyDataMenu.MeasuresWidthSetToMaxTitle',
  MEASURES_WARNING_WIDTH_SET_TO_MAX_PREFIX: 'KeyDataMenu.MeasuresWidthSetToMaxPrefix',
  MEASURES_WARNING_WIDTH_SET_TO_MAX_SUFFIX: 'KeyDataMenu.MeasuresWidthSetToMaxSuffix',
  MEASURES_WARNING_WIDTH_SET_TO_MIN_TITLE: 'KeyDataMenu.MeasuresWidthSetToMinTitle',
  MEASURES_WARNING_WIDTH_SET_TO_MIN_PREFIX: 'KeyDataMenu.MeasuresWidthSetToMinPrefix',
  MEASURES_WARNING_WIDTH_SET_TO_MIN_SUFFIX: 'KeyDataMenu.MeasuresWidthSetToMinSuffix',
  MEASURES_WARNING_HEIGHT_SET_TO_MAX_TITLE: 'KeyDataMenu.MeasuresHeightSetToMaxTitle',
  MEASURES_WARNING_HEIGHT_SET_TO_MAX_PREFIX: 'KeyDataMenu.MeasuresHeightSetToMaxPrefix',
  MEASURES_WARNING_HEIGHT_SET_TO_MAX_SUFFIX: 'KeyDataMenu.MeasuresHeightSetToMaxSuffix',
  MEASURES_WARNING_HEIGHT_SET_TO_MIN_TITLE: 'KeyDataMenu.MeasuresHeightSetToMinTitle',
  MEASURES_WARNING_HEIGHT_SET_TO_MIN_PREFIX: 'KeyDataMenu.MeasuresHeightSetToMinPrefix',
  MEASURES_WARNING_HEIGHT_SET_TO_MIN_SUFFIX: 'KeyDataMenu.MeasuresHeightSetToMinSuffix',
  MENU_TITLE: 'KeyDataMenu.Title',
  INSERTED_OUTSIDE_COMB_TEXT: 'KeyDataMenu.InsertedOutsideCombinationText',
  INSERTED_OUTSIDE_IMPOSSIBLE_TEXT: 'KeyDataMenu.InsertedOutsideImpossibleText',
  CONCEALING_OUTSIDE_COMB_TEXT: 'KeyDataMenu.ConsealingOutsideCombinationText',
  CONCEALING_OUTSIDE_INSIDE_TEXT: 'KeyDataMenu.ConsealingOutsideInsideText'
}

@Component({
  selector: 'configurator-key-data-menu',
  templateUrl: './key-data-menu.component.html',
  styleUrls: ['./key-data-menu.component.scss']
})
export class KeyDataMenuComponent {
  protected readonly Din = (constEnumModule as { Din: Required<typeof Din> })?.Din
  protected readonly SideType = SideType
  protected readonly SimpleConstruction = (ConstructionModule as {
    SimpleConstruction: Required<typeof SimpleConstruction>
  })?.SimpleConstruction
  protected readonly SingleConstruction = (ConstructionModule as {
    SingleConstruction: Required<typeof SingleConstruction>
  })?.SingleConstruction
  protected readonly SizeType = SizeType
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  @Input() isMobile: boolean
  @ViewChildren('measureInput') private measureInputsRef: QueryList<ElementRef<HTMLInputElement>>
  @ViewChild('measuresFormRef') private measuresFormRef: ElementRef<HTMLFormElement>
  @Output() readonly measuresUpdated: EventEmitter<void>
  @Output() readonly renderRequest: EventEmitter<null>
  @Input() showPrice: boolean

  constructor(
    private logger: NGXLogger,
    protected readonly navigatorService: NavigatorService,
    private modalService: ModalService,
    private _configuratorConfigurationModel: ConfiguratorConfigurationModel,
    private toastrService: ToastrService,
    private _translate: TranslateService,
    private grundformService: GrundformService,
    private viewService: ViewService,
    private componentSelectionService: ComponentSelectionService
  ) {
    this.renderRequest = new EventEmitter<null>()
    this.measuresUpdated = new EventEmitter<null>()
    this.navigatorService.onShowToggleChange.subscribe((value: string): void => {
      if (value !== 'Masse') {
        this.measuresFormRef.nativeElement.reset()
      }
    })
  }

  private calcMinMaxValues(model: ComponentModel, element: ConstructionComponent): minMax {
    const ex = {
      maxHeight: 0,
      minHeight: 0,
      maxWidth: 0,
      minWidth: 0
    }
    const profilMasse = this.configuratedDoor.profile.Masse.find((m): boolean => m.Typ === element.objectType)
    const material = this.configuratedDoor.profile.Material
    const profileMinWidth = profilMasse.MinBreite
    const profileMaxWidth = profilMasse.MaxBreite
    const profileMinHeight = profilMasse.MinHoehe
    const profileMaxHeight = profilMasse.MaxHoehe
    // set default values
    if (element.objectType !== ConstructionComponentType.Fanlight) {
      ex.minWidth = Math.max(model.minwidth(element.konstruktion), profileMinWidth)
      ex.maxWidth =
        profileMaxWidth > 0
          ? Math.min(model.maxwidth(element.konstruktion, material), profileMaxWidth)
          : model.maxwidth(element.konstruktion, material)
      ex.minHeight = Math.max(model.minheight(element.konstruktion), profileMinHeight)
      ex.maxHeight =
        profileMaxHeight > 0
          ? Math.min(model.maxheight(element.konstruktion, material), profileMaxHeight)
          : model.maxheight(element.konstruktion, material)
    } else {
      ex.minWidth = profileMinWidth !== 0 ? profileMinWidth : model.minwidth(element.konstruktion)
      ex.maxWidth = profileMaxWidth !== 0 ? profileMaxWidth : model.maxwidth(element.konstruktion, material)
      ex.minHeight = profileMinHeight !== 0 ? profileMinHeight : model.minheight(element.konstruktion)
      ex.maxHeight = profileMaxHeight !== 0 ? profileMaxHeight : model.maxheight(element.konstruktion, material)
    }
    if (element.konstruktion === SimpleConstruction.AufsatzBeidseitig) {
      switch (material) {
        case Material.Kunststoff:
          ex.maxHeight = Math.min(model.MaxHoeheAufsatzKunststoff, ex.maxHeight)
          ex.maxWidth = Math.min(model.MaxBreiteAufsatzKunststoff, ex.maxWidth)
          ex.minHeight = Math.max(model.MinHoeheAufsatz, ex.minHeight)
          ex.minWidth = Math.max(model.MinBreiteAufsatz, ex.minWidth)
          break
        case Material.Alu:
          ex.maxHeight = Math.min(model.MaxHoeheAufsatzAluminium, ex.maxHeight)
          ex.maxWidth = Math.min(model.MaxBreiteAufsatzAluminium, ex.maxWidth)
          ex.minHeight = Math.max(model.MinHoeheAufsatz, ex.minHeight)
          ex.minWidth = Math.max(model.MinBreiteAufsatz, ex.minWidth)
          break
        default:
          console.error('no valid material found')
      }
    } else if (
      (element.konstruktion === SimpleConstruction.AufsatzEinseitig)
    ) {
      const minWidthE = model.MinBreiteEinsatz
      const minWidthAE = model.MinBreiteAufsatz
      const minHeightE = model.MinHoeheEinsatz
      const minHeightAE = model.MinHoeheAufsatz
      let maxWidthE: number
      let maxWidthAE: number
      let maxHeightE: number
      let maxHeightAE: number
      switch (material) {
        case Material.Kunststoff:
          maxWidthE = model.MaxBreiteEinsatzKunststoff
          maxWidthAE = model.MaxBreiteAufsatzKunststoff
          maxHeightE = model.MaxHoeheEinsatzKunststoff
          maxHeightAE = model.MaxHoeheAufsatzKunststoff
          break
        case Material.Alu:
          maxWidthE = model.MaxBreiteEinsatzAluminium
          maxWidthAE = model.MaxBreiteAufsatzAluminium
          maxHeightE = model.MaxHoeheEinsatzAluminium
          maxHeightAE = model.MaxHoeheAufsatzAluminium
          break
        default:
          console.error('no valid material found')
      }
      // nimm die größere mindestBreite/höhe
      // nimm die kleinere höchstbreite/höhe
      ex.minWidth = Math.max(minWidthE, minWidthAE, ex.minWidth)
      ex.maxWidth = Math.min(maxWidthE, maxWidthAE, ex.maxWidth)
      ex.minHeight = Math.max(minHeightE, minHeightAE, ex.minHeight)
      ex.maxHeight = Math.min(maxHeightE, maxHeightAE, ex.maxHeight)
    } else if (
      element.konstruktion === SimpleConstruction.Einsatz
    ) {
      switch (material) {
        case Material.Kunststoff:
          ex.maxHeight = Math.min(model.MaxHoeheEinsatzKunststoff, ex.maxHeight)
          ex.maxWidth = Math.min(model.MaxBreiteEinsatzKunststoff, ex.maxWidth)
          ex.minHeight = Math.max(model.MinHoeheEinsatz, ex.minHeight)
          ex.minWidth = Math.max(model.MinBreiteEinsatz, ex.minWidth)
          break
        case Material.Alu:
          ex.maxHeight = Math.min(model.MaxHoeheEinsatzAluminium, ex.maxHeight)
          ex.maxWidth = Math.min(model.MaxBreiteEinsatzAluminium, ex.maxWidth)
          ex.minHeight = Math.max(model.MinHoeheEinsatz, ex.minHeight)
          ex.minWidth = Math.max(model.MinBreiteEinsatz, ex.minWidth)
          break
        default:
          console.error('no valid material found')
      }
    }
    if (element.konstruktion !== SimpleConstruction.AufsatzBeidseitig) {
      ex.minWidth =
        ex.minWidth +
        profilMasse.FluegelrahmenAussenSeite +
        profilMasse.FluegelrahmenAussenSeite
      ex.maxWidth =
        ex.maxWidth +
        profilMasse.FluegelrahmenAussenSeite +
        profilMasse.FluegelrahmenAussenSeite
      ex.minHeight =
        ex.minHeight +
        profilMasse.FluegelrahmenAussenOben +
        profilMasse.FluegelrahmenAussenUnten
      ex.maxHeight =
        ex.maxHeight +
        profilMasse.FluegelrahmenAussenOben +
        profilMasse.FluegelrahmenAussenUnten
    }
    // add Blendrahmen to all
    ex.minWidth =
      ex.minWidth +
      profilMasse.BlendrahmenAussenSeite +
      profilMasse.BlendrahmenAussenSeite
    ex.maxWidth =
      ex.maxWidth +
      profilMasse.BlendrahmenAussenSeite +
      profilMasse.BlendrahmenAussenSeite
    ex.minHeight =
      ex.minHeight + profilMasse.BlendrahmenAussenOben
    ex.maxHeight =
      ex.maxHeight + profilMasse.BlendrahmenAussenOben
    /* if (element.objectType === 'Tuer'){

                } else {
                  minHeight = minHeight + profilMasse.blendrahmen_breite_oben + profilMasse.blendrahmen_breite_unten;
                  maxHeight = maxHeight + profilMasse.blendrahmen_breite_oben + profilMasse.blendrahmen_breite_unten;
                } */
    return ex
  }

  checkMeasureChanges(): void {
    // check if values in input fields differ from the default values in model
    let standardWerte: boolean = (
      this.configuratedDoor.Hausfronten?.[SideType.Outside]
      || this.configuratedDoor.Hausfronten?.[SideType.Inside]
    ) && !this.configuratedDoor.hasCustomHausfront
    if (standardWerte) {
      this.measureInputsRef.forEach((inputRef: ElementRef<HTMLInputElement>): void => {
        const component: ConstructionComponent = this.configuratedDoor.Components.find(
          (c: ConstructionComponent): boolean => c.Index === Number(inputRef.nativeElement.dataset.elementIndex)
        )
        const parameter = inputRef.nativeElement.dataset.sizeType === 'width' ? SizeType.Width : SizeType.Height
        const validatedValue = Number(inputRef.nativeElement.value)
        switch (component.objectType) {
          case ConstructionComponentType.SidePanel:
            if (
              parameter === SizeType.Height
              && validatedValue !== this.configuratedDoor.Hausfronten[this.viewService.currentSide].SeitenteilHoehe
            ) {
              standardWerte = false
            }
            if (
              parameter === SizeType.Width
              && validatedValue !== this.configuratedDoor.Hausfronten[this.viewService.currentSide].SeitenteilBreite
            ) {
              standardWerte = false
            }
            break
          case ConstructionComponentType.Fanlight:
            if (
              parameter === SizeType.Height
              && validatedValue !== this.configuratedDoor.Hausfronten[this.viewService.currentSide].OberlichtHoehe
            ) {
              standardWerte = false
            }
            if (
              parameter === SizeType.Width
              && validatedValue !== this.configuratedDoor.Hausfronten[this.viewService.currentSide].OberlichtBreite
            ) {
              standardWerte = false
            }
            break
          case ConstructionComponentType.Door:
            if (
              parameter === SizeType.Height
              && validatedValue !== this.configuratedDoor.Hausfronten[this.viewService.currentSide].TuerHoehe
            ) {
              standardWerte = false
            }
            if (
              parameter === SizeType.Width
              && validatedValue !== this.configuratedDoor.Hausfronten[this.viewService.currentSide].TuerBreite
            ) {
              standardWerte = false
            }
            break
        }
      })
    }
    const standardHausfronten = !this.configuratedDoor.hasCustomHausfront
    const customHausfronten = this.configuratedDoor.hasCustomHausfront
    const noHausfronten = this.configuratedDoor.Hausfronten.Inside === null && this.configuratedDoor.Hausfronten.Outside === null
    if (standardHausfronten && standardWerte) {
      // do nothing as nothing has changed
    } else if (noHausfronten || customHausfronten || (!this.configuratedDoor.customSize && standardWerte)) {
      this.updateConstructionComponentMeasures()
      this.updateCustomHausfrontValues()
      this.renderRequest.emit()
    } else if (standardHausfronten && !standardWerte) {
      // ask user if values should really be customized
      this.modalService.showMasseChangeModal()
        .afterClosed()
        .subscribe((result: boolean): void => {
          if (result) {
            // Set new values
            this.updateConstructionComponentMeasures()
            // remove Hausfronten
            if (!this.configuratedDoor.hasCustomHausfront) {
              this.configuratedDoor.Hausfronten = {
                Inside: null,
                Outside: null
              }
            }
            this.updateCustomHausfrontValues()
          } else {
            this.measuresFormRef.nativeElement.reset()
            // this.resetMeasureInput()
          }
          this.renderRequest.emit()
        })
    } else {
      console.error('Update Measures. This code should not be reached.')
    }
  }

  getConstructionDisallowedError(
    construction: SingleConstruction,
    side: SideType
  ): undefined | ErrorMessage {
    const componentToString = (component: ConstructionComponent): string =>
      `${this._translate.translate(
        component.objectType === ConstructionComponentType.Door
          ? TRANSLATION_KEY.MEASURES_DOOR
          : component.objectType === ConstructionComponentType.SidePanel
            ? TRANSLATION_KEY.MEASURES_SIDEPANEL
            : TRANSLATION_KEY.MEASURES_FANLIGHT
      )} (${this._translate.translate(component.model.Bezeichnung)})`
    // skip if not initialized
    if (
      this.configuratedDoor.Components.filter(
        (c): boolean => typeof c.model === 'undefined'
      ).length !== 0
    ) {
      return undefined
    }
    if (construction === SingleConstruction.Einsatz) {
      if (side === SideType.Outside) {
        if (this.construction === SimpleConstruction.AufsatzBeidseitig) {
          return this._translate.translate(
            TRANSLATION_KEY.INSERTED_OUTSIDE_COMB_TEXT
          )
        }
        if (!this.selectedComponent.model.IsEinsatzMoeglich) {
          return this._translate.translate(
            TRANSLATION_KEY.INSERTED_OUTSIDE_IMPOSSIBLE_TEXT)
            + componentToString(this.selectedComponent)
        }
        return undefined
      }
      if (this.construction === SimpleConstruction.AufsatzBeidseitig) {
        if (!this.selectedComponent.model.IsAufsatzEinseitigMoeglich) {
          return this._translate.translate(
            TRANSLATION_KEY.CONCEALING_OUTSIDE_COMB_TEXT
          ) + componentToString(this.selectedComponent)
        }
      }
      return undefined
    }
    if (side === SideType.Outside) {
      if (this.construction === SimpleConstruction.Einsatz) {
        if (!this.selectedComponent.model.IsAufsatzEinseitigMoeglich) {
          return this._translate.translate(
            TRANSLATION_KEY.CONCEALING_OUTSIDE_COMB_TEXT
          ) + componentToString(this.selectedComponent)
        }
      }
      return undefined
    }
    if (this.construction === SimpleConstruction.Einsatz) {
      return this._translate.translate(
        TRANSLATION_KEY.INSERTED_OUTSIDE_COMB_TEXT
      )
    }
    if (!this.selectedComponent.model.IsAufsatzBeidseitigMoeglich) {
      return this._translate.translate(
        TRANSLATION_KEY.CONCEALING_OUTSIDE_INSIDE_TEXT
      ) + componentToString(this.selectedComponent)
    }
    return undefined
  }

  private minMaxCheck(
    element: ConstructionComponent,
    minMaxValues: minMax,
    newValue: number,
    parameter: SizeType
  ): number {
    const MIN_HEIGHT_FANLIGHT = 200
    if (element.objectType === ConstructionComponentType.Fanlight) {
      minMaxValues.minHeight = Math.max(minMaxValues.minHeight, MIN_HEIGHT_FANLIGHT)
    }
    // check against MAX values
    if (parameter === SizeType.Width && newValue > minMaxValues.maxWidth) {
      this.toastrService.warning(
        this._translate.translate(TRANSLATION_KEY.MEASURES_WARNING_WIDTH_SET_TO_MAX_PREFIX) +
        minMaxValues.maxWidth +
        this._translate.translate(TRANSLATION_KEY.MEASURES_WARNING_WIDTH_SET_TO_MAX_SUFFIX),
        this._translate.translate(TRANSLATION_KEY.MEASURES_WARNING_WIDTH_SET_TO_MAX_TITLE)
      )
      return minMaxValues.maxWidth
    }
    if (
      parameter === SizeType.Height &&
      newValue > minMaxValues.maxHeight
    ) {
      this.toastrService.warning(
        this._translate.translate(TRANSLATION_KEY.MEASURES_WARNING_HEIGHT_SET_TO_MAX_PREFIX) +
        minMaxValues.maxHeight +
        this._translate.translate(TRANSLATION_KEY.MEASURES_WARNING_HEIGHT_SET_TO_MAX_PREFIX),
        this._translate.translate(TRANSLATION_KEY.MEASURES_WARNING_HEIGHT_SET_TO_MAX_TITLE)
      )
      return minMaxValues.maxHeight
    }
    // check against MIN values
    if (parameter === SizeType.Width && newValue < minMaxValues.minWidth) {
      this.toastrService.warning(
        this._translate.translate(TRANSLATION_KEY.MEASURES_WARNING_WIDTH_SET_TO_MIN_PREFIX) +
        minMaxValues.minWidth +
        this._translate.translate(TRANSLATION_KEY.MEASURES_WARNING_WIDTH_SET_TO_MIN_SUFFIX),
        this._translate.translate(TRANSLATION_KEY.MEASURES_WARNING_WIDTH_SET_TO_MIN_TITLE)
      )
      return minMaxValues.minWidth
    }
    if (
      parameter === SizeType.Height &&
      newValue < minMaxValues.minHeight
    ) {
      this.toastrService.warning(
        this._translate.translate(TRANSLATION_KEY.MEASURES_WARNING_HEIGHT_SET_TO_MIN_PREFIX) +
        minMaxValues.minHeight +
        this._translate.translate(TRANSLATION_KEY.MEASURES_WARNING_HEIGHT_SET_TO_MIN_SUFFIX),
        this._translate.translate(TRANSLATION_KEY.MEASURES_WARNING_HEIGHT_SET_TO_MIN_TITLE)
      )
      return minMaxValues.minHeight
    }
    return newValue
  }

  reviseSize(element: ConstructionComponent, eventTarget: EventTarget, parameter: SizeType): void {
    if (!(eventTarget instanceof HTMLInputElement)) {
      return
    }
    const inputField: HTMLInputElement = eventTarget
    const ex: minMax = this.calcMinMaxValues(element.model, element)
    const validatedValue: number = this.minMaxCheck(element, ex, Number(inputField.value), parameter)
    inputField.value = validatedValue.toString()
  }

  setConstruction(component: ConstructionComponent, construction: SingleConstruction, side: SideType): void {
    if (this.getConstructionDisallowedError(construction, side) !== undefined) {
      return
    }
    if (side === SideType.Outside && construction === SingleConstruction.Einsatz) {
      component.konstruktion = SimpleConstruction.Einsatz
    }
    if (side === SideType.Outside && construction === SingleConstruction.Aufsatz) {
      if (component.konstruktion === SimpleConstruction.Einsatz) {
        component.konstruktion = SimpleConstruction.AufsatzEinseitig
      } else {
        component.konstruktion = SimpleConstruction.AufsatzBeidseitig
      }
    }
    if (side === SideType.Inside && construction === SingleConstruction.Einsatz) {
      if (
        component.konstruktion === SimpleConstruction.AufsatzEinseitig
        || component.konstruktion === SimpleConstruction.AufsatzBeidseitig
      ) {
        component.konstruktion = SimpleConstruction.AufsatzEinseitig
      } else {
        component.konstruktion = SimpleConstruction.Einsatz
      }
    }
    if (side === SideType.Inside && construction === SingleConstruction.Aufsatz) {
      component.konstruktion = SimpleConstruction.AufsatzBeidseitig
    }
    // this._configuratorConfigurationModel.configuratedDoor.Konstruktion = construction
    void this._configuratorConfigurationModel.updateConstruction().then((): void => {
      this.viewService.view = side
      this.renderRequest.emit()
    })
  }

  private setValues(element: ConstructionComponent, parameter: SizeType, validatedValue: number): void {
    // this.configuratedDoor.customSize = true
    if (parameter === SizeType.Width) {
      element.breite = validatedValue
      this.configuratedDoor.updateWidths()
    } else if (parameter === SizeType.Height) {
      element.hoehe = validatedValue
      // if the doorheight has been changed
      if (element.objectType === ConstructionComponentType.Door) {
        // update all other elements to match it's height
        this.configuratedDoor.updateSiblingHeigths(element)
      }
      this.configuratedDoor.updateHeights()
    }
  }

  updateConstructionComponentMeasures(): void {
    this.measureInputsRef.forEach((inputRef: ElementRef<HTMLInputElement>): void => {
      const component = this.configuratedDoor.Components.find(
        (c: ConstructionComponent): boolean => c.Index === Number(inputRef.nativeElement.dataset.elementIndex)
      )
      const parameter = inputRef.nativeElement.dataset.sizeType === 'width' ? SizeType.Width : SizeType.Height
      const validatedValue = Number(inputRef.nativeElement.value)
      this.setValues(component, parameter, validatedValue)
    })
  }

  updateCustomHausfrontValues(): void {
    const sides: constEnumModule.SideType[] = [SideType.Inside, SideType.Outside]
    sides.forEach((st: SideType): void => {
      if (this.configuratedDoor.Hausfronten[st]?.Custom) {
        this.grundformService.updateCustomHausfront(
          this.configuratedDoor.Hausfronten[st],
          this.configuratedDoor.width,
          this.configuratedDoor.height
        ).then(() => void this.grundformService.updateAllCustomHausfrontenElementSizes(
          st,
          this.configuratedDoor.width,
          this.configuratedDoor.height
        )).catch((err): void => {
          console.error(err)
        })
      }
    })
  }

  get configuratedDoor(): ConfiguratedDoor {
    return this._configuratorConfigurationModel.configuratedDoor
  }

  get construction(): SimpleConstruction {
    return this.selectedComponent.konstruktion
  }

  set din(din: Din) {
    if (this.selectedComponent.shortObjectType === 'T') {
      this.selectedComponent.din = din
      this.selectedComponent.dinfuellung = din
    } else {
      this.selectedComponent.dinfuellung = din
    }
    this.renderRequest.emit()
  }

  get dinFuellung(): Din {
    return this.selectedComponent.dinfuellung
  }

  get selectedComponent(): ConstructionComponent {
    return this.componentSelectionService.selectedComponent
  }
}
