import {Component, Input} from '@angular/core'
import {NavigatorService} from '../../../classes/service/navigator.service'
import {TranslateService} from '../../../translate'

const TRANSLATION_KEY = {
  MENU_NAVIGATION_BUTTON_PREVIOUS: 'MenuNavigation.Button.Previous'
}

export type ButtonSettings = {
  disabled?: boolean | (() => boolean)
  displayString?: string | (() => string)
  onClick?: () => void
}
type ButtonConfiguration = {
  getDisplayString: () => string
  isDisabled: () => boolean
  onClick: () => void
}

@Component({
  selector: 'configurator-menu-navigation',
  templateUrl: './menu-navigation.component.html',
  styleUrls: ['menu-navigation.component.scss']
})
export class MenuNavigationComponent {
  @Input() isMobile: boolean
  protected nextButtonConfiguration: Required<ButtonConfiguration>
  protected previousButtonConfiguration: Required<ButtonConfiguration>

  constructor(
    protected readonly navigatorService: NavigatorService,
    translateService: TranslateService
  ) {
    this.nextButtonConfiguration = {
      getDisplayString: (): string => navigatorService.getNextString(),
      isDisabled: (): boolean => navigatorService.showToggle === 'Fertig',
      onClick: () => void navigatorService.getNextFunc(),
    }
    this.previousButtonConfiguration = {
      getDisplayString: (): string => translateService.translate(TRANSLATION_KEY.MENU_NAVIGATION_BUTTON_PREVIOUS),
      isDisabled: (): boolean => navigatorService.isFirstNavEntryActive(),
      onClick: () => void navigatorService.goToPrev(),
    }
  }

  private applySettingsToButton(
    button: 'nextButtonConfiguration' | 'previousButtonConfiguration',
    settings: ButtonSettings
  ): void {
    if (typeof settings?.onClick === 'function') {
      this[button].onClick = settings.onClick
    }
    if (typeof settings?.disabled === 'boolean') {
      const disabled = settings.disabled
      this[button].isDisabled = (): boolean => disabled
    } else if (typeof settings?.disabled === 'function') {
      const isDisabled = settings.disabled
      this[button].isDisabled = (): boolean => isDisabled()
    }
    if (typeof settings?.displayString === 'string') {
      const displayString = settings.displayString
      this[button].getDisplayString = (): string => displayString
    } else if (typeof settings?.displayString === 'function') {
      const getDisplayString = settings.displayString
      this[button].getDisplayString = (): string => getDisplayString()
    }
  }

  @Input({required: false}) set nextButton(settings: ButtonSettings) {
    this.applySettingsToButton('nextButtonConfiguration', settings)
  }

  @Input({required: false}) set previousButton(settings: ButtonSettings) {
    this.applySettingsToButton('previousButtonConfiguration', settings)
  }
}
