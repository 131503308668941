import {Component, EventEmitter, HostBinding, HostListener, isDevMode, OnInit, Output, ViewChild} from '@angular/core'
import {Location} from '@angular/common'
import {HausfrontDoorPosition} from '../class'
import {HttpService} from '../http.service'
import {TranslateService} from '../translate'
import {environment} from '../../environments/environment'
import {ComponentModel} from '../classes/model/component/model/component-model'
import {TuerFactory} from '../classes/model/component/construction/tuer'
import {ConstructionComponent} from '../classes/model/component/construction/constructionComponent'
import * as constEnumModule from '../types'
import {ConfiguratorMode, Din, SideType} from '../types'
import {NGXLogger} from 'ngx-logger'
import {FbsModelService} from '../classes/model/dataProvider/fbsModel/fbsModel.service'
import {GlasaufbauFactory} from '../classes/model/component/glassaufbau/glasaufbau'
import {SeitenteilFactory} from '../classes/model/component/construction/seitenteil'
import {OberlichtFactory} from '../classes/model/component/construction/oberlicht'
import {ConfiguratedDoor, ConfiguratedDoorFactory} from '../classes/model/configuratedDoor'
import {CatalogueService} from '../classes/service/catalogueService'
import {ModelMenuComponent} from './menu/model-menu/model-menu.component'
import {StringUtil} from '../classes/util/stringUtil'
import {Settings} from '../classes/service/settings/settings'
import {MenuClosed, NavigationKey, NavigatorService} from '../classes/service/navigator.service'
import {ParameterService} from '../classes/service/parameter/parameter.service'
import {ConfiguratorDataModel} from '../classes/model/configuratorDataModel'
import {GlassService} from '../classes/model/dataProvider/glass/glass.service'
import {GLASS_PROVIDER_TOKEN} from '../classes/model/dataProvider/glass/glass.type'
import {FBS_MODEL_PROVIDER_TOKEN} from '../classes/model/dataProvider/fbsModel/fbsModel.type'
import {ParameterModelConfiguration} from '../classes/model/modelConfiguration/parameterModelConfiguration'
import {ConfiguratorConfigurationModel} from '../classes/model/configuratorConfigurationModel'
import {ModalService} from './modal/modal.service'
import TrackingService from '../classes/service/tracking.service'
import {BetterMatRadioChange} from '../batter-mat-radio-group.directive'
import {DinUtil} from '../classes/util/dinUtil'
import {ToastrService} from 'ngx-toastr'
import {GrundformService} from '../classes/service/grundform.service'
import {ProfileService} from '../classes/service/profile.service'
import {HausfrontService} from '../classes/service/hausfront.service'
import {HausfrontMenuComponent} from './menu/hausfront-menu/hausfront-menu.component'
import {ConfiguratorModeService} from '../classes/service/configuratorMode.service'
import {ConfiguratedDoorDto} from '../classes/api/configuratedDoor.dto'
import {isMaterial, Material} from '../classes/model/material'
import {ShareAPIPolyfillOptions} from 'share-api-polyfill'
import {ComponentSelectionService} from '../classes/service/componentSelectionService'
import {WorkflowService} from '../workflow/workflow.service'
import {WorkflowSubscriber} from '../workflow/workflow.types'
import {ViewService} from '../classes/service/view.service'
import {Parameter} from '../classes/service/parameter/parameter'
import * as ConstructionComponentTypeEnumModule from '../classes/model/component/construction/construction-component-type.enum'
import {
  ConstructionComponentType,
  isConstructionComponentType
} from '../classes/model/component/construction/construction-component-type.enum'
import {FbsModelTyp} from '../classes/model/component/model/component-model-type.enum'
import {SettingsService} from '../classes/service/settings/settings.service'
import {ResponsePriceItem} from '../classes/api/price/response-price-item.interface'
import {ResponseValidation} from '../classes/api/validation/response-validation.interface'
import {ResponseInsulation} from '../classes/api/insulations/response-insulation.interface'
import MemoListService from '../classes/service/memo-list/memo-list.service'
import {ResponseText} from '../classes/api/texts/response-text.interface'
import {CharacteristicsDataFactory} from '../classes/model/dataProvider/characteristics-data/characteristics-data.factory'
import {CharacteristicsData} from '../classes/model/dataProvider/characteristics-data/characteristics-data'
import {LoadRequestIdentifier} from './components/load-configuration-button/load-configuration-button.component'
import {CookieConsentService} from '../cookie-consent'
import {ConstructionDimensionService} from '../classes/service/construction-dimension.service'
import {SpacerThicknessExceededError, SpacerThicknessUndercutError} from '../classes/model/component/glassaufbau/spacer-thickness.error'
import {Observable} from 'rxjs'
import {ConfiguratorInitializerService, LoadModule, LoadService} from './load'

const TRANSLATION_KEY = {
  CONFIGURATOR_NAVIGATION_SUPPORT: 'ConfiguratorComponent.Navigation.Support',
  CONFIGURATOR_NAVIGATION_PRIVACY: 'ConfiguratorComponent.Navigation.Privacy',
  CONFIGURATOR_NAVIGATION_IMPRINT: 'ConfiguratorComponent.Navigation.Imprint',
  CONFIGURATOR_NAVIGATION_COOKIES: 'ConfiguratorComponent.Navigation.Cookies',
  CONFIGURATOR_FRONTOPTIONS_ADDMEMO: 'ConfiguratorComponent.FrontOptions.AddMemo',
  CONFIGURATOR_FRONTOPTIONS_CHARACTERISTICS: 'ConfiguratorComponent.FrontOptions.Charasteristics',
  CONFIGURATOR_FRONTOPTIONS_MULTIVISION: 'ConfiguratorComponent.FrontOptions.Multivision',
  CONFIGURATOR_FRONTOPTIONS_PRICE: 'ConfiguratorComponent.FrontOptions.Price',
  TOAST_ERROR_CONFIGURATION_NOT_FOUND_TITLE: 'ConfiguratorComponent.Toast.Error.ConfigurationNotFound.Title',
  TOAST_ERROR_CONFIGURATION_NOT_FOUND_MESSAGE: 'ConfiguratorComponent.Toast.Error.ConfigurationNotFound.Message',
  TOAST_ERROR_CONFIGURATION_LOAD_UNKNOWN_TITLE: 'ConfiguratorComponent.Toast.Error.ConfigurationLoadUnknown.Title',
  TOAST_ERROR_CONFIGURATION_LOAD_UNKNOWN_MESSAGE: 'ConfiguratorComponent.Toast.Error.ConfigurationLoadUnknown.Message',
  TOAST_ERROR_GLASS_SPACER_UNDERCUT_TITLE: 'ConfiguratorComponent.Toast.Error.SpacerThicknessUndercut.Title',
  TOAST_ERROR_GLASS_SPACER_UNDERCUT_MESSAGE_PREFIX: 'ConfiguratorComponent.Toast.Error.SpacerThicknessUndercut.Message.Prefix',
  TOAST_ERROR_GLASS_SPACER_UNDERCUT_MESSAGE_SUFFIX: 'ConfiguratorComponent.Toast.Error.SpacerThicknessUndercut.Message.Suffix',
  TOAST_ERROR_GLASS_SPACER_EXCEEDED_TITLE: 'ConfiguratorComponent.Toast.Error.SpacerThicknessExceeded.Title',
  TOAST_ERROR_GLASS_SPACER_EXCEEDED_MESSAGE_PREFIX: 'ConfiguratorComponent.Toast.Error.SpacerThicknessExceeded.Message.Prefix',
  TOAST_ERROR_GLASS_SPACER_EXCEEDED_MESSAGE_SUFFIX: 'ConfiguratorComponent.Toast.Error.SpacerThicknessExceeded.Message.Suffix',
  TOAST_ERROR_GLASS_SPACER_UNKNOWN_TITLE: 'ConfiguratorComponent.Toast.Error.SpacerThicknessUnknown.Title',
  TOAST_ERROR_GLASS_SPACER_UNKNOWN_MESSAGE: 'ConfiguratorComponent.Toast.Error.SpacerThicknessUnknown.Message',
  TOAST_ERROR_LOADING_MODEL_MESSAGE: 'ConfiguratorComponent.Toast.Error.LoadingModel.Message',
  TOAST_ERROR_LOADING_MODEL_TITLE: 'ConfiguratorComponent.Toast.Error.LoadingModel.Title',
  TOAST_WARNING_ZVALUE_TOO_LARGE_TITLE: 'ConfiguratorComponent.Toast.Warning.ZValueTooLarge.Title',
  TOAST_WARNING_ZVALUE_TOO_LARGE_MESSAGE_PREFIX: 'ConfiguratorComponent.Toast.Warning.ZValueTooLarge.Message.Prefix',
  TOAST_WARNING_ZVALUE_TOO_LARGE_MESSAGE_SUFFIX: 'ConfiguratorComponent.Toast.Warning.ZValueTooLarge.Message.Suffix',
  TOAST_WARNING_ZVALUE_TOO_SMALL_TITLE: 'ConfiguratorComponent.Toast.Warning.ZValueTooSmall.Title',
  TOAST_WARNING_ZVALUE_TOO_SMALL_MESSAGE_PREFIX: 'ConfiguratorComponent.Toast.Warning.ZValueTooSmall.Message.Prefix',
  TOAST_WARNING_ZVALUE_TOO_SMALL_MESSAGE_SUFFIX: 'ConfiguratorComponent.Toast.Warning.ZValueTooSmall.Message.Suffix',
  TOAST_SUCCESS_NOTE_SAVED_TITLE: 'ConfiguratorComponent.Toast.Success.NoteSaved.Title',
  TOAST_SUCCESS_NOTE_SAVED_MESSAGE: 'ConfiguratorComponent.Toast.Success.NoteSaved.Message',
  MOBILE_MENU_PRIVACY_LINK_LABEL: 'ConfiguratorComponent.Mobile.Menu.PrivacyLink.Label',
  MENU_TITLE: 'ConfiguratorComponent.Menu.Title',
  LOADING_PLEASE_WAIT: 'ConfiguratorComponent.Loading.PleaseWait',
  LOADING_CONFIGURATION: 'ConfiguratorComponent.Loading.Configuration',
  VIEW_OUTSIDE: 'ConfiguratorComponent.View.Outside',
  VIEW_INSIDE: 'ConfiguratorComponent.View.Inside',
  HINT_MEASURES_NOT_OBLIGING: 'ConfiguratorComponent.Hint.MeasuresNotObliging',
  FOOTER_ITEMS_COOKIES: 'ConfiguratorComponent.Footer.Cookies',
  FOOTER_ITEMS_VERSION: 'ConfiguratorComponent.Footer.Version',
  BUTTON_NOTES: 'ConfiguratorComponent.Button.Notes',
  BUTTON_SAVE: 'ConfiguratorComponent.Button.Save',
  BUTTON_VIEWSWITCH_OUTSIDE: 'ConfiguratorComponent.Button.ViewSwitch.Outside',
  BUTTON_VIEWSWITCH_INSIDE: 'ConfiguratorComponent.Button.ViewSwitch.Inside',
  HEADER_TTK_SETTINGS: 'ConfiguratorComponent.Header.TTK.Settings',
  CONFIGURATOR_HEADER_TITLE: 'ConfiguratorComponent.Header.Title',
  CALC_MODAL_LOADING_DATA: 'ConfiguratorComponent.CalculationModal.LoadingData',
  CALC_MODAL_TITLE: 'ConfiguratorComponent.CalculationModal.Title',
  CALC_MODAL_TABLE_COLUMN_SUMMARY: 'ConfiguratorComponent.CalculationModal.Table.Column.Summary',
  CALC_MODAL_TABLE_COLUMN_ASSIGNMENT: 'ConfiguratorComponent.CalculationModal.Table.Column.Assignment',
  CALC_MODAL_TABLE_COLUMN_PREFIX: 'ConfiguratorComponent.CalculationModal.Table.Column.Prefix',
  CALC_MODAL_TABLE_COLUMN_TITLE: 'ConfiguratorComponent.CalculationModal.Table.Column.Title',
  CALC_MODAL_TABLE_COLUMN_TEXT: 'ConfiguratorComponent.CalculationModal.Table.Column.Text',
  CALC_MODAL_INPUT_KOMMISSION_TITLE: 'ConfiguratorComponent.CalculationModal.Input.Kommission.Title',
  CALC_MODAL_INPUT_ACTION_TITLE: 'ConfiguratorComponent.CalculationModal.Input.Action.Title',
  CALC_MODAL_PRICES_TITLE: 'ConfiguratorComponent.CalculationModal.Prices.Title',
  CALC_MODAL_PRICES_TABLE_COLUMN_NR: 'ConfiguratorComponent.CalculationModal.Prices.Table.Column.Nr',
  CALC_MODAL_PRICES_TABLE_COLUMN_DESCRIPTION: 'ConfiguratorComponent.CalculationModal.Prices.Table.Column.Description',
  CALC_MODAL_PRICES_TABLE_COLUMN_BRUTTO: 'ConfiguratorComponent.CalculationModal.Prices.Table.Column.Brutto',
  CALC_MODAL_PRICES_TABLE_COLUMN_NETTO: 'ConfiguratorComponent.CalculationModal.Prices.Table.Column.Netto',
  CALC_MODAL_PRICES_TABLE_COLUMN_DISCOUNT: 'ConfiguratorComponent.CalculationModal.Prices.Table.Column.Discount',
  CALC_MODAL_HINTLIST_HINTS_TITLE: 'ConfiguratorComponent.CalculationModal.HintList.Hints.Title',
  CALC_MODAL_HINTLIST_WARNINGS_TITLE: 'ConfiguratorComponent.CalculationModal.HintList.Warnings.Title',
  CALC_MODAL_HINTLIST_ERRORS_TITLE: 'ConfiguratorComponent.CalculationModal.HintList.Errors.Title',
  CALC_MODAL_VALUES_TITLE: 'ConfiguratorComponent.CalculationModal.Values.Title',
  CALC_MODAL_VALUES_WEIGHT_TITLE: 'ConfiguratorComponent.CalculationModal.Values.Weight.Title',
  CALC_MODAL_VALUES_WEIGHT_PREFIX: 'ConfiguratorComponent.CalculationModal.Values.Weight.Prefix',
  CALC_MODAL_VALUES_WEIGHT_SUFFIX: 'ConfiguratorComponent.CalculationModal.Values.Weight.Suffix',
  CALC_MODAL_VALUES_WEIGHT_UPVALUE_U: 'ConfiguratorComponent.CalculationModal.Values.UPValue.U',
  CALC_MODAL_VALUES_WEIGHT_UPVALUE_P: 'ConfiguratorComponent.CalculationModal.Values.UPValue.P',
  CALC_MODAL_VALUES_WEIGHT_UPVALUE_TITLE: 'ConfiguratorComponent.CalculationModal.Values.UPValue.Title',
  CALC_MODAL_VALUES_WEIGHT_UGVALUE_U: 'ConfiguratorComponent.CalculationModal.Values.UGValue.U',
  CALC_MODAL_VALUES_WEIGHT_UGVALUE_G: 'ConfiguratorComponent.CalculationModal.Values.UGValue.G',
  CALC_MODAL_VALUES_WEIGHT_UGVALUE_TITLE: 'ConfiguratorComponent.CalculationModal.Values.UGValue.Title'
} as const

@Component({
  selector: 'app-configurator',
  templateUrl: './configurator.component.html',
  styleUrls: ['./configurator.component.scss'],
  providers: [
    ConfiguratedDoorFactory,
    TuerFactory,
    OberlichtFactory,
    SeitenteilFactory,
    GlasaufbauFactory,
    CharacteristicsDataFactory,
    ModalService,
    FbsModelService,
    CatalogueService,
    NavigatorService,
    ViewService,
    TrackingService,
    ConfiguratorModeService,
    ConfiguratorDataModel,
    ConfiguratorConfigurationModel,
    ComponentSelectionService,
    GlassService,
    GrundformService,
    ProfileService,
    HausfrontService,
    {provide: GLASS_PROVIDER_TOKEN, useExisting: ConfiguratorDataModel},
    {provide: FBS_MODEL_PROVIDER_TOKEN, useExisting: ConfiguratorDataModel},
    MemoListService,
    ConstructionDimensionService,
    ...LoadModule.provide()
  ]
})
export class ConfiguratorComponent implements OnInit {
  protected ConfiguratorMode = ConfiguratorMode
  protected readonly ConstructionComponentType: typeof ConstructionComponentType =
    (ConstructionComponentTypeEnumModule as {
      ConstructionComponentType: Required<typeof ConstructionComponentType>
    })?.ConstructionComponentType
  protected readonly SideType: typeof SideType = (constEnumModule as {
    SideType: Required<typeof SideType>
  })?.SideType
  protected readonly StringUtil = StringUtil
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  private delayedCalculationTimer: ReturnType<typeof setTimeout>
  protected doorPosition: HausfrontDoorPosition | null = null
  @ViewChild(HausfrontMenuComponent) protected hausfrontMenuComponent: HausfrontMenuComponent
  protected readonly isDevMode = isDevMode
  protected isMobile = false
  // if the current device is mobile
  protected loading = true
  protected logoLinkUrl: string
  @ViewChild(ModelMenuComponent) protected modelMenuComponent: ModelMenuComponent
  @Output() readonly ngInit: EventEmitter<void>
  protected result_inside = ''
  protected result_outside = ''
  private screenHeight = 0
  private screenWidth = 0
  protected showCharacteristics: boolean = false
  public showNextToggle = ''
  protected version: string = environment.version

  constructor(
    private httpService: HttpService,
    private _translate: TranslateService,
    private toastrService: ToastrService,
    private logger: NGXLogger,
    protected modalService: ModalService,
    private catalogueService: CatalogueService,
    private grundformService: GrundformService,
    private viewService: ViewService,
    private _configuratorModeService: ConfiguratorModeService,
    protected _componentSelection: ComponentSelectionService,
    private profileService: ProfileService,
    protected navigatorService: NavigatorService,
    private parameterService: ParameterService,
    private trackingService: TrackingService,
    private _configuratorDataModel: ConfiguratorDataModel,
    private configuratorConfigurationModel: ConfiguratorConfigurationModel,
    private workflowService: WorkflowService,
    private settingsService: SettingsService,
    private location: Location,
    protected readonly memoListService: MemoListService,
    private characteristicsDataFactory: CharacteristicsDataFactory,
    protected cookieConsentService: CookieConsentService,
    private readonly configuratorInitializeHelper: ConfiguratorInitializerService,
    private readonly loadService: LoadService
  ) {
    this.logger.trace('ConfiguratorComponent::constructor')
    this.isMobile = window.innerWidth <= 767
    this.screenWidth = window.innerWidth
    this.screenHeight = window.innerHeight
    this.ngInit = new EventEmitter<void>()
    if (this.configuratorMode === ConfiguratorMode.FBS) {
      this.cookieConsentService.showConsentBanner = false
      // this.cookieConsentService.addConsent('necessary')
      // this.cookieConsentService.addConsent('tracking')
    }
    this.navigatorService.configurator = this
    this.navigatorService.onShowToggleChange.subscribe(this.onShowToggleChange.bind(this))
    this.configuratorConfigurationModel.zMassChanged.subscribe(
      (): void => this.onZmassChange()
    )
    this.configuratorConfigurationModel.colorChanged.subscribe(() => void this.getCompleteDoor())
    this.configuratorConfigurationModel.grundformChange.subscribe(() => void this.onGrundformChange())
    this.configuratorConfigurationModel.afterZubehoerChanged.subscribe(() => void this.getCompleteDoor())
    this.configuratorConfigurationModel.securityPackageChange.subscribe(() => void this.getCompleteDoor())
  }

  changeDoorView(view: SideType): void {
    this.changeDoorViewInternal(view)
    void this.getCompleteDoor()
  }

  changeDoorViewInternal(view: SideType): void {
    this.logger.trace('changeDoorViewInternal')
    // reverse the door and seitenteil array - as they have to be mirrored
    if (this.view !== view) {
      this.configuratedDoor.Components.reverse()
    }
    this.configuratedDoor.innenansicht = view !== SideType.Outside
    this.view = view
  }

  changeMaterialTuersystem(changeEvent: BetterMatRadioChange): void {
    this.modalService.showConfiguratorResetModal()
      .afterClosed()
      .subscribe((result): void => {
        if (result && isMaterial(changeEvent.value)) {
          this.configuratorConfigurationModel.material = changeEvent.value
        } else {
          changeEvent.cancel()
        }
      })
  }

  changeView(newSide: SideType): void {
    if (this.view === newSide) {
      return
    }
    this.view = newSide
    void this.onViewChange()
  }

  clearFbsKonstruktion(): boolean {
    return delete this._componentSelection.selectedComponent.fbsKonstruktion
  }

  closeMe(): void {
    if (this.parameter.hasOwnProperty('close')) {
      try {
        if (this.parameter.close === '1') {
          if (window.external != null && 'closeMe' in window.external && window.external.closeMe instanceof Function) {
            window.external.closeMe(0)
          }
        } else if (this.parameter.close === '2') {
          window.close()
        } else if (this.parameter.close === '3') {
          window.parent.postMessage('close', '*')
        } else if (this.parameter.close === '4') {
          const postMessage =
            (window as unknown as Record<string, Record<string, Record<string, unknown>>>
            )?.chrome?.webview?.postMessage
          if (typeof postMessage === 'function') {
            postMessage('close')
          }
        }
      } catch (e) {
        this.logger.error(e)
      }
    }
  }

  collectTextsPromises(elem: ConfiguratedDoorDto): Promise<void | CharacteristicsData>[] {
    return [
      this.fbsGetWeightsData(elem),
      this.fbsGetInsulationsData(elem),
      this.fbsGetValidationData(elem),
      this.fbsGetTextsData(elem)
    ]
  }

  // checks if all configuratedDoor elements' models have been loaded
  configuratedModelsLoaded(): ComponentModel {
    return this._componentSelection.selectedComponent && this._componentSelection.selectedComponent.model
  }

  delayedCalculation(): void {
    if (this.delayedCalculationTimer) {
      clearTimeout(this.delayedCalculationTimer)
    }
    this.delayedCalculationTimer = setTimeout((): void => {
      this.sendCalculationData()
    }, 500)
  }

  fbsGetInsulationsData(requestData?: ConfiguratedDoorDto): Promise<void> {
    this.configuratedDoor.Insulation = null
    return new Promise<void>((resolve, reject): void => {
      this.httpService.fbsGetInsulations(requestData || this.generateElement()).subscribe(
        (data: ResponseInsulation): void => {
          this.configuratedDoor.Insulation = data
        },
        (error): void => {
          this.logger.error(error)
          reject()
        },
        (): void => {
          resolve()
        }
      )
    })
  }

  fbsGetTextsData(requestData?: ConfiguratedDoorDto, saveResponse: boolean = true): Promise<CharacteristicsData> {
    return new Promise<ResponseText>((resolve, reject): void => {
      this.httpService.fbsGetTexts(requestData || this.generateElement()).subscribe({
        next: resolve,
        error: (error): void => {
          this.logger.error(error)
          reject()
        }
      })
    }).then((textData): CharacteristicsData => {
      const characteristicsData = this.characteristicsDataFactory.create(textData)
      if (saveResponse) {
        this.configuratedDoor.summary = characteristicsData
      }
      return characteristicsData
    })
  }

  fbsGetValidationData(requestData?: ConfiguratedDoorDto): Promise<void> {
    return new Promise<void>((resolve, reject): void => {
      this.configuratedDoor.Errors = []
      this.configuratedDoor.Warnings = []
      this.configuratedDoor.Infos = []
      this.httpService.fbsGetValidations(requestData || this.generateElement()).subscribe({
        next: (data: ResponseValidation): void => {
          this.configuratedDoor.Errors = data.Errors
          this.configuratedDoor.Warnings = data.Warnings
          this.configuratedDoor.Infos = data.Infos
        },
        error: (error): void => {
          this.logger.trace(error)
          reject()
        },
        complete: (): void => {
          resolve()
        }
      })
    })
  }

  fbsGetWeightsData(requestData?: ConfiguratedDoorDto): Promise<void> {
    return new Promise<void>((resolve, reject): void => {
      this.configuratedDoor.DoorWeight = null
      this.httpService.fbsGetWeights(requestData || this.generateElement()).subscribe(
        (data): void => {
          this.configuratedDoor.DoorWeight = data.Weight
        },
        (error): void => {
          this.logger.error(error)
          reject()
        },
        (): void => {
          resolve()
        }
      )
    })
  }

  generateElement(): ConfiguratedDoorDto {
    return this.configuratorConfigurationModel.configuratedDoorDto
  }

  getCalculationColumns(): string[] {
    const cols = ['nr', 'text']
    if (this.settingsService.settings?.BruttoPreise) {
      cols.push('brutto')
    }
    if (this.settingsService.settings?.NettoPreise) {
      cols.push('rabatt')
      cols.push('netto')
    }
    return cols
  }

  // collect current configuration and post it to the backend
  getCompleteDoor(): Promise<void> {
    this.logger.trace('getCompleteDoor')
    let resolve: () => void
    let reject: (reason?: unknown) => void
    const promise: Promise<void> = new Promise<void>((resolve1, reject1): void => {
      resolve = resolve1
      reject = reject1
    }).catch((error): Promise<void> => {
      this.result_outside = ''
      this.result_inside = ''
      return Promise.reject(error)
    })
    let elem: ConfiguratedDoorDto
    try {
      elem = this.generateElement()
    } catch (error) {
      this.logger.error(error)
      reject(error)
    }
    if (elem) {
      this.loading = true
      this.httpService.postImagesV2(elem).subscribe({
        next: (result): void => {
          this.logger.trace('downloadDoor')
          this.result_inside = result.Images?.Innen ?? ''
          this.result_outside = result.Images?.Aussen ?? ''
          this.configuratedDoor.Images = {
            Inside: result.Images?.Innen ?? '',
            Outside: result.Images?.Aussen ?? ''
          }
          this.loading = false
          if (result.TuerId !== '') {
            this.parameterService.setTuerId(result.TuerId)
          }
        }, error: (error): void => {
          this.logger.error(error)
          this.loading = false
          reject(error)
        }, complete: (): void => {
          this.hausfrontMenuComponent?.updateDoorPosition()
          resolve()
          // this.setDoorPosition();
          // this.resetDownload();
        }
      })
    }
    return promise
  }

  getDeckschichtStaerken(): Promise<void> {
    return this.configuratorConfigurationModel.updateDeckschichtStaerke()
  }

  getDin(): Din | undefined {
    return this._componentSelection.selectedComponent.dinfuellung as Din || DinUtil.fromString(this.configuratedDoor?.Grundform?.DinElement)
  }

  getModel(
    modelId: string,
    componentType: ConstructionComponentType,
    material?: Material | null,
    loadRequestIdentifier?: LoadRequestIdentifier
  ): Promise<ComponentModel> {
    this.logger.trace('getModel')
    this.loading = true
    return this.configuratorDataModel
      .loadModel(
        modelId,
        componentType,
        material ?? this._componentSelection.selectedComponent.material ?? this.modell.material ?? Material.Kunststoff,
        this.parameter.profil ?? this.configuratedDoor.profile.Beschreibung,
        this._componentSelection.selectedComponent.konstruktion,
        this.modell,
        this._componentSelection.selectedComponent.oeffnungsart,
        this.getDin()
        ?? (this._componentSelection.selectedComponent.oeffnungsart === 'innen'
          ? DinUtil.fromString(this.modell.din)
          : DinUtil.fromString(this.modell.din) as number === 0 ? 1 : 0),
        loadRequestIdentifier
      )
  }

  getUGString(): string {
    return this._translate.translate(TRANSLATION_KEY.CALC_MODAL_VALUES_WEIGHT_UGVALUE_U)
      + '<sub>' + this._translate.translate(TRANSLATION_KEY.CALC_MODAL_VALUES_WEIGHT_UGVALUE_G)
      + '</sub> - '
      + this._translate.translate(TRANSLATION_KEY.CALC_MODAL_VALUES_WEIGHT_UGVALUE_TITLE)
  }

  getUPString(): string {
    return this._translate.translate(TRANSLATION_KEY.CALC_MODAL_VALUES_WEIGHT_UPVALUE_U)
      + '<sub>' + this._translate.translate(TRANSLATION_KEY.CALC_MODAL_VALUES_WEIGHT_UPVALUE_P)
      + '</sub> - '
      + this._translate.translate(TRANSLATION_KEY.CALC_MODAL_VALUES_WEIGHT_UPVALUE_TITLE)
  }

  hideAndReloadConsent(): void {
    this.cookieConsentService.showConsentBanner = false
    window.location.reload()
  }

  lazyImageLoaded(object: {
    loaded?: boolean
  }): void {
    object.loaded = true
  }

  async loadComponentByIndex(loadRequestIdentifier: LoadRequestIdentifier): Promise<void> {
    return this.loadService.loadComponentOfDoorFromLoadedConfig(loadRequestIdentifier)
  }

  async loadDoorById(doorId: string): Promise<void> {
    this.loading = true
    try {
      await this.loadService.loadDoorById(doorId)
      await this.getCompleteDoor()
    } finally {
      this.loading = false
    }
  }

  ngOnInit(): void {
    this.logger.trace('ngOnInit')
    this.loading = true
    this.ngInit.emit()
    window.ttc = this
    const loadingModal = this._configuratorModeService.mode === ConfiguratorMode.TTK
      ? this.modalService.showInitialLoadingModal()
      : undefined
    loadingModal?.componentInstance.setMessage('Lade Einstellungen...')
    this.logoLinkUrl = ((): string => {
      const absoluteUrl = window.location.href
      const relativePath = this.location.path()
      const index = absoluteUrl.indexOf(relativePath)
      return absoluteUrl.substring(0, index)
    })()
    const workflowSubscriber: WorkflowSubscriber[] = []
    loadingModal?.componentInstance.setMessage('Lade Kataloge und Modelle...')
    const preloading = [
      this._configuratorDataModel.preload(),
      this.catalogueService.load().then(() => void this.navigatorService.goToFirst()),
      this.grundformService.load(),
      this.profileService.load(),
      this.workflowService.load().then((subs: WorkflowSubscriber[]): void => {
        workflowSubscriber.push(...subs)
      }),
      this.configuratorInitializeHelper.loadConfig(),
      this.configuratorInitializeHelper.construction()
    ]
    /* if (this.configurationEditMode) {
      preloading.push(this.loadFbsConfig(this.parameter.transkey, this.modell.pos))
    } */
    void Promise.all(preloading)
      .then((): Promise<void> => this.configuratorInitializeHelper.resetConfiguratedDoor())
      .then((): void => workflowSubscriber.forEach((sub): void =>
        void sub.subscribe(this.configuratorConfigurationModel, this._configuratorDataModel, this.parameterService))
      ).then((): Promise<void> | void =>
        this.configuratorInitializeHelper.initialLoadDoor()
          .then((): Promise<void> => this.getCompleteDoor())
          .catch(async (error): Promise<void> => {
            this.logger.error(error)
            this.toastrService.error(
              this._translate.translate(
                TRANSLATION_KEY.TOAST_ERROR_LOADING_MODEL_MESSAGE
              ),
              this._translate.translate(TRANSLATION_KEY.TOAST_ERROR_LOADING_MODEL_TITLE)
            )
            await this.resetConfiguratedDoor()
            if (this.navigatorService.showToggle !== 'Modell') {
              this.navigatorService.goToFirst()
            }
          })
      ).catch((error): void => {
        this.logger.error(error)
      })
      .then((): void => {
        this.loading = false
      })
      .then(() => void this.trackingService.track())
      .then(() => void loadingModal?.close())
      .then(() => void this.ngInit.complete())
  }

  onGrundformChange(): void {
    void this.loadService.loadModelsAfterGrundformChange()
    this.selectFirstDoor()
  }

  onMaterialChange(materialChange: BetterMatRadioChange): void {
    // event.preventDefault()
    const oldValue = materialChange.previous
    const newValue = materialChange.value as unknown
    if (newValue === oldValue || !isMaterial(newValue)) {
      return
    }
    this.modalService.showFuellungMaterialChangeModal()
      .afterClosed()
      .subscribe((result): void => {
        if (result) {
          this.selectedComponent.material = newValue
          this.selectedComponent.optionen = {Inside: [], Outside: []}
          void this.onSelectModel(
            this.selectedComponent.model.Id,
            this.selectedComponent.objectType,
            newValue,
            true
          ).then((): void =>
            this.configuratorConfigurationModel.fillingMaterialChange.emit(this.selectedComponent.material)
          )
        } else {
          materialChange.cancel()
        }
      })
  }

  onOptionChange(): void {
    void this.getDeckschichtStaerken()
      .then((): void => {
        void this.getCompleteDoor()
      })
  }

  @HostListener('window:resize')
  onResize(): void {
    this.logger.trace('onResize')
    this.screenWidth = window.innerWidth
    this.screenHeight = window.innerHeight
    this.isMobile = this.screenWidth <= 767
    if (this.configuratedDoor?.Hausfronten) {
      this.hausfrontMenuComponent?.updateDoorPosition()
    }
  }

  async onSelectModel(
    model_id: string | number,
    compTypeParam: ConstructionComponentType | FbsModelTyp,
    material?: Material,
    preventNavigation: boolean = false
  ): Promise<void> {
    try {
      let componentType: ConstructionComponentType
      if (!compTypeParam) {
        componentType = undefined
      } else if (!isConstructionComponentType(compTypeParam)) {
        switch (compTypeParam) {
          case FbsModelTyp.tuer:
            componentType = ConstructionComponentType.Door
            break
        }
      } else {
        componentType = compTypeParam
      }
      material ??= this.configuratorConfigurationModel.material
      this.loading = true
      await this.loadService.setComponentModelById(String(model_id), componentType, material, this.selectedComponent)
      if (this.configuratorMode === ConfiguratorMode.FBS && !preventNavigation && this.navigatorService.showToggle !== 'Modell') {
        this.navigatorService.goToFirst()
      }
      await this.getCompleteDoor()
      if (this.configuratorMode === ConfiguratorMode.FBS) {
        this.showModelHints()
      }
    } catch (err) {
      this.logger.error(err)
    }
  }

  onShowToggleChange(showToggle: NavigationKey | MenuClosed): void {
    // update navSquares
    if (showToggle !== '') {
      this.navigatorService.updateNavSquares(showToggle)
    }
    if (this.navigatorService.mobileNav) {
      this.navigatorService.toggleNav()
    }
    if (showToggle === 'Hausfront') {
      setTimeout((): void => {
        this.hausfrontMenuComponent.setMiniDoorPosition()
      }, 300)
    }
  }

  async onViewChange(): Promise<void> {
    if (this.configuratorMode === ConfiguratorMode.TTK) {
      await this.getCompleteDoor().catch((err): void => this.logger.error(err))
    }
  }

  onZmassChange(): void {
    if (this.selectedComponent.Zmass < this.configuratedDoor.MinZMass) {
      this.selectedComponent.Zmass = this.configuratedDoor.MinZMass
      if (this.configuratorMode === ConfiguratorMode.FBS) {
        this.toastrService.warning(
          this._translate.translate(TRANSLATION_KEY.TOAST_WARNING_ZVALUE_TOO_SMALL_MESSAGE_PREFIX)
          + this.configuratedDoor.MinZMass + this._translate.translate(TRANSLATION_KEY.TOAST_WARNING_ZVALUE_TOO_SMALL_MESSAGE_SUFFIX),
          this._translate.translate(TRANSLATION_KEY.TOAST_WARNING_ZVALUE_TOO_SMALL_TITLE)
        )
      }
    }
    if (this.selectedComponent.Zmass > this.configuratedDoor.MaxZMass) {
      this.selectedComponent.Zmass = this.configuratedDoor.MaxZMass
      if (this.configuratorMode === ConfiguratorMode.FBS) {
        this.toastrService.warning(
          this._translate.translate(TRANSLATION_KEY.TOAST_WARNING_ZVALUE_TOO_LARGE_MESSAGE_PREFIX)
          + this.configuratedDoor.MaxZMass + this._translate.translate(TRANSLATION_KEY.TOAST_WARNING_ZVALUE_TOO_LARGE_MESSAGE_SUFFIX),
          this._translate.translate(TRANSLATION_KEY.TOAST_WARNING_ZVALUE_TOO_LARGE_TITLE)
        )
      }
    }
    this.updateStaerkeValues()
    // TODO: Do not set glasaufbau at all, if it is empty
    if (
      this.selectedComponent.glasaufbau
      && this.selectedComponent.glasaufbau.Id !== 0
    ) {
      this.selectedComponent.glasaufbau.calcGlasStaerke()
      if (this._configuratorModeService.mode === ConfiguratorMode.FBS && this.selectedComponent.glasaufbau.errors.length > 0) {
        this.selectedComponent.glasaufbau.errors.forEach((err): void => {
          let title: string
          let message: string
          if (err instanceof SpacerThicknessUndercutError) {
            title = this._translate.translate(TRANSLATION_KEY.TOAST_ERROR_GLASS_SPACER_UNDERCUT_TITLE)
            message = this._translate.translate(TRANSLATION_KEY.TOAST_ERROR_GLASS_SPACER_UNDERCUT_MESSAGE_PREFIX)
              + String(err.requiredSpacerThickness)
              + this._translate.translate(TRANSLATION_KEY.TOAST_ERROR_GLASS_SPACER_UNDERCUT_MESSAGE_SUFFIX)
          } else if (err instanceof SpacerThicknessExceededError) {
            title = this._translate.translate(TRANSLATION_KEY.TOAST_ERROR_GLASS_SPACER_EXCEEDED_TITLE)
            message = this._translate.translate(TRANSLATION_KEY.TOAST_ERROR_GLASS_SPACER_EXCEEDED_MESSAGE_PREFIX)
              + String(err.requiredSpacerThickness)
              + this._translate.translate(TRANSLATION_KEY.TOAST_ERROR_GLASS_SPACER_EXCEEDED_MESSAGE_SUFFIX)
          } else {
            title = this._translate.translate(TRANSLATION_KEY.TOAST_ERROR_GLASS_SPACER_UNKNOWN_TITLE)
            message = this._translate.translate(TRANSLATION_KEY.TOAST_ERROR_GLASS_SPACER_UNKNOWN_MESSAGE)
          }
          this.toastrService.error(message, title)
        })
      }
    }
  }

  postMultivision(): void {
    this.loading = true
    this.httpService.postMultivision(this.parameterService.parameter.tuerId, this.viewService.currentSide).subscribe({
      next: (): void => {
        this.loading = false
      },
      error: (error): void => {
        this.logger.error(error)
        this.loading = false
      }
    })
  }

  recalcMobileHeight(): string {
    const contentWrapper = document.getElementById('image_background')
    const sn = document.querySelector('.subnav')
    if (contentWrapper && sn) {
      const snh = this.screenHeight - contentWrapper.offsetHeight - 50 + 'px'
      if (sn instanceof HTMLElement) {
        sn.style.height = snh
      }
      return snh
    }
    return null
  }

  async resetConfiguratedDoor(material?: Material): Promise<void> {
    this.doorPosition = null
    this.result_inside = ''
    this.result_outside = ''
    await this.loadService.resetConfiguratedDoor(material)
    this.loading = false
  }

  saveFbs(): void {
    this.configuratedDoor.TransKey = this.parameter.transkey
    this.configuratedDoor.Pos = this.modell.pos
    /* if (this.parameter.hasOwnProperty('session_token')) {
        this.configuratedDoor.SessionToken = this.parameter.session_token
      }
      if (this.parameter.hasOwnProperty('software')) {
        this.configuratedDoor.Software = this.parameter.software
    } */
    const sendbuttonI = document.querySelector('#sendFBSConfig i')
    sendbuttonI.classList.remove('fa-floppy-o')
    sendbuttonI.classList.add('fa-spinner')
    sendbuttonI.classList.add('fa-spin')
    this.httpService.postFbsConfig(this.generateElement()).subscribe(
      (): void => {
        sendbuttonI.classList.remove('fa-spinner')
        sendbuttonI.classList.remove('fa-spin')
        sendbuttonI.classList.add('fa-check')
        this.closeMe()
      },
      (error): void => {
        this.logger.error(error)
        sendbuttonI.classList.remove('fa-spinner')
        sendbuttonI.classList.remove('fa-spin')
        sendbuttonI.classList.add('fa-times')
      }
    )
  }

  selectFirstDoor(): void {
    if (this._componentSelection.selectedComponent._objectType !== ConstructionComponentType.Door) {
      this._componentSelection.selectedComponent = this.configuratedDoor.Components
        .filter((c: ConstructionComponent): boolean => c._objectType === ConstructionComponentType.Door)?.[0]?.Index
    }
  }

  sendCalculationData(element?: ConfiguratedDoorDto): void {
    const elem: ConfiguratedDoorDto = element || this.generateElement()
    const loadData = this.collectTextsPromises(elem)
    this.configuratedDoor.Prices = null
    void Promise.all(loadData)
      .then((): void => {
        this.httpService.getCalculationFBS(elem).subscribe({
          next: (response): void => {
            const data: ConfiguratedDoor['Prices']['Data'] = response.Data || []
            if (response.Data.length) {
              const filterRetainPreisgruppeZuschlag = (item: ResponsePriceItem): boolean =>
                item.mehrpreisgruppe === 'ZUSCHLAG-PROZENT-NETTO'
              const filterRemovePreisgruppeZuschlag = (item: ResponsePriceItem): boolean => !filterRetainPreisgruppeZuschlag(item)
              if (data.filter(filterRetainPreisgruppeZuschlag).length > 0) {
                let zwischensummeBrutto = 0
                let zwischensummeNetto = 0
                const idx = data.indexOf(data.filter(filterRetainPreisgruppeZuschlag)[0])
                data.filter(filterRemovePreisgruppeZuschlag)
                  .forEach((item): void => {
                    zwischensummeBrutto += item.PreisBrutto
                    zwischensummeNetto += item.PreisNetto
                  })
                data.splice(idx, 0, {
                  summe: false,
                  mehrpreisgruppe: '',
                  Nr: null,
                  PreisBrutto: zwischensummeBrutto,
                  PreisNetto: zwischensummeNetto,
                  Rabatt: null,
                  Sonderrabatt: null,
                  Text: 'Zwischensumme',
                  Zuschlag: 0
                })
              }
              data.push({
                summe: true,
                mehrpreisgruppe: '',
                Nr: null,
                PreisBrutto: response.PreisBrutto,
                PreisNetto: response.PreisNetto,
                Rabatt: null,
                Sonderrabatt: null,
                Text: 'Summe',
                Zuschlag: 0
              })
            } else if (response.Status && response.Status === '500') {
              data.push({
                summe: false,
                mehrpreisgruppe: '',
                Nr: null,
                PreisBrutto: 0,
                PreisNetto: 0,
                Rabatt: null,
                Sonderrabatt: null,
                Text: 'Fehler bei der Preisberechnung.',
                Zuschlag: 0
              })
            }
            this.configuratedDoor.Prices = response
          },
          error: (error): void => {
            this.logger.error(error)
          }
        }
        )
      })
  }

  setShowNextToggle(showNext: string): void {
    this.logger.trace('setShowNextToggle')
    if (this.showNextToggle === showNext) {
      this.showNextToggle = ''
    } else {
      this.showNextToggle = showNext
    }
    if (showNext === 'calculateFbs') {
      this.sendCalculationData()
      this.showNextToggle = showNext
    }
  }

  shareDialog(): void {
    const myNavigator: Navigator = window.navigator
    const data = {
      url: window.location.href,
      text:
        'Traumtür erstellt mit dem Rodenberg Traumtürkonfigurator (Referenzcode: ' +
        this.parameter.tuerId +
        ') ', // + (imageURL ? imageURL : ''),
      title:
        'Rodenberg Traumtürkonfigurator (Referenzcode: ' +
        this.parameter.tuerId +
        ')'
    }
    const options: ShareAPIPolyfillOptions = {
      // change this configurations to hide specific unnecessary icons
      copy: true,
      email: true,
      print: true,
      sms: true,
      messenger: true,
      facebook: true,
      whatsapp: true,
      twitter: true,
      telegram: true,
      skype: false,
      linkedin: false,
      language: 'de' // specify the default language
    }
    if (myNavigator && myNavigator.share) {
      myNavigator
        .share(data, options)
        .catch((err): void => {
          this.logger.error(err)
        })
    }
  }

  showModelHints(): void {
    this.configuratedDoor.Components.forEach((c: ConstructionComponent): void => {
      if (c.model?.Hinweise?.length > 0) {
        const hinweise = c.model?.Hinweise.join('<br>')
        this.toastrService.info(hinweise, 'Hinweis', {enableHtml: true})
      }
    })
  }

  showNotesDialog(): void {
    this.modalService.showNotesModal(this.configuratedDoor.notes || '')
      .afterClosed()
      .subscribe((result: string): void => {
        if (!result) {
          return
        }
        this.configuratedDoor.notes = result
        this.toastrService.success(
          this._translate.translate(TRANSLATION_KEY.TOAST_SUCCESS_NOTE_SAVED_MESSAGE),
          this._translate.translate(TRANSLATION_KEY.TOAST_SUCCESS_NOTE_SAVED_TITLE)
        )
      })
  }

  showPrivacyDialog(): void {
    this.modalService.showPrivacyModal(this.settings.DatenschutzUrl || '')
  }

  showSupport(): void {
    this.modalService.showSupportModal()
  }

  updateOeffnungsart(): void {
    this.updateOeffnungsartInternal()
    void this.getCompleteDoor()
  }

  updateOeffnungsartInternal(): void {
    if (this._componentSelection.selectedComponent.oeffnungsart === 'innen') {
      this._componentSelection.selectedComponent.dinfuellung = this._componentSelection.selectedComponent.din
    } else {
      this._componentSelection.selectedComponent.dinfuellung = this._componentSelection.selectedComponent.din === 0 ? 1 : 0
    }
  }

  updateStaerkeValues(): void {
    this._componentSelection.selectedComponent.staerke =
      this._componentSelection.selectedComponent.Zmass
      + this.configuratedDoor.DeckschichtStaerken.StaerkeAussen
      + this.configuratedDoor.DeckschichtStaerken.StaerkeInnen
  }

  async updateZmassValues(): Promise<boolean> {
    return this.configuratorConfigurationModel.updateZmassValues()
  }

  get configuratedDoor(): ConfiguratedDoor {
    return this.configuratorConfigurationModel.configuratedDoor
  }

  get configuratorDataModel(): ConfiguratorDataModel {
    return this._configuratorDataModel
  }

  get configuratorMode(): ConfiguratorMode {
    return this._configuratorModeService.mode
  }

  get logoUrl$(): Observable<string> {
    return this.settingsService.logoUrl$
  }

  get modell(): ParameterModelConfiguration {
    return this.parameterService.model || {} as ParameterModelConfiguration
  }

  get navService(): NavigatorService {
    return this.navigatorService
  }

  @HostBinding('style.--header-height')
  get overrideHeaderSize(): number | void {
    if (this.configuratorMode === ConfiguratorMode.FBS) {
      return 0
    }
  }

  get parameter(): Parameter {
    return this.parameterService.parameter
  }

  get selectedComponent(): ConstructionComponent {
    return this._componentSelection.selectedComponent
  }

  get settings(): Settings {
    return this.settingsService.settings
  }

  get showPrice(): boolean {
    return this.settings.Preise
  }

  get showToggle(): typeof this.navigatorService.showToggle {
    return this.navigatorService.showToggle
  }

  get view(): SideType {
    return this.viewService.currentSide
  }

  set view(side: SideType) {
    this.viewService.view = side
  }
}

declare global {
  interface Window {
    ttc: ConfiguratorComponent
  }
}
