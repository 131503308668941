import { Injectable} from '@angular/core'
import {NGXLogger} from 'ngx-logger'
import {Workflow, WorkflowSubscription, WorkflowTrigger} from './workflow.types'
import {ConfiguratorConfigurationModel} from '../classes/model/configuratorConfigurationModel'
import { Observer} from 'rxjs'
import {ParameterService} from '../classes/service/parameter/parameter.service'
import WorkflowModule from './workflow.module'
import {ChangeType} from '../classes/model/event/events.types'
import WorkflowParser from './workflow-parser'
import ZubehoerChangeEvent from '../classes/model/event/zubehoerChange.event'
import {ConfiguratorDataModel} from '../classes/model/configuratorDataModel'

export const MEHRPREISE_TRIGGER_KEY = 'mehrpreise'

@Injectable({providedIn: WorkflowModule})
export class WorkflowTriggerParser {
  constructor(
    private logger: NGXLogger
  ) {
  }

  private buildBasisTrigger(triggerParts: string[]): WorkflowTrigger {
    if (triggerParts.length < 2) {
      this.logger.warn('Too few workflow trigger child keys in "basis"!', triggerParts)
    } else if (triggerParts.length > 2) {
      this.logger.warn('Too many workflow trigger child keys in "basis"!', triggerParts)
    } else if (triggerParts[1].toLowerCase() !== 'change') {
      this.logger.warn(
        `Unknown workflow trigger action "${triggerParts[1]}" on "basis.${triggerParts[0].toLowerCase()}", expected ` +
        '"change"!'
      )
    }

    switch (triggerParts[0].toLowerCase()) {
      case 'konstruktionsvariante':
        return {
          subscribeTrigger: (
            ccm: ConfiguratorConfigurationModel,
            cdm: ConfiguratorDataModel,
            ps: ParameterService,
            wf: Workflow): WorkflowSubscription =>
            ccm.constructionVariationChanged.subscribe(this.createWorkflowSubscriber(ccm, cdm, ps, wf))
        }

      case 'materialtürfüllung':
      case 'materialtuerfüllung':
      case 'materialtürfuellung':
      case 'materialtuerfuellung':
        return {
          subscribeTrigger: (
            ccm: ConfiguratorConfigurationModel,
            cdm: ConfiguratorDataModel,
            ps: ParameterService,
            wf: Workflow): WorkflowSubscription =>
            ccm.fillingMaterialChange.subscribe(this.createWorkflowSubscriber(ccm, cdm, ps, wf))
        }


      case 'materialtürsystem':
      case 'materialtuersystem':
        return {
          subscribeTrigger: (
            ccm: ConfiguratorConfigurationModel,
            cdm: ConfiguratorDataModel,
            ps: ParameterService,
            wf: Workflow): WorkflowSubscription =>
            ccm.systemMaterialChange.subscribe(this.createWorkflowSubscriber(ccm, cdm, ps, wf))
        }

      case 'sicherheitspaket':
        return {
          subscribeTrigger: (
            ccm: ConfiguratorConfigurationModel,
            cdm: ConfiguratorDataModel,
            ps: ParameterService,
            wf: Workflow): WorkflowSubscription =>
            ccm.securityPackageChange.subscribe(this.createWorkflowSubscriber(ccm, cdm, ps, wf))
        }

      case 'wärmeschutzpaket':
      case 'waermeschutzpaket':
        return {
          subscribeTrigger: (
            ccm: ConfiguratorConfigurationModel,
            cdm: ConfiguratorDataModel,
            ps: ParameterService,
            wf: Workflow): WorkflowSubscription =>
            ccm.thermalInsulationPackageChange.subscribe(this.createWorkflowSubscriber(ccm, cdm, ps, wf))
        }

      case 'z-maß':
      case 'zmaß':
      case 'z-mass':
      case 'zmass':
        return {
          subscribeTrigger: (
            ccm: ConfiguratorConfigurationModel,
            cdm: ConfiguratorDataModel,
            ps: ParameterService,
            wf: Workflow): WorkflowSubscription =>
            ccm.zMassChanged.subscribe(this.createWorkflowSubscriber(ccm, cdm, ps, wf))
        }

      default:
        this.logger.error('Unknown workflow trigger key "' + triggerParts[0] + '" on "basis"!')
        return this.getUndefinedTriggerSubscriber()
    }
  }

  private buildInitTrigger(triggerParts: string[]): WorkflowTrigger {
    if (triggerParts.length > 1) {
      this.logger.warn('Too many workflow trigger child keys in "initialisierung"!', triggerParts)
    }
    switch (triggerParts[0].toLowerCase()) {
      case 'open':
        return {
          subscribeTrigger: (
            ccm: ConfiguratorConfigurationModel,
            cdm: ConfiguratorDataModel,
            ps: ParameterService,
            wf: Workflow
          ): WorkflowSubscription =>
            ccm.configuratedDoorOpened.subscribe(this.createWorkflowSubscriber(ccm, cdm, ps, wf))
        }

      case 'new':
        return {
          subscribeTrigger: (
            ccm: ConfiguratorConfigurationModel,
            cdm: ConfiguratorDataModel,
            ps: ParameterService,
            wf: Workflow): WorkflowSubscription =>
            ccm.newConfiguratedDoorInitialized.subscribe(this.createWorkflowSubscriber(ccm, cdm, ps, wf))
        }

      default:
        this.logger.error('Unknown workflow trigger key "' + triggerParts[0] + '" on "initialisierung"!')
        return this.getUndefinedTriggerSubscriber()
    }
  }

  private buildMehrpreiseTrigger(triggerParts: string[]): WorkflowTrigger {
    if (triggerParts.length < 2) {
      this.logger.warn(`Too few workflow trigger child keys in "${MEHRPREISE_TRIGGER_KEY}"!`, triggerParts)
    } else if (triggerParts.length > 2) {
      this.logger.warn(`Too many workflow trigger child keys in "${MEHRPREISE_TRIGGER_KEY}"!`, triggerParts)
    } else if (['add', 'remove'].indexOf(triggerParts[1].toLowerCase()) === -1) {
      this.logger.warn(
        `Unknown workflow trigger action "${triggerParts[1]}" on "${MEHRPREISE_TRIGGER_KEY}", expected on of "add", `
        + '"remove"!'
      )
    } else {
      let triggerPredicate: (_: ZubehoerChangeEvent) => boolean = (): boolean => true
      const triggerFilter = triggerParts[0].match(WorkflowParser.filterFinder)
      if (triggerFilter?.length === 1) {
        const filterParts = triggerFilter[0].match(WorkflowParser.filterSplitter)
        const key = filterParts.groups.key
        if (key?.toLowerCase() === 'typ') {
          if (filterParts.groups.value === undefined) {
            this.logger.warn(
              `Malformed workflow trigger filter "${triggerFilter[0]}" on key "${MEHRPREISE_TRIGGER_KEY}", no value ` +
              'given!'
            )
          } else {
            triggerPredicate = (changeEvent: ZubehoerChangeEvent): boolean =>
              changeEvent.identifier.toLowerCase() === filterParts.groups.value.toLowerCase()
          }
        } else {
          this.logger.warn(`Unknown workflow trigger filter "${key}" on key "${MEHRPREISE_TRIGGER_KEY}"!`)
        }
      } else if (triggerFilter !== null) {
        this.logger.warn(`Only one workflow trigger filter is supported on key "${MEHRPREISE_TRIGGER_KEY}"!`)
      }
      const changeType = ({
        add: ChangeType.Added,
        remove: ChangeType.Removed,
      })[triggerParts[1].toLowerCase()]
      return {
        subscribeTrigger: (
          ccm: ConfiguratorConfigurationModel,
          cdm: ConfiguratorDataModel,
          ps: ParameterService,
          wf: Workflow
        ): WorkflowSubscription => ccm.zubehoerChanged.subscribe(
          (changeEvent: ZubehoerChangeEvent): Promise<void> => {
            if (changeEvent.type === changeType && triggerPredicate(changeEvent)) {
              return wf.execute(ccm, cdm, ps) || Promise.resolve()
            }
            return Promise.resolve()
          })
      }
    }
    return this.getUndefinedTriggerSubscriber()
  }

  public buildTrigger(triggerString: string): WorkflowTrigger {
    const triggerParts = triggerString.split('.')
    const firstTriggerPartLc = triggerParts[0].toLowerCase()
    switch (firstTriggerPartLc) {
      case 'initialisierung':
        return this.buildInitTrigger(triggerParts.slice(1))

      case 'basis':
        return this.buildBasisTrigger(triggerParts.slice(1))

    }
    if (firstTriggerPartLc.startsWith(MEHRPREISE_TRIGGER_KEY)) {
      return this.buildMehrpreiseTrigger(triggerParts)
    }

    this.logger.error('Unknown workflow trigger key "' + triggerParts[0] + '"!')
    return this.getUndefinedTriggerSubscriber()
  }



  private createWorkflowSubscriber(
    ccm: ConfiguratorConfigurationModel,
    cdm: ConfiguratorDataModel,
    ps: ParameterService,
    wf: Workflow
  ): Pick<Observer<void>, 'next'> | { next: (value: void) => Promise<void> } {
    return {
      next: (): Promise<void> | void => wf.execute(ccm, cdm, ps)
    }
  }

  private getUndefinedTriggerSubscriber(): WorkflowTrigger {
    return {
      subscribeTrigger: (): ReturnType<WorkflowTrigger['subscribeTrigger']> => ({
        unsubscribe: (): void => {
        }
      })
    }
  }
}
