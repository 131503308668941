import { Component, OnInit } from '@angular/core'

@Component({
  templateUrl: 'privacy.component.html'
})
export class PrivacyComponent implements OnInit {
  protected privacyUrl: string
  ngOnInit(): void {
    // TODO: Load from settings in ttk
    this.privacyUrl = 'https://www.rodenberg.ag/de/unternehmen/impressum'
  }
}
