const filterSplitter = /\[\s*(?<key>[a-zA-Z0-9_-]+)\s*(?:=\s*(?<value>[a-zA-Z0-9_-]+))?\s*]/
const filterFinder = new RegExp(filterSplitter, 'g')
const metaCharacters = {
  isSet: '*',
  not: '!',
} as const
export default Object.freeze({
  filterSplitter,
  filterFinder,
  metaCharacters
} as const)
